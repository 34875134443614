import { createAsyncThunk } from '@reduxjs/toolkit';
import apiPhone from '../../api/phone';

export const phoneupdateStatus = createAsyncThunk('phoneupdateStatus', async (payload, { rejectWithValue }) => {
  try {
    const response = await apiPhone.updateStatusCall(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const phoneHostline = createAsyncThunk('phoneHostline', async (payload, { rejectWithValue }) => {
  try {
    const response = await apiPhone.hostline(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const SelectpostCall = createAsyncThunk('postCall', async (payload, { rejectWithValue }) => {
  try {
    const response = await apiPhone.postCall(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
