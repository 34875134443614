import React, { useState, useEffect } from 'react';
import './navbar.css';
import { listNarbar } from '../../util/constan';
import User from '../../assets/image/user.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AddSiidebar, selectListNarbar } from '../../features/sidebar/sidebarSlice';
import { selectProfile, resetProfile, getProfile } from '../../features/user/userSlice';
import { useSelector } from 'react-redux';
import authApi from '../../api/auth';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const Narbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listNarvarfooter = useSelector(selectListNarbar);
  const profileNarbar = useSelector(selectProfile);
  const logout = async () => {
    await localStorage.clear();
    await dispatch(resetProfile());
    window.location.reload();
  };

  // const { data: dataProfile, isLoading: loading } = useQuery({
  //   queryKey: ['profileHeader'],
  //   queryFn: () => authApi.profile(),
  //   keepPreviousData: true,
  //   staleTime: 60 * 1000,
  // });

  return (
    <div className='BlockNarbar'>
      <div className='Narbarflex'>
        <div>
          <img src={User} alt='' className='imgUser' />
        </div>
        <div className='nameprofile'>HKDSWE NGUYỄN HỮU THÌN </div>
        <div className='logout' onClick={() => logout()} style={{ cursor: 'pointer' }}>
          {t('LOGOUT')}
        </div>
      </div>
      <div className='Narbarflex listNarbar'>
        {listNarbar.map((v, i) => (
          <div
            key={i}
            className='itemNarbar'
            onClick={() => {
              navigate(`/admin/${v?.path}`);

              // const check = listNarvarfooter.map((k) => k?.name);
              if (!listNarvarfooter?.map((v) => v?.name)?.includes(v?.name)) {
                dispatch(AddSiidebar(listNarvarfooter.concat([{ name: v?.name, path: v?.path }])));
              }
            }}
          >
            {t(v?.name)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Narbar;
