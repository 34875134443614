import {  useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillRecordFill } from 'react-icons/bs';
import ConsultationApi from '../../../../api/Consultation';
import CommonApi from './common';
import { useSelector } from 'react-redux';
import { selectConsultation, updateDataDetai } from '../../../../features/consultation/ConsultationSlice';
import { styles } from '../../../../scss/cssCustoms';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { covertNumberPrice, phone } from '../../../../util/help';
import CustomNumberFormat from '../../../../components/common/CustomNumberFormat';
const PopupCustInfo = ({ setStatusCustInfo, setStatusInfomation,setValueSub }) => {
  const [statuSave, setStatusSave] = useState(false);
  const value = useSelector(selectConsultation);
  const [staluatation,setSalutation] = useState(['Mr', 'Ms', 'UNKNOWN'])

  const [data, setData] = useState({
    ...value,
  });
  const queryClient = useQueryClient();
const dispatch = useDispatch()

  const mutation = useMutation({
    mutationFn: (dataCheck) => {
      return ConsultationApi.checkCustomer(dataCheck);
    },
    onSuccess: (data, variables, context) => {},
    onError: (data, variables, context) => {
      alert('0');
      setStatusSave(true);
    },
  });

  const mutationSave = useMutation({
    mutationFn: (dataSave) => {
      return ConsultationApi.saveCustomer(dataSave);
    },
    onSuccess: (data, variables, context) => {

      dispatch(updateDataDetai(variables));
      alert('Thành công');
      setStatusCustInfo(false);
      setStatusInfomation(true);
      setValueSub('Select')
      queryClient.invalidateQueries({queryKey:["detailCustomer"]})
     
    },
    onError: (data, variables, context) => {
      alert('err');
    },
  });

  const CheckID = () => {
    mutation.mutate({});
  };
  const saveCheck = () => {
    mutationSave.mutate({ ...data });
  };
  const occupations = useQuery({
    queryKey: ['occupations'],
    queryFn: () => CommonApi.Occupation(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  const citys = useQuery({
    queryKey: ['citys'],
    queryFn: () => CommonApi.cities(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  const handleChageMain = (value) => {};
  const handleInput = (e) => {
    setData({ ...data, [e.target?.name]: e.target.value });
  };

  return (
    <div>
      {' '}
      <Draggable defaultPosition={{ x: 600, y: 0 }}>
        <div className='boxCustInfo' style={{zIndex:'10000'}}>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFC125',
              justifyContent: 'space-between',
              paddingRight: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <div style={{ marginLeft: '10px', color: 'white' }}>[CCLT2030] Chage Cust. Info</div>
            <AiOutlineClose
              style={{ fontWeight: '600',cursor:'pointer' }}
              onClick={() => {
                setStatusCustInfo(false);
              }}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <div className='Item_title_list' style={{ display: 'flex', border: 'none', alignItems: 'center' }}>
              <BsFillRecordFill style={{ fontSize: '12px' }} /> Personal Infomation
            </div>
            <table style={{ width: '100%', padding: '10px' }}>
              <tr>
                <td>Cust. name</td>
                <td colSpan={4}>
                  <input type='text' value={data?.fullname} placeholder='name' onChange={handleInput} name='fullname' />
                </td>
                <td>salutation</td>
                <td>
                  {/* <input
                    type='text'
                    placeholder='name'
                    value={data?.salutation}
                    onChange={handleInput}
                    name='salutation'
                  /> */}
                       <Select
                      options={staluatation?.map((v) => ({
                        label: v,
                        value: v,
                      }))}
                      value={{label: data?.salutation  ,value:data?.value}}
                      onChange={(value) => {
                        setData({ ...data, ['salutation']: value?.value })
                      }}
                      styles={styles}
                    />
                </td>
              </tr>
              <tr>
                <td>Cust. ID No.</td>
                <td>
                  <input type='text' placeholder='ID no' value={data?.idNo} onChange={handleInput} name='idNo' />
                </td>
                <td>Date of birth</td>
                <td><input type='checkbox'/></td>
                <td>
                  <input type='date' placeholder='Date of birth' onChange={handleInput} name='dateOfBirth' />
                </td>
                <td>Tel(Mobile)</td>
                <td>
                  <input type='text' disabled value= {phone(data?.mobile)} placeholder='mobile' onChange={handleInput} name='mobile' />
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td colSpan={4}>
                  <input
                    type='text'
                    value={data?.concact?.email}
                    placeholder='email'
                    onChange={handleInput}
                    name='email'
                  />
                </td>
                <td>Tel(Phone)</td>
                <td>
                  <input type='text' placeholder='phone' value={ phone(data?.phone) } onChange={handleInput} name='phone' />
                </td>
              </tr>
              <tr>
                <td>Premanent Addr</td>
                <td colSpan={4}>
                  <input
                    type='text'
                    value={data?.permanentAddres}
                    placeholder='permanentAddres'
                    onChange={handleInput}
                    name='permanentAddres'
                  />
                </td>
                <td>Occupation</td>
                <td>
                  {' '}
                  {/* <select
                    onChange={(e) => {
                      setData({ ...data, ['occupation']: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  >
                    {occupations?.data?.data?.results?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select> */}
                  <Select
                      options={occupations?.data?.data?.results?.map((v) => ({
                        label: v?.name,
                        value: v?.id,
                      }))}
                      value={{label: occupations?.data?.data?.results?.find((v) => v?.id === data?.occupation)?.name  ,value:data?.value}}
                      onChange={(value) => {
                        setData({ ...data, ['occupation']: value?.value })
                      }}
                      styles={styles}
                    />
                </td>
              </tr>
              <tr>
                <td>Current Addr.</td>
                <td colSpan={4}>
                  <input
                    type='text'
                    value={data?.currentAddres}
                    placeholder='currentAddres'
                    onChange={handleInput}
                    name='currentAddres'
                  />
                </td>
                <td>Area</td>
                <td>
                  {/* <select
                    onChange={(e) => {
                      setData({ ...data, ['city']: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  >
                    {citys?.data?.data?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select> */}

                  <Select
                      options={citys?.data?.data?.map((v) => ({
                        label: v?.name,
                        value: v?.id,
                      }))}
                      value={{label: citys?.data?.data.find((v) => v?.id === data?.city)?.name  ,value:data?.value}}
                      onChange={(value) => {
                        setData({ ...data, ['city']: value?.value })
                      }}
                      styles={styles}
                    />
                </td>
              </tr>
            </table>

            <div className='Item_title_list' style={{ display: 'flex', border: 'none', alignItems: 'center' }}>
              <BsFillRecordFill style={{ fontSize: '12px' }} /> Company Infomation
            </div>
            <table style={{ width: '100%', padding: '10px' }}>
              <tr>
                <td>Company Name</td>

                <td>
                  <input
                    type='text'
                    value={data?.companyName}
                    placeholder='companyName'
                    onChange={handleInput}
                    name='companyName'
                  />
                </td>
                <td>Company Addr</td>
                <td colSpan={3}>
                  <input
                    type='text'
                    value={data?.companyAddress}
                    placeholder='companyAddress'
                    onChange={handleInput}
                    name='companyAddress'
                  />
                </td>
              </tr>
              <tr>
                <td>Tel(Company)</td>
                <td>
                  <input
                    type='text'
                    value={data?.companyTel}
                    placeholder='companyTel'
                    onChange={handleInput}
                    name='companyTel'
                  />
                </td>
                <td>Tex(Fax)</td>
                <td>
                  <input
                    type='text'
                    value={data?.companyFax}
                    placeholder='companyFax'
                    onChange={handleInput}
                    name='companyFax'
                  />
                </td>
                <td>Income Per Month</td>
                <td>
                  
                  <CustomNumberFormat
                      value={data?.income}
                      isGroup={false}
                      onValueChange={(value) => {
                        setData({ ...data, income: value.floatValue });
                      }}
                    />
                </td>
              </tr>
              <tr>
                <td>Work penod</td>

                <td>
                  <input
                    type='text'
                    // value={data?.income}
                    // placeholder='income'
                    // onChange={handleInput}
                    name='income'
                  />
                </td>
                <td>Type of contract</td>
                <td>1</td>

                <td>Postion</td>
                <td>
                  <input
                    type='text'
                    value={data?.position}
                    placeholder='position'
                    onChange={handleInput}
                    name='position'
                  />
                </td>
              </tr>
              <tr>
                <td>Sai Method</td>

                <td>1</td>
                <td>Health card</td>
                <td>1</td>

                <td colSpan={2}>1</td>
              </tr>
            </table>

            <div
              className='block_one_2'
              style={{ marginTop: '5px', marginRight: '5px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <div
                onClick={CheckID}
                className='item_block_one_2'
                style={{ display: 'flex', alignItems: 'center', backgroundColor: '#7FFF00',cursor:'pointer' }}
              >
                <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
                <span>Check ID</span>
              </div>
              <div
                className='item_block_one_2'
                onClick={saveCheck}
                style={{ backgroundColor: statuSave && '#7FFF00', cursor: 'pointer' }}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default PopupCustInfo;
