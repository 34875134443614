import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './router/protectedRoutes';

import Auth from './page/login/auth';
import { router } from './router/constants';

import ProtectedAuth from './router/protecdAuth';
import Layout from './layout/admin/layout';
import Admin from './layout/admin/admin';


function App() {


  return (
    <>
      <Routes>
        <Route exact path='/' element={<Layout />}>
          <Route index element={<Navigate to='/admin/dashboard' />} />
          <Route path='admin' element={<ProtectedRoutes Component={Admin} />}>
            {router?.map((v, i) => {
              return (
                // v?.role?.includes(role) &&
                // module?.includes(v?.module) &&

                !v?.child ? (
                  <Route exact path={v.path} key={i} element={v.component} />
                ) : (
                  <Route exact path={v.path} key={i} element={v.component}>
                    {v?.child?.map((k, d) => {
                      return <Route exact path={k.path} key={d} element={k.component} />;
                    })}
                  </Route>
                )
              );
            })}
          </Route>
          <Route path='login' element={<ProtectedAuth Component={Auth} />} />
          {/* <Route path='/admin/dashboard' element={<HomePage/>} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
