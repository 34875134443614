import { UA, debug, JsSIP } from 'jssip';
import _ from 'lodash';
// import { Inviter, SessionState, UserAgent } from 'sip.js';

function CallsFlowControl() {
  this.onUserAgentAction = () => {};

  this.notify = (message) => {
    this.onCallActionConnection('notify', message);
  };
  this.tmpEvent = () => {};
  this.onCallActionConnection = () => {};
  this.engineEvent = () => {};
  this.setMicMuted = () => {
    if (this.micMuted && this.activeCall) {
      this.activeCall.unmute();
      this.micMuted = false;
      this.onCallActionConnection('unmute', this.activeCall.id);
    } else if (!this.micMuted && this.activeCall) {
      this.micMuted = true;
      this.activeCall.mute();
      this.onCallActionConnection('mute', this.activeCall.id);
    }
  };
  this.hold = (sessionId) => {
    // If there is an active call with id that is requested then fire hold
    if (this.activeCall.id === sessionId) {
      this.activeCall.hold();
    }
  };
  this.unhold = (sessionId) => {
    // If we dont have active call then unhold the the call with requested id
    if (!this.activeCall) {
      // Find the Requested call in hold calls array
      const toUnhold = _.find(this.holdCallsQueue, { id: sessionId });
      // If we found the call in hold calls array the fire unhold function
      if (toUnhold) {
        toUnhold.unhold();
      }
    } else {
    }
  };
  this.micMuted = false;
  this.activeCall = null;
  this.activeChanel = null;
  this.callsQueue = [];
  this.holdCallsQueue = [];
  this.player = {};
  this.ringer = null;
  this.connectedPhone = null;
  this.config = {};
  this.initiated = false;
  this.playRing = () => {
    this.ringer.current.currentTime = 0;
    this.ringer.current.play();
  };
  this.stopRing = () => {
    this.ringer.current.currentTime = 0;
    this.ringer.current.pause();
  };
  this.removeCallFromQueue = (callId) => {
    _.remove(this.callsQueue, (calls) => calls.id === callId);
  };
  this.addCallToHoldQueue = (callId) => {
    if (this.activeCall.id === callId) {
      this.holdCallsQueue.push(this.activeCall);
    }
  };
  this.removeCallFromActiveCall = (callId) => {
    if (this.activeCall && callId === this.activeCall.id) {
      this.activeCall = null;
    }
  };
  this.removeCallFromHoldQueue = (callId) => {
    _.remove(this.holdCallsQueue, (calls) => calls.id === callId);
  };
  this.connectAudio = () => {
    this.activeCall.connection.addEventListener('addstream', (event) => {
      this.player.current.srcObject = event.stream;
    });
  };

  this.sessionEvent = (type, data, cause, callId) => {
    switch (type) {
      case 'sending':
        const request = data.request;

        const day = new Date(); // const callId = request.getHeader('Call-ID');
        const make_id = `${day.getFullYear()}${day.getMonth()}${day.getDay()}${day.getHours()}${day.getMinutes()}${day.getSeconds()}${day.getMilliseconds()}`;
        // const modifiedCallId = `${data?.request?.call_id}|3434343`; // Thay thế bằng Call-ID tùy chỉnh bạn muốn sử dụng
        const modifiedCallId = `${make_id}${request?.call_id}`;
        request.setHeader('x-header-vntel', modifiedCallId);

        const payload = {
          call_id: data?.request?.call_id,
          make_id: `${make_id}${request?.call_id}`,
        };
      
        this.onCallActionConnection('sending', payload, data?.request?.to?._uri?._user);
      
        break;

      case 'terminated':
        //  this.endCall(data, cause);
        break;
      case 'accepted':
        // this.startCall(data);
        break;
      case 'reinvite':
        this.onCallActionConnection('reinvite', callId, data);
        break;
      case 'hold':
        this.onCallActionConnection('hold', callId);
        this.addCallToHoldQueue(callId);
        this.removeCallFromActiveCall(callId);
        break;
      case 'unhold':
        this.onCallActionConnection('unhold', callId);
        this.activeCall = _.find(this.holdCallsQueue, { id: callId });
        this.removeCallFromHoldQueue(callId);
        break;
      case 'dtmf':
        break;
      case 'muted':
        this.onCallActionConnection('muted', callId);
        break;
      case 'unmuted':
        break;
      case 'confirmed':
        if (!this.activeCall) {
          this.activeCall = _.find(this.callsQueue, { id: callId });
        }
        this.removeCallFromQueue(callId);
        this.onCallActionConnection('callAccepted', callId, this.activeCall);
        break;
      case 'connecting':
        break;
      case 'ended':
        this.onCallActionConnection('callEnded', callId);
        this.removeCallFromQueue(callId);
        this.removeCallFromActiveCall(callId);
        this.removeCallFromHoldQueue(callId);
        if (this.callsQueue.length === 0) {
          this.stopRing();
        }
        break;
      case 'failed':
        this.onCallActionConnection('callEnded', callId);
        this.removeCallFromQueue(callId);
        this.removeCallFromActiveCall(callId);
        if (this.callsQueue.length === 0) {
          this.stopRing();
        }

        break;
      default:
        break;
    }
  };

  this.handleNewRTCSession = (rtcPayload) => {
    const { session: call } = rtcPayload;

    if (call.direction === 'incoming') {
      this.callsQueue.push(call);
      this.onCallActionConnection('incomingCall', call);
      if (!this.activeCall) {
        this.playRing();
      }
    } else {
      this.activeCall = call;
      this.onCallActionConnection('outgoingCall', call);
      this.connectAudio();
    }
    const defaultCallEventsToHandle = [
      'peerconnection',
      'connecting',
      'sending',
      'progress',
      'accepted',
      'newDTMF',
      'newInfo',
      'hold',
      'unhold',
      'muted',
      'unmuted',
      'reinvite',
      'update',
      'refer',
      'replaces',
      'sdp',
      'icecandidate',
      'getusermediafailed',
      'ended',
      'failed',
      'connecting',
      'confirmed',
    ];
    _.forEach(defaultCallEventsToHandle, (eventType) => {
      call.on(eventType, (data, cause) => {
        this.sessionEvent(eventType, data, cause, call.id);
      });
    });
  };

  this.init = () => {
    try {
      this.phone = new UA({ ...this?.config });

      const contact = this.phone.contact;
      // contact.uri._parameters.transport = 'wss';
      const tranfrom = this?.phone?._registrator;

      tranfrom._contact = tranfrom._contact.replace('transport=ws', 'transport=wss');

      // this.phone.setContact(contact);

      this.phone.on('newRTCSession', this.handleNewRTCSession.bind(this));
      const binds = [
        'connected',
        'disconnected',
        'registered',
        'unregistered',
        'registrationFailed',
        'invite',
        'message',
        'connecting',
      ];

      _.forEach(binds, (value) => {
        this.phone.on(value, (e) => {
          this.engineEvent(value, e);
        });
      });
      this.initiated = true;
    } catch (e) {}
  };

  // this.transfer = (sip) => {
  //   const userAgent = new UserAgent({
  //     uri: UserAgent.makeURI(this.config.uri),
  //     transportOptions: {
  //       server: this.config.ws_servers,
  //     },
  //   });
  //   userAgent.start().then(() => {
  //     // Set target destination (callee)
  //     const target = UserAgent.makeURI('sip:ext202411003@cms.siptrunk.vn');
  //     if (!target) {
  //       throw new Error('Failed to create target URI.');
  //     }
  //
  //     // Create a user agent client to establish a session
  //     const inviter = new Inviter(userAgent, target, {
  //       sessionDescriptionHandlerOptions: {
  //         constraints: { audio: true, video: false },
  //       },
  //     });
  //
  //     inviter.stateChange.addListener((newState) => {
  //       switch (newState) {
  //         case SessionState.Establishing:
  //           // Session is establishing
  //           break;
  //         case SessionState.Established:
  //           // Session has been established
  //           break;
  //         case SessionState.Terminated:
  //           // Session has terminated
  //           break;
  //         default:
  //           break;
  //       }
  //     });
  //     inviter
  //       .invite()
  //       .then(() => {
  //
  //       })
  //       .catch((error) => {
  //         // INVITE did not send
  //
  //       });
  //   });
  // };

  this.call = (to) => {
    if (!this.connectedPhone) {
      this.notify('Please connect to Voip Server in order to make calls');
      return;
    }
    if (this.activeCall) {
      this.notify('Already have an active call');
      return;
    }
    this.phone.call(`sip:${to}@${this.config.domain}`, {
      extraHeaders: ['X-Client-Type: pancake'],
      RTCConstraints: {
        optional: [{ DtlsSrtpKeyAgreement: 'true' }],
      },
      mediaConstraints: {
        audio: true,
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: 'negotiate',
        // iceServers: [
        //   {
        //     urls: ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302'],
        //   },
        // ],
      },
      rtcOfferConstraints: {
        offerToReceiveAudio: 1,
        // offerToReceiveVideo: 1,
      },
      sessionTimersExpires: 600,
    });
  };

  this.answer = (sessionId) => {
    if (this.activeCall) {
      this.notify('Already have an active call');
    } else if (this.activeChanel.inCall) {
      this.notify('Current chanel is busy');
    } else {
      // Stop the annoying ring ring
      this.stopRing();

      // Get the call from calls Queue
      this.activeCall = _.find(this.callsQueue, { id: sessionId });
      if (this.activeCall) {
        this.activeCall.customPayload = this.activeChanel.id;
        this.activeCall.answer({
          mediaConstraints: {
            audio: true,
          },
        });

        // Connect Audio
        this.connectAudio();
      }
    }
  };

  this.hungup = (e) => {
    try {
      this.phone._sessions[e].terminate();
    } catch (e) {}
  };

  this.start = () => {
    if (!this.initiated) {
      this.notify('Error: 356 Please report');
      return;
    }

    if (this.config.debug) {
      debug.enable('JsSIP:*');
    } else {
      debug.disable();
    }
    this.phone.start();
  };

  this.stop = () => {
    this.phone.stop();
  };
}

export default CallsFlowControl;
