import { useSelector } from 'react-redux';
import { selectProfile } from '../features/user/userSlice';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosClient from '../api/axiosClient';
export const conventData = (data) => {
  const DO = Object.keys(data);
  var p = '';
  for (var i = 0; i < DO.length; i++) {
    if (i > 0) {
      p = p + `&${DO[i]}=${data[DO[i]]}`;
    } else {
      p = p + `${DO[i]}=${data[DO[i]]}`;
    }
  }
  return p;
};
export const covertNumberPrice = (data) => {
  const formattedNumber = data.replace(/,/g, '');
  const dataT = `${formattedNumber}`;
  if (dataT === 'undefined') {
    return 0;
  }

  var c = Number(dataT.length) % 3;
  var h = c === 0 ? 3 : c;
  var m = dataT.slice(0, h);

  for (var i = 1; i < dataT.length; i++) {
    if (i % 3 === 0) {
      if (i / 3 === 1) {
        m = m + `,${dataT.slice(h, h + 3 * (i / 3))}`;
      } else {
        m = m + `,${dataT.slice(h + 3 * (i / 3 - 1), h + 3 * (i / 3))}`;
      }
    }
  }
  return m;
};

export const CheckPermission = (module) => {
  const profile = useSelector(selectProfile);

  const arr = profile?.group?.listPermission
    ?.find((v) => v?.name === module)
    ?.children?.map((k) => k?.functions?.map((y) => ({ name: y?.name, enable: y?.enable }))?.filter((t) => t?.enable))
    .flat();
  return arr?.map((v) => v?.name);
};

export const formatFullName = (fullName) => {
  if (!fullName) return '';
  let res = '';
  fullName
    .toLowerCase()
    .split(' ')
    .map((name) => (res += name[0].toUpperCase() + name.slice(1) + ' '));
  return res.trim();
};

export const covertPhone = (number) => {
  if (!number) {
    return '';
  }
  const end = String(number).slice(-4);
  const state = String(number)?.slice(0, 2);
  return `${state}*****${end}`;
};

export const groupBy = (x, f) => x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});
function useWindowFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  useEffect(() => {
    const handleWindowFocus = () => setIsWindowFocused(true);
    const handleWindowBlur = () => setIsWindowFocused(false);

    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleVisibilityChange = () => setIsWindowFocused(!document.hidden);

    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, []);

  return isWindowFocused;
}

export { useWindowFocus };

export const phone = (phone) => {
  if (phone === '' || phone === undefined || phone?.length < 4) {
    return '';
  }
  var phoneNew = '';
  var star = '';
  for (var i = 0; i < phone?.length - 3; i++) {
    star = star + '*';
  }
  for (var i = phone?.length - 3; i < phone?.length; i++) {
    phoneNew = phoneNew + phone[i];
  }

  return `${star}${phoneNew}`;
};
