import axiosClient from './axiosClient';

const customerApi = {
  getAll(params) {
    const url = '/contact/';
    return axiosClient.get(url, { params });
  },
  campaign(data) {
    const url = '/contact/campaign/';
    return axiosClient.post(url, data);
  },
  checkCustomer(params){
    const url = '/contact/search/';
    return axiosClient.get(url,{params})
  },
  saveFile(params){
    const url = '/sample-files/';
    return axiosClient.get(url,{params})
  },
 upFile(data){
    const url = '/contact/upload/';
    return axiosClient.post(url,data)
  }
};
export default customerApi;
