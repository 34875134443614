import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleSidebar: false,
  ListNarbarFooter: [ {
    name: 'Dashboard',
    path: 'dashboard',
  },
  {
    name: 'Customer',
    path: 'customer',
  },
  {
    name: 'Consultation',
    path: 'consultation',
  },],
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    },
    AddSiidebar: (state, action) => {
      state.ListNarbarFooter = action.payload;
    },
  },
});

export const { toggleSidebar, AddSiidebar } = sidebarSlice.actions;

// Selector
export const isOpenSidebar = (state) => state.sidebar.toggleSidebar;
export const selectListNarbar = (state) => state.sidebar.ListNarbarFooter;
const { reducer: sidebarReducer } = sidebarSlice;
export default sidebarReducer;
