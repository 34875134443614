import { createAsyncThunk } from '@reduxjs/toolkit';
import authApi from '../../api/auth';

export const LoginApi = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
  try {
    const response = await authApi.login(payload);

    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
