import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { DialogNotification } from './dialog-notification';
import { MdPhoneInTalk, MdSupportAgent } from 'react-icons/md';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaPhoneAlt } from 'react-icons/fa';

import { useState } from 'react';
// import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
// import axiosInstance from "../axiosApi";

const ConfirmModal = React.forwardRef((props, ref) => {
  const { onClose } = props;
  const [hotline, setHotline] = useState();
  // const profile = useSelector(selectProfile);
  const cancelRef = React.useRef(null);

  props.webSocket.current.onmessage = (message) => {
    const mes = JSON.parse(message.data);

    // setActive(true);

    setHotline(mes?.message?.hotline);
  };
  // }, [url]);
  // const hostLine = useSelector(SelecetnumberPhone);

  // useEffect(() => {
  //   const handleSearchInfoByPhone = async () => {
  //     try {
  //       const res = await axiosInstance.get(
  //         `/leads/?phone=${props.phoneNumber}`
  //       );
  //       if (res.status === 200) {
  //         setData(res.data);
  //       }
  //     } catch (error) {}
  //   };
  //   handleSearchInfoByPhone();
  // }, [props.phoneNumber]);

  useImperativeHandle(ref, () => ({
    closeDialogCall: () => {
      onClose(false);
    },
  }));
  // const isPhone = phoneRegExp.test(props.phoneNumber) ? '19000354' : '9919000354';
  return (
    <>
      <DialogNotification leastDestructiveRef={cancelRef} overlayClassName={'z-999999'}>
        <div className='popup-confirm' style={{ background: 'linear-gradient(180deg, #0065FF 0%, #8CBAFF 77.6%)' }}>
          <button onClick={() => onClose(false)} className='btn-close'>
            <IoIosCloseCircleOutline />
          </button>

          {/* <div className='box-info'>
            <div className='photo'>
              <MdSupportAgent />
            </div>
            <div className='content'>
              <p className='font-semibold'>{props.title}</p>
              <p className='text-gray'>{props.message}</p>
              <p className='font-semibold'>
                <span className='text-red-600'>Hotline: </span>

                {isPhone}
              </p>
            </div>
          </div> */}
          <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '30px',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <MdPhoneInTalk style={{ fontSize: '45px', color: '#2B7FFF' }} />
              </div>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '17px' }}>
              <div style={{ fontWeight: '500', fontSize: '14px', color: 'white' }}>
                <spam>{props?.phoneNumber}</spam>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ fontWeight: '500', fontSize: '20px', color: 'white' }}>
                <span>Hotline : {hotline}</span>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ fontWeight: '500', fontSize: '20px', color: 'white' }}>
                <span>Cuộc gọi thoại đến</span>
              </div>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
              <div
                style={{
                  width: '66px',
                  height: '66px',
                  borderRadius: '60px',
                  backgroundColor: '#00BA88',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => onClose(true)}
                ref={cancelRef}
              >
                <FaPhoneAlt style={{ fontSize: '35px', color: 'white' }} />
              </div>
              <div
                style={{
                  width: '66px',
                  height: '66px',
                  borderRadius: '60px',
                  backgroundColor: '#DF3030',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => onClose(false)}
              >
                <FaPhoneAlt style={{ fontSize: '35px', color: 'white', transform: 'rotate(135deg)' }} />
              </div>
            </div>
            {/* 
            <div className='button-group'>
              <button onClick={() => onClose(true)} ref={cancelRef} className='btn'>
                Chấp thuận
              </button>
              <button onClick={() => onClose(false)} className='btn bg-gray'>
                Từ chối
              </button>
            </div> */}
          </div>
        </div>
      </DialogNotification>
    </>
  );
});

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Warning!',
  confirmText: 'Ok',
  confirmClass: 'primary',
  cancelText: 'Cancel',
  cancelClass: '',
  className: '',
  buttonsComponent: null,
  size: null,
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
  title: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  buttonsComponent: PropTypes.func,
};

export default ConfirmModal;
