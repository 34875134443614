import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  hostLinePhone: 0,
  phonecheck:{}
};

const PhoneSlice = createSlice({
  name: 'phoneHotline',
  initialState,
  reducers: {
    getHotLine: (state, action) => {
      state.hostLinePhone = action?.payload;
    },
    getPhoneCheck: (state,action) => {
      state.phonecheck = action.payload
    },
    clearphone:(state) => {
      state.phonecheck= {}
    }
  },
});

// Actions
export const { getHotLine ,getPhoneCheck,clearphone} = PhoneSlice.actions;

// Selector
export const selectloadingRepor = (state) => state.phone.loading;
export const selectHotPhone = (state) => state.phone.hostLinePhone;
export const selectPhoneCheck = (state) => state.phone.phonecheck
// Reducer
const { reducer: PhoneSliceReduce } = PhoneSlice;
export default PhoneSliceReduce;
