import axiosClient from './axiosClient';

const ConsultationApi = {
  getDetailCustomer(params) {
    const url = '/contact/campaign/';
    return axiosClient.get(url, { params });
  },
  CallStatus(data) {
    const url = '/contact/cdr/';
    return axiosClient.patch(url, data);
  },
  checkCustomer(data) {
    const url = '3';
    return axiosClient.post(url, data);
  },
  saveCustomer(data) {
    const url = '/contact/';
    return axiosClient.put(url, data);
  },

  AddCustomer(data) {
    const url = '/contact/customer-campaign/';
    return axiosClient.post(url, data);
  },
  saveInfomation(data) {
    const url = `/payment/payment/${data?.id}/`;
    return axiosClient.post(url, data);
  },
  getInfomation(id, params) {
    const url = `/payment/payment/${id}/`;
    return axiosClient.get(url, { params });
  },
  getdocument() {
    const url = '/payment/document';
    return axiosClient.get(url);
  },
  getproduct() {
    const url = '/payment/products';
    return axiosClient.get(url);
  },
};
export default ConsultationApi;
