import React from 'react';

const LoanInfo = () => {
  return (
    <div className=''>
      <div>Loan info</div>
    </div>
  );
};

export default LoanInfo;
