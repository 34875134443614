import React, { useState } from 'react';
import { useDispatch } from 'react-redux';


import { saveAs } from 'file-saver';


import Button from '../common/Button';
import LoaddingCoaster from '../common/LoaddingCoaster';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import customerApi from '../../api/customer';
import axios from 'axios';

const ImportExcel = ({ setActiveExcel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [file, setFile] = useState();

  const location = useLocation();

  const [listExcel, setListExcel] = useState();
  const [active, setActive] = useState(false);
  const [save, setSave] = useState(false);

  const mution = useMutation({
    mutationKey:['uploadfile'],
    mutationFn: (data) => {
      return customerApi.upFile(data);
    },
    onSuccess: (data, variables, context) => {
      // setActiveExcel(false)
      setActive(false);

      setListExcel(data?.data)
      console.log('data',data);
      if(data?.data?.success > 0){
        navigate('/admin/customer')
      }
   

    },
    onError: (data, variables, context) => {
      
      setActive(false);
  

    },
  })


  const handleCreate = () => {
    const formData = new FormData();
    
       formData.append('contacts', file)
  
    setActive(true);
    setSave(true);

    mution.mutate(formData)

   
  };

  const handleExcel = (e) => {
    setActive(false);
    setFile(e.currentTarget.files[0]);
    setSave(false);
    setListExcel();
  };
  const {data} = useQuery({
    queryKey:['sample'],
    queryFn: () => customerApi.saveFile({code:'contacts'}),
    staleTime:60*100000,
    // enabled:false
  })
  const importTemplateExcel = () => {
    saveAs(data?.data?.files[0].file);
    // axios.get('https://mafc-be.videocall.vn/static/samples/data_fxTPH8B.csv')

    // dispatch(getTemplateExcelApi({ code: location.pathname.includes('product') ? 'SP' : 'KH' }))
    //   .unwrap()
    //   .then((res) => {
    //     saveAs(res?.data?.thumbnail);
    //   });
  };

  return (
    <div className='content'>
      <div style={{ display: 'grid', gridTemplateColumns: '98%', gap: '2%', rowGap: '20px', marginBottom: '10px' }}>
        <div>
          <div className='font-bold' style={{ ...color, cursor: 'pointer' }} onClick={importTemplateExcel}>
          download file
          </div>
          <div style={{ ...blockExcel }}>
            {file?.name ? <span style={{ color: 'black' }}> {file?.name}</span> : `${t('uploadFile')}.xlsx`}
            <input style={cssInputFile} type='file' accept='.csv' onChange={handleExcel} />
          </div>
        </div>
      </div>
      <div style={{}}>
        {active && <LoaddingCoaster />}
        {active && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
            <p style={{ width: '20px', border: '1px solid black' }}></p>
          </div>
        )}
        {active && <div style={{ display: 'flex', justifyContent: 'center' }}> {t('loading_data')} ...</div>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <b> {listExcel && t('data_loaded_successfully')} </b> <span> : </span><span style={{marginLeft:'5px',color:'green'}}> {listExcel?.success}</span> 
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <b> {listExcel?.fail && t('already_in_system') } </b> <span> :  </span>  <span style={{marginLeft:'5px',color:'red'}}>{listExcel && listExcel?.fail}</span>  
      </div>

      <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
        {/* {active && ( */}
        {!save && (
          <Button className='bg-theme' onClick={handleCreate}>
            {t('save')}
          </Button>
        )}

        {save && (
          <Button
            className='bg-theme'
            onClick={() => {
              setActiveExcel(false)
            }}
          >
            
            {t('cancel')}{' '}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ImportExcel;

export const color = {
  color: '#00BFFF',
};
export const blockExcel = {
  border: '1px dashed #00BFFF',
  height: '100px',
  borderRadius: '5px',
  cursor: 'pointer',
  color: '#00BFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const cssInputFile = {
  border: '1px solid red',
  height: '100px',
  position: 'absolute',
  opacity: '0',
};
