import React, { useEffect, useState } from 'react';
import DashboardApi from '../../../../api/dashboardApi';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { showMessageError, showMessageSuccess } from '../../../../features/alert/alert-message';
import { BsCalendar3 } from 'react-icons/bs';

import 'react-datepicker/dist/react-datepicker.css';

const NewTodo = ({ setModalTodo }) => {
  const dispatch = useDispatch();
  const [dataTodo, setDataTodo] = useState({});
  const [dateTodo, setDateTodo] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const postTodo = useMutation({
    mutationFn: (data) => DashboardApi.postCalendar(data),
    onSuccess: (data) => {
      dispatch(showMessageSuccess('Tạo ghi nhớ thành công '));
      setModalTodo(false);
    },
    onError: (data) => {
      dispatch(showMessageError('Tạo ghi nhớ chưa thành công'));
      setModalTodo(false);
    },
  });

  const onSubmit = () => {
    postTodo.mutate({ duedate: dateTodo, owner: 1, ...dataTodo });
  };

  const handleChange = (e) => {
    setDataTodo({ ...dataTodo, [e.target.name]: e.target.value });
  };

  console.log('todoNew', dataTodo);
  console.log('dateTodo', dateTodo);

  return (
    <>
      <div className='form-post__todo p-[20px]'>
        <form>
          <div className='input-date control-alls'>
            <DatePicker
              showTimeSelect
              timeFormat='HH:mm:ss'
              placeholderText={dateTodo}
              onChange={(date) => setDateTodo(moment(date).format('YYYY-MM-DD HH:MM:SS'))}
            />
            <BsCalendar3 />
          </div>
          <textarea
            rows={3}
            type='text'
            name='description'
            placeholder='description'
            onChange={handleChange}
            className='control-alls'
          ></textarea>
          <button
            className='btn-blues'
            type='submit'
            onClick={(event) => {
              event.preventDefault();
              onSubmit();
            }}
          >
            Tạo ghi nhớ
          </button>
        </form>
      </div>
    </>
  );
};

export default NewTodo;
