import React from 'react';
import { selectConsultation } from '../../../../features/consultation/ConsultationSlice';
import { useSelector } from 'react-redux';
import { addHours, format } from 'date-fns';

const Consthist = () => {
  const data = useSelector(selectConsultation);

  const list = [
    'No',
    'Date',
    'Call Div.',
    'Call No.',
    'Call Rslt',
    'Call Rcpt',
    'Assigned Date',
    'Assigned Div.',
    'Campaign Name',
    'Main Category',
    'Sub Category',
    'Hang up Type',
  ];
  return (
    <div className='Consthist_block'>
      <div>
        <div className='Consthist_block_value Consthist_block_value-title'>
          {list.map((v, i) => (
            <div key={i} className='Consthist_item_value'>
              {v}
            </div>
          ))}
        </div>
      </div>
      <div>
        {data?.histories?.map((el, i) => (
          <div className='Consthist_block_value' key={i}>
            <div className='Consthist_item_value'>{el?.id}</div>
            <div className='Consthist_item_value'>
              <div>{format(addHours(new Date(el?.createdOn), 7), 'yyyy-MM-dd HH:mm:ss')}</div>
            </div>
            <div className='Consthist_item_value'>{'outbound'}</div>
            <div className='Consthist_item_value'>
              <div>{data?.mobile ? hidePhoneNumber(data?.mobile) : hidePhoneNumber(data?.phone)}</div>
            </div>
            <div className='Consthist_item_value'>
              <div>{el?.callResult ? el?.callResult?.name : ''}</div>
            </div>
            <div className='Consthist_item_value'>
              <div>{el?.callReceived ? el?.callReceived?.name : ''}</div>
            </div>
            <div className='Consthist_item_value'></div>
            <div className='Consthist_item_value'>
              <div>{el?.campignAssign}</div>
            </div>
            <div className='Consthist_item_value'>
              <div>{el?.callId}</div>
            </div>
            <div className='Consthist_item_value'>
              <div>{el?.subCategory ? el?.subCategory.parent.name : ''}</div>
            </div>
            <div className='Consthist_item_value'>
              <div>{el?.subCategory ? el?.subCategory?.name : ''}</div>
            </div>
            <div className='Consthist_item_value'>{el?.revert ? 'không' : 'có'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
function hidePhoneNumber(phoneNumber) {
  const visibleDigits = 3;
  const hiddenDigits = phoneNumber.length - visibleDigits;
  return phoneNumber.length > 7 ? '*'.repeat(hiddenDigits) + phoneNumber.slice(-visibleDigits) : '';
}
export default Consthist;
