import axiosClient from '../../../../api/axiosClient';

const CommonApi = {
  callRecei() {
    const url = '/call-recei/';
    return axiosClient.get(url);
  },
  callResult(params) {
    const url = '/call-result/';
    return axiosClient.get(url, { params });
  },
  mainCategory() {
    const url = '/main-category/';
    return axiosClient.get(url);
  },
  Occupation() {
    const url = '/occupation/';
    return axiosClient.get(url);
  },
  cities(params) {
    const url = '/cities/';
    return axiosClient.get(url, { params });
  },
};
export default CommonApi;
