import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectStatusCall, updateRegisteringCall, updateUnregisterCall } from '../../features/call/callSlice';

import Alert from '../../components/common/Alert';
import Button from '../../components/common/Button';
import PhoneBox from './phone-box';
import TestCall from './phone-call';
import { selectProfile } from '../../features/user/userSlice';

import { useTranslation } from 'react-i18next';

const PhoneConnect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const statusCall = useSelector(selectStatusCall);

  const [showPhone, setShowPhone] = useState(false);
  const [showDialogCall, setShowDialogCall] = useState(false);

  const [statusSip, setStatusSip] = useState('');
  const [disablePhone, setDisablePhone] = useState(false);

  var orgCode = profile?.org?.unique_id;
  const webSocket = useRef(null);
  var url = `${process.env.REACT_APP_API_WSS}/ws/socket-server/?org_Name=${orgCode}`;

  const Socket = (value) => {
    if (orgCode !== undefined) {
      webSocket.current = new WebSocket(url);

      webSocket.current.onopen = () => {
        webSocket.current.send(value);
      };

      webSocket.current.onclose = function (evt) {};
    }
  };
  const toast_type = {
    error: 'error',
    success: 'success',
    warning: 'warning',
  };
  useEffect(() => {
    // dispatch(updateUnregisterCall());
    Socket(JSON.stringify({ type: 'true', phone: '1001' }));
    dispatch(updateRegisteringCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (statusSip === 'disconnected') {
  //     dispatch(updateUnregisterCall());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [statusSip]);

  // const handleClickMenu = () => {
  //   if (statusCall.registered === 'unregistered') {
  //     dispatch(updateRegisteringCall());
  //     Socket(JSON.stringify({ type: 'true', phone: '1001' }));
  //   } else {
  //     dispatch(updateUnregisterCall());
  //     Socket(JSON.stringify({ type: 'false', phone: '1001' }));
  //     setShowPhone(false);
  //   }
  // };
  // // useEffect = () => {
  // //   handleClickMenu();
  // // };

  // useEffect(() => {
  //   if (profile.length === 0) {
  //     return;
  //   }
  //   if (Object.keys(profile).length !== 0 && profile.agent) {
  //     setDisablePhone(false);
  //     handleClickMenu();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [profile]);

  return (
    <>
      <div
        style={{
          display: statusCall?.numberPhone || showPhone ? 'block' : 'none',
        }}
      >
        <TestCall
          setShowPhone={setShowPhone}
          setShowDialogCall={setShowDialogCall}
          showDialogCall={showDialogCall}
          showPhone={showPhone}
          setStatusSip={setStatusSip}
        />
      </div>
    </>
  );
};

export default PhoneConnect;
