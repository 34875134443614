import React, { useEffect, useState } from 'react';
import './Footer.css';
import { BsCalendar2Fill, BsCalendar2XFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AddSiidebar, selectListNarbar } from '../../features/sidebar/sidebarSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listnarbar = useSelector(selectListNarbar);
  const [key, setkey] = useState();
  const [count, setCount] = useState();
  const location = useLocation()
  

  useEffect(() => {
    setkey(listnarbar[count === undefined ? listnarbar.length - 1 : count]?.path);
  }, [listnarbar]);

  useEffect(() => {

setkey(location?.pathname)
  },[location])
  const Closeitem = (item) => {
    const deleteItem = listnarbar.filter((v) => v?.path !== item);
    return deleteItem;
  };
  return (
    <div className='Block_footer'>
      <div className='Block_footerChid'>
        <div className='footerChid'>
          <div>
            <BsCalendar2Fill />
          </div>
          <div>Find</div>
        </div>
        <div className='footerChid BttomBorder'>
          <div>
            <BsCalendar2XFill style={{ backgroundColor: 'red' }} />
          </div>
          <div
            onClick={() => {
              dispatch(AddSiidebar([]));
              navigate(`/admin/dashboard`);
            }}
            style={{ cursor: 'pointer' }}
          >
            Close All
          </div>
        </div>
      </div>
      <div className='footerNarbar'>
        {listnarbar.map((v, i) => (
          <div className='itemFooter' style={{ backgroundColor: key?.includes(v?.path) && '#f4a460' }}>
            <div
              key={i}
              onClick={() => {
                navigate(`/admin/${v?.path}`);
                setkey(v?.path);
              }}
              style={{ cursor: 'pointer' }}
              className='itemTitleFooter'
            >
              {v?.name}
            </div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(AddSiidebar(Closeitem(v?.path)));
                if (v?.path === key) {
                  if (key === listnarbar[0].path) {
                    if (listnarbar.length === 1) {
                      navigate(`/admin/dashboard`);
                    } else {
                      navigate(`/admin/${listnarbar[1].path}`);
                      setkey(listnarbar[1].path);
                      setCount(0);
                    }
                  } else {
                    if (key === listnarbar[listnarbar.length - 1].path) {
                      navigate(`/admin/${listnarbar[i - 1].path}`);
                      setkey(listnarbar[i - 1].path);
                      setCount(i - 1);
                    } else {
                      navigate(`/admin/${listnarbar[i + 1].path}`);
                      setkey(listnarbar[i].path);
                      setCount(i);
                    }
                  }
                }
              }}
            >
              x
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
