import FindDataCustomer from '../page/admin/FindDataCustomer/FindDataCustomer';
import Consultation from '../page/admin/consultation/consultation';
import ConsultationAsslst from '../page/admin/consultationAsslst/consultationAsslst';
import LayoutConsultationAsslst from '../page/admin/consultationAsslst/layoutConsultationAsslst';
import Custorm from '../page/admin/customer/custorm';
import LayoutCustorm from '../page/admin/customer/layoutCustorm';
import Dashboard from '../page/admin/Dashboard/dashboard';
import LayoutDashboard from '../page/admin/Dashboard/layoutDashboard';
import LayoutReports from '../page/admin/reports/layoutReports';
import Reports from '../page/admin/reports/reports';
import Layoutuploadfile from '../page/admin/Updaloadfile/layoutuploadfile';

export const router = [
  {
    path: 'dashboard',
    component: <LayoutDashboard />,
    child: [
      {
        path: '',
        component: <Dashboard />,
      },
    ],
  },

  {
    path: 'customer',
    component: <LayoutCustorm />,
    child: [
      {
        path: '',
        // component: <Custorm />,
        component: <FindDataCustomer />,
      },
    ],
  },
  {
    path: 'reports',
    component: <LayoutReports />,
    child: [
      {
        path: '',
        component: <Reports />,
      },
    ],
  },

  {
    path: 'consultationAsslst',
    component: <LayoutConsultationAsslst />,
    child: [
      {
        path: '',
        component: <ConsultationAsslst />,
      },
    ],
  },
  {
    path: 'consultation',
    component: <LayoutCustorm />,
    child: [
      {
        path: '',
        component: <Consultation />,
      },
    ],
  },
  {
    path: 'uploadFile',
    component: <Layoutuploadfile />,
   
  },

  
];

export const user = [];

export const listTableDocument = [{ name: 'STT' }, { name: 'Tên Tài Liệu' }, { name: 'Hành Động' }];
export const listTableReportCdr = [
  { name: 'ID' },
  { name: 'Ngày gọi' },
  { name: 'Thời gian gọi' },
  { name: 'Cuộc gọi (đến - đi)' },
  { name: 'Nguồn' },
  { name: 'Gọi đến' },
  { name: 'File ghi âm' },
];
