import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../../../styles/components/Consultation/layoutConsultation.css';
import AsgList from './component/AsgList';
import PersonalInfo from './component/PersonalInfo';
import Select from 'react-select';
import { styles } from '../../../scss/cssCustoms';
import { BsChevronRight, BsFillRecordFill } from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import { useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import ConsultationApi from '../../../api/Consultation';
import { ThemContext } from '../../../components/common/ThemContext';
import BasicInfo from './component/BasicInfo';
import LoanInfo from './component/LoanInfo';
import PLCalculator from './component/PLCalculator';
import DCInfo from './component/DCInfo';
import Consthist from './component/Consthist';
import TendencyInfo from './component/TendencyInfo';
import ConsulttationScript from './component/ConsulttationScript';
import PopupCustInfo from './component/popupCustInfo';
import PopupInfomation from './component/popupInfomation';
import AddCustomer from './component/addCustomer';
import CommonApi from './component/common';
import { EndCall, NotReady, selectMakeCallID, selectStatusCall } from '../../../features/call/callSlice';
import { useDispatch } from 'react-redux';
import '../../../styles/components/Consultation/index.scss';
import { useTranslation } from 'react-i18next';
import { GetSubCategory } from '../../../api/commonApi/commonApi';
import { updateDataDetai } from '../../../features/consultation/ConsultationSlice';
import { useSelector } from 'react-redux';
import { clearphone, selectPhoneCheck } from '../../../features/phone/phoneSlice';
import { refreshFnc } from '../../../features/customer/customerSlice';

const Consultation = () => {
  const { t } = useTranslation();
  const loaddingContext = useContext(ThemContext);
  const statusCall = useSelector(selectStatusCall);
  const dispatch = useDispatch();

  const [key, setKey] = useState('Asg');
  const [keyDetail, setKeyDetail] = useState('basic');
  const [keydetailBottom, setkeyDetailbottom] = useState('const');
  const [keySelect, setkeySelect] = useState('chocolate');
  const [datastatus, setDataStatus] = useState({});
  const [statusCustInfo, setStatusCustInfo] = useState(false);
  const [statusInfomation, setStatusInfomation] = useState(false);
  const [checkPupup, setCheckPupup] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const makeCallid = useSelector(selectMakeCallID);
  const [keyMain, setKeyMain] = useState();
  const [valueSub, setValueSub] = useState();
  const [valueMain, setValueMain] = useState();
  const [rslt, setRslt] = useState();
  const [rcpt, setRcpt] = useState();
  const phoneCheck = useSelector(selectPhoneCheck);
  const id = '';
  const titleList = [
    { name: 'Asg. List', key: 'Asg' },
    { name: 'Presonal Info', key: 'Info' },
  ];

  const detailListTap = [
    { name: 'Basic Info', key: 'basic' },
    { name: 'Loan Info', key: 'Loan' },
    { name: 'PL calculator', key: 'pl' },
    { name: 'DC Info', key: 'dc' },
  ];

  const detailBottomtap = [
    { name: 'Const. hist', key: 'const' },
    { name: 'Tendency Info', key: 'Tendency' },
    { name: 'Consultation Script', key: 'Consultation' },
  ];

  const valueList = {
    Asg: <AsgList setKey={setKey} />,
    Info: <PersonalInfo />,
  };

  const valueListTap = {
    basic: <BasicInfo />,
    Loan: <LoanInfo />,
    pl: <PLCalculator />,
    dc: <DCInfo />,
  };
  const valueDetailBottomTap = {
    const: <Consthist />,
    Tendency: <TendencyInfo />,
    Consultation: <ConsulttationScript />,
  };
  useEffect(() => {
    dispatch(updateDataDetai({}));
  }, []);

  const dataResult = useQuery({
    queryKey: ['callResult'],
    queryFn: () => CommonApi.callResult(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const dataRecei = useQuery({
    queryKey: ['callRecei'],
    queryFn: () => CommonApi.callRecei(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const mainCategory = useQuery({
    queryKey: ['maincategory'],
    queryFn: () => CommonApi.mainCategory(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  // setCallRslt(
  //   dataResult?.data?.data?.results?.map((v) => ({
  //   label: v?.name,
  //   value:v?.id
  //  })))
  const { data: subcategory } = GetSubCategory();

  const handleSelect = (value) => {
    setDataStatus({ ...datastatus, ['callResult']: value.value });
    setRslt(value?.label);
  };

  const handleSelectRcpt = (value) => {
    setDataStatus({ ...datastatus, ['callReceived']: value.value });
    setRcpt(value?.label);
  };
  const handleChageMain = (value) => {
    setKeyMain(value.value);
    setValueMain(value?.label);
    // setDataStatus({ ...datastatus, ['mainCategory']: value.target.value });
  };

  const handleChageSup = (value) => {
    setValueSub(value?.label);
    setDataStatus({ ...datastatus, ['subCategory']: Number(value.value) });
    if (value.value === 6) {
      if (checkPupup) {
        setStatusInfomation(true);
      } else {
        setStatusCustInfo(true);
      }
    }
  };

  const mutation = useMutation({
    mutationFn: (dataStatus) => {
      return ConsultationApi.CallStatus(dataStatus);
    },
    onSuccess: (data, variables, context) => {
      alert('Cập nhật thành công');
      setValueSub('');
      setValueMain('');
      setRcpt('');
      setCheckPupup();
      setStatusCustInfo();
      setRslt('');
      dispatch(clearphone());
      setKey('Asg');
      dispatch(updateDataDetai({}));
      dispatch(refreshFnc(Math.floor(Math.random() * 10)));
    },
    onError: (data, variables, context) => {},
  });

  const saveStatus = () => {
    dispatch(NotReady());

    rslt && mutation.mutate({ makeId: makeCallid, ...datastatus });
  };

  const addCustomerComponent = useMemo(() => {
    if (addCustomer) return <AddCustomer setStatusCustInfo={setAddCustomer} />;
    return null;
  }, [addCustomer]);
  console.log('statusCall.status', statusCall.status);
  return (
    <div className='block_Consultation'>
      {addCustomerComponent}
      {statusInfomation && <PopupInfomation setStatusInfomation={setStatusInfomation} />}
      {statusCustInfo && (
        <PopupCustInfo
          setValueSub={setValueSub}
          setStatusInfomation={setCheckPupup}
          setStatusCustInfo={setStatusCustInfo}
        />
      )}
      <div className='Consultation_chid_one'>
        <div>[CCLT2010] {t('Consultation -O/B')}</div>
        <div>HELP</div>
      </div>
      <div className='Consultation_chid_two'>
        <div className=''>
          <div className='CT-item-contains-one'>
            <div className='Contains_one_1'>
              <div className='block_one_1'>
                {titleList.map((v, i) => (
                  <div
                    key={i}
                    className='Item_title_list'
                    style={{ backgroundColor: key === v?.key && '#EECFA1', border: '1px solid #ccc' }}
                    onClick={() => {
                      setKey(v?.key);
                      setValueSub('');
                      setValueMain('');
                      setRcpt('');
                      setRslt('');
                      setCheckPupup();
                      setStatusCustInfo();
                      dispatch(clearphone());
                      if (v.key === 'Asg') {
                        dispatch(updateDataDetai({}));
                      }
                    }}
                  >
                    {t(v?.name)}
                  </div>
                ))}
              </div>
              <div className='block_one_2'>
                <div className='item_block_one_2'>B/L</div>
                <div className='item_block_one_2'>Tdcy</div>
                <div
                  className='item_block_one_2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setAddCustomer(true);
                  }}
                >
                  Reg
                </div>
              </div>
            </div>
            <div style={{ border: '1px solid #ccc' }} className='Contains_one_2'>
              {valueList[key]}
            </div>
          </div>
          <div className='CT-item-contains-two'>
            <div className='Contains_one_1'>
              <div className='block_one_1'>{t('Result')}</div>
              <div className='block_one_2'>
                <div className='item_block_one_2'>{t('Clear')}</div>
                <div className='item_block_one_2'>{t('Without call')}</div>
                <div className='item_block_one_2'>{t('Statisfaction')}</div>
                <div
                  className='item_block_one_2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(EndCall());
                  }}
                >
                  {t('Release')}
                </div>
              </div>
            </div>
            <div className='contains-two_one_2'>
              <div className='contains-two_item_title'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{t('Call Rslt')}.</div>
                  <div style={{ width: '200px' }}>
                    {' '}
                    <Select
                      options={
                        phoneCheck?.status === 'Không nghe máy'
                          ? dataResult?.data?.data?.results
                              ?.filter((k) => k?.name !== 'Success')
                              ?.map((v) => ({
                                label: v?.name,
                                value: v?.id,
                              }))
                          : dataResult?.data?.data?.results?.map((v) => ({
                              label: v?.name,
                              value: v?.id,
                            }))
                      }
                      isDisabled={statusCall.status !== 'After Work'}
                      value={{ label: rslt }}
                      onChange={handleSelect}
                      styles={styles}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {' '}
                  <div>{t('Call Rcpt')}.</div>
                  <div style={{ width: '200px' }}>
                    {' '}
                    <Select
                      options={dataRecei?.data?.data?.results?.map((v) => ({
                        label: v?.name,
                        value: v?.id,
                      }))}
                      value={{ label: rcpt }}
                      onChange={handleSelectRcpt}
                      styles={styles}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginLeft: '8px' }}>
                <div
                  className='Item_title_list'
                  style={{ display: 'flex', alignItems: 'center', border: '1px solid #97b6da', borderBottom: 'none' }}
                >
                  <BsFillRecordFill style={{ fontSize: '12px' }} /> {t('Consultation')}
                </div>
              </div>

              <div style={{ border: '1px solid #97b6da', marginLeft: '8px', height: '135px' }}>
                <div style={{ margin: '5px' }}>
                  <div className='PersonalInfo_chid_item'>
                    <div className='item_left'>{t('Main Category')}</div>
                    <div className='Item_right'>
                      {' '}
                      <Select
                        options={mainCategory?.data?.data?.results?.map((v) => ({
                          label: v?.name,
                          value: v?.id,
                        }))}
                        value={{ label: valueMain }}
                        onChange={handleChageMain}
                        styles={styles}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        menuPlacement='top'
                      />
                    </div>
                  </div>
                  <div className='PersonalInfo_chid_item'>
                    <div className='item_left'>{t('Sub Category')}</div>
                    <div className='Item_right'>
                      {' '}
                      <Select
                        options={subcategory
                          ?.filter((k) => k?.parent?.id === Number(keyMain))
                          ?.map((v) => ({
                            label: v?.label,
                            value: v?.value,
                          }))}
                        value={{ label: valueSub }}
                        onChange={handleChageSup}
                        styles={styles}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        menuPlacement='top'
                      />
                    </div>
                  </div>
                </div>
                <div style={{ margin: '5px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {' '}
                    <span style={{ color: '#8DEEEE' }}>
                      <BsChevronRight />
                    </span>{' '}
                    {t('Remark')}
                  </div>
                  <div>
                    <textarea style={{ height: '35px', width: '100%', border: '1px solid black' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
            <div className='block_one_2'>
              <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
                <span> {t('Call Reserve')}</span>
              </div>
              <div className='item_block_one_2' onClick={saveStatus} style={{ cursor: 'pointer' }}>
                {t('Save')}
              </div>
            </div>
          </div>
        </div>
        {/* block right */}
        <div className=''>
          <div className='CT-item-contains-one'>
            <div className='Contains_one_1'>
              <div className='block_one_1'>
                {detailListTap.map((v, i) => (
                  <div
                    key={i}
                    className='Item_title_list'
                    style={{ backgroundColor: keyDetail === v?.key && '#EECFA1' }}
                    onClick={() => {
                      setKeyDetail(v?.key);
                    }}
                  >
                    {t(v?.name)}
                  </div>
                ))}
              </div>
              <div className='block_one_2'>
                <div className='item_block_one_2'>B/L</div>
                <div className='item_block_one_2'>Tdcy</div>
                <div className='item_block_one_2'>Reg</div>
              </div>
            </div>
            <div className='Contains_one_2' style={{ height: '45vh' }}>
              {valueListTap[keyDetail]}
            </div>
          </div>
          <div className='block_one_2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
              <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
              <span>{t('Change Cust. Info')}</span>
            </div>
          </div>

          <div className='CT-item-contains-one'>
            <div className='Contains_one_1'>
              <div className='block_one_1'>
                {detailBottomtap.map((v, i) => (
                  <div
                    key={i}
                    className='Item_title_list'
                    style={{ backgroundColor: keydetailBottom === v?.key && '#EECFA1' }}
                    onClick={() => {
                      setkeyDetailbottom(v?.key);
                    }}
                  >
                    {t(v?.name)}
                  </div>
                ))}
              </div>
            </div>
            <div className='Contains_one_2' style={{ height: '13vh', width: '100%' }}>
              {valueDetailBottomTap[keydetailBottom]}
            </div>
          </div>
        </div>
      </div>

      <div className='Consultation_chid_three'>
        <div>3/13/2019 16:30:30.138 {t('Query Completed')}</div>
      </div>
    </div>
  );
};

export default Consultation;
