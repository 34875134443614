import React from 'react'
import ImportExcel from '../../../components/common/importExcel'
import Modal, { ModalContent } from '../../../components/common/Modal';
import { useState } from 'react';

const Layoutuploadfile = () => {

  const [activeExcel,setActiveExcel] = useState(true)
  return (
    <div>
         <Modal active={activeExcel}>
        <ModalContent
          className='pu-import'
          heading={'upload'}
          onClose={() => {
            setActiveExcel(false);
          }}
        >
          <ImportExcel setActiveExcel={setActiveExcel} />
        </ModalContent>
      </Modal>
        <button onClick={() => setActiveExcel(true)} style={{height:'20px',width:'100px',backgroundColor:'#97FFFF',margin:'10px',borderRadius:'3px',display:'flex',justifyContent:'center',alignItems:'center'}}>Upload</button>
    </div>
  )
}

export default Layoutuploadfile