import React from 'react';
import { BsChevronRight, BsFillRecordFill } from 'react-icons/bs';
import { selectConsultation } from '../../../../features/consultation/ConsultationSlice';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import CommonApi from './common';
import { useTranslation } from 'react-i18next';
import { phone } from '../../../../util/help';
import CustomNumberFormat from '../../../../components/common/CustomNumberFormat';

const BasicInfo = () => {
  const { t } = useTranslation();
  const value = useSelector(selectConsultation);
  
  const occupations = useQuery({
    queryKey: ['occupations'],
    queryFn: () => CommonApi.Occupation(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  const citys = useQuery({
    queryKey: ['citys'],
    queryFn: () => CommonApi.cities(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  console.log('value',value);
  return (
    <div className='BasicInfo_block'>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsFillRecordFill style={{ fontSize: '12px' }} /> {t('Presonal Info')}
        </div>
        <div className='BasicInfo_block_list'>
          <div className='BasicInfo_block_item'>{t('Cust Name')}</div>
          <div className='BasicInfo_block_item'>{value?.fullname}</div>
          <div className='BasicInfo_block_item'>{t('Salutation')}</div>
          <div className='BasicInfo_block_item'>{value?.salutation}</div>
          <div className='BasicInfo_block_item'>{t('Date of Birth')}</div>
          <div className='BasicInfo_block_item'>{value?.dateOfBirth}</div>
        </div>
        <div className='BasicInfo_block_list'>
          <div className='BasicInfo_block_item'>{t('Cust. ID No')}</div>
          <div className='BasicInfo_block_item'>{value?.idNo}</div>
          <div className='BasicInfo_block_item'>{t('Email')}</div>
          <div className='BasicInfo_block_item'>{value?.email}</div>
          <div className='BasicInfo_block_item'>{t('Tel (Mobile)')}</div>
          <div className='BasicInfo_block_item'>{value?.mobile && phone(value?.mobile)}</div>
        </div>
        <div className='BasicInfo_block_list1'>
          <div className='BasicInfo_block_item'>{t('Permanent Addr.')}</div>
          <div className='BasicInfo_block_item'>{value?.permanentAddres}</div>

          <div className='BasicInfo_block_item'>{t('Occupation')}</div>
          <div className='BasicInfo_block_item'>
            {occupations?.data?.data?.results.find((v) => v?.id === value?.occupation)?.name}
          </div>
        </div>
        <div className='BasicInfo_block_list1'>
          <div className='BasicInfo_block_item'>{t('Current Addr.')}</div>
          <div className='BasicInfo_block_item'>{value?.currentAddres}</div>

          <div className='BasicInfo_block_item'>{t('Area')}</div>
          <div className='BasicInfo_block_item'>{citys?.data?.data?.find((v) => v?.id === value?.city)?.name}</div>
        </div>
     
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsFillRecordFill style={{ fontSize: '12px' }} /> {t('Presonal Info')}
        </div>
        <div className='BasicInfo_block_list1'>
          <div className='BasicInfo_block_item'>{t('Company Name')}</div>
          <div className='BasicInfo_block_item'>{value?.companyName}</div>

          <div className='BasicInfo_block_item'>{t('Tel (Company)')}</div>
          <div className='BasicInfo_block_item'>{value?.companyTel}</div>
        </div>
        <div className='BasicInfo_block_list1'>
          <div className='BasicInfo_block_item'>{t('Company Addr')}</div>
          <div className='BasicInfo_block_item'>{value?.companyAddress}</div>

          <div className='BasicInfo_block_item'>{t('Fax No.')}</div>
          <div className='BasicInfo_block_item'>{value?.companyFax}</div>
        </div>
        <div className='BasicInfo_block_list'>
          <div className='BasicInfo_block_item'>{t('Income')}</div>
          <div className='BasicInfo_block_item'>{
            
            value?.income && <CustomNumberFormat displayType={'text'} value = {value?.income} />
            }</div>
          <div className='BasicInfo_block_item'>{t('Work period')}</div>
          <div className='BasicInfo_block_item'>{value?.workPeriod}</div>
          <div className='BasicInfo_block_item'>{t('Type of contract')}</div>
          <div className='BasicInfo_block_item'>{value?.typeOfContract}</div>
        </div>
        <div className='BasicInfo_block_list'>
          <div className='BasicInfo_block_item'>{t('Position')}</div>
          <div className='BasicInfo_block_item'>{value?.position}</div>
          <div className='BasicInfo_block_item'>{t('Sai Method')}</div>
          <div className='BasicInfo_block_item'>{value?.saiMethod}</div>
          <div className='BasicInfo_block_item'>{t('Health card')}</div>
          <div className='BasicInfo_block_item'>{value?.healthCard}</div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsFillRecordFill style={{ fontSize: '12px' }} /> {t('Presonal Info')}
        </div>
        <div className='BasicInfo_block_list'>
          <div className='BasicInfo_block_item'>{t('Assigned Date')}</div>
          <div className='BasicInfo_block_item'>{value?.assignedDate}</div>
          <div className='BasicInfo_block_item'>{t('Followed Date')}</div>
          <div className='BasicInfo_block_item'>{value?.followedDate}</div>
          <div className='BasicInfo_block_item'>{t('Agent ID')}</div>
          <div className='BasicInfo_block_item'>{value?.assigned}</div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
