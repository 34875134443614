import { useQuery } from '@tanstack/react-query';
import axiosClient from '../axiosClient';

export const GetSubCategory = (data) => {
  const url = '/sub-category/';
  return useQuery({
    queryKey: ['sub-category'],
    queryFn: () => axiosClient.get(url, data),
    select: (data) => {
      const newData = data?.data.results.map((item) => ({ value: item?.id, label: item?.name, parent: item?.parent }));
      return newData ? newData : [];
    },
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
};
