import { createAsyncThunk } from '@reduxjs/toolkit';

import DashboardApi from '../../api/dashboardApi';

export const getAllDashboardApi = createAsyncThunk('dashboard', async (payload, { rejectWithValue }) => {
  const controller = new AbortController();
  try {
    const response = await DashboardApi.getAllDashboards(payload);
    // localStorage.setItem('access_token', response.data.access);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
