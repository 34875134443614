import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import './admin.css';

import { resetNotify } from '../../features/notify/notify';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../header/layoutHeader';
import Narbar from '../narbar/narbar';
import Footer from '../footer/Footer';
import Infomation from '../infomation/infomation';
import PhoneConnect from '../phone/phone-connect';

function Admin() {
  const [menu, setMenu] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    //dispatch(getProfile());
    dispatch(resetNotify());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 991) {
        setMenu(false);
      } else {
        setMenu(true);
      }
    });
  }, []);

  return (
    <>
      <div>
        {' '}
        <PhoneConnect />
        <LayoutHeader />
        <Narbar />
        <Infomation />
        <div>
          {' '}
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Admin;
