import React, { useContext, useEffect, useState } from 'react';
import { ThemContext } from './ThemContext';

const Spinner = ({ heading }) => {
  const loading = true;
  return (
    <div
      id='upload-loading'
      className='loading'
      style={{
        display: `${loading === false ? 'none' : 'flex'}`,
        top: '50vh',
        backgroundColor: 'gray',
        zIndex: '20000',
        opacity: '0.5',
        position: 'fixed',
      }}
    >
      <div className='box-text-loading'>
        {heading && (
          <h3 className='title text-center' style={{ fontSize: 28 }}>
            {heading}
          </h3>
        )}
        <i style={{ marginTop: 50 }} className='spinning-loader'></i>
      </div>
    </div>
  );
};

export default Spinner;
