import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { initReactI18next } from 'react-i18next';
import './index.css';
import './styles/customstyle.css';
import './styles/custom.css';
import './scss/customstyle.scss';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Themprovider } from './components/common/ThemContext';

axios.interceptors.response.use((response) => {
  return response;
});

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['vi', 'en'],
    fallbackLng: 'vi',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/i18n/{{lng}}/translation.json',
    },
  });
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {

}
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {' '}
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
