import React, { useState, useEffect } from 'react';
import { GiCheckMark } from 'react-icons/gi';
import ButtonRefreshPlus from './ButtonRefreshPlus';
import { MdDesktopAccessDisabled } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import DashboardApi from '../../../../api/dashboardApi';
import { useQuery } from '@tanstack/react-query';
import Modal, { ModalContent } from '../../../../components/common/Modal';
import NewNotice from './NewNotice';

const BoxDashbroad2 = () => {
  const [modalNotice, setModalNotice] = useState(false);
  const [showTable, setShowTable] = useState([]);
  const { t } = useTranslation();

  const checkFormDashboard = [
    { id: 1, label: 'Notice' },
    { id: 2, label: 'Directions' },
  ];
  const titleTable = [{ title: 'Division' }, { title: 'Subject' }, { title: 'Posting Date' }];

  const { data: dataNotice, isLoading: loading } = useQuery({
    queryKey: ['dashboardNotice', modalNotice],
    queryFn: () => DashboardApi.getNotice(),
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });

  const handleFilterTable = (e, value) => {
    if (e.target.checked === true) {
      setShowTable([...showTable, value?.id]);
    } else {
      setShowTable(showTable?.filter((v) => v !== value?.id));
    }
  };

  return (
    <>
      <Modal active={modalNotice}>
        <ModalContent
          className={'pu-face'}
          heading={'New Notice'}
          onClose={() => {
            setModalNotice(false);
          }}
        >
          <NewNotice setModalNotice={() => setModalNotice()} />
        </ModalContent>
      </Modal>
      <div
        className='border-[#4F6896] p-[15px] box-content__dasboard border-t-[4px]'
        style={{ borderTop: 'solid 3px #4F6896' }}
      >
        <div className='flex justify-between items-center mb-[15px]'>
          <div className='check-box__forms flex items-center'>
            {/* {checkFormDashboard?.map((v, i) => (
              <div className='check-box__dashboard' key={i}>
                <input
                  type='checkbox'
                  class='form-check-input'
                  onChange={(e) => handleFilterTable(e, v)}
                  // checked={show.includes(v?.id)}
                />
                <span className='checkmark'>
                  <GiCheckMark />
                </span>
                <label className='form-check-label'>{v.label}</label>
              </div>
            ))} */}
          </div>
          <div className='refresh-plus'>
            <ButtonRefreshPlus color={'#899BC1'} handle={() => setModalNotice(true)} />
          </div>
        </div>
        <div className='dasboard-table'>
          <table className='table mb-0' style={{ backgroundColor: '#FFFFFF', borderRadius: '0px', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#EDEDED' }}>
                {titleTable?.map((v, i) => (
                  <th key={i} style={{ fontWeight: 400, padding: 3 }}>
                    {t(v.title)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataNotice?.data?.Notice?.map((v, i) => (
                <tr key={i}>
                  <td>{v?.division?.username}</td>
                  <td>{v?.title}</td>
                  <td>{v?.postingDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BoxDashbroad2;
