import React, { useEffect, useState } from 'react';
import { BsTelephoneFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import {
  Dialing,
  UpdateSecond,
  UpdateTime,
  selectStatusCall,
  startCall,
  updateStatus,
} from '../../../../features/call/callSlice';
import { phone } from '../../../../util/help';

import ConsultationApi from '../../../../api/Consultation';
import { useSelector } from 'react-redux';
import { selectConsultation } from '../../../../features/consultation/ConsultationSlice';
import { useQuery } from '@tanstack/react-query';
import CommonApi from './common';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import CheckCustormer from './checkCustormer';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectConsultation);
  const statusCall = useSelector(selectStatusCall);
  const [active,setActive] = useState(false)


  const handleCall = (phone) => {
    if(statusCall.status !=='Dialing'){
      if (phone) {
        if (statusCall.status === 'NotReady') {
          dispatch(Dialing());
        } else {
          dispatch(updateStatus());
          dispatch(UpdateTime(true));
        }
        dispatch(startCall(phone));
      }
    }
  
  };

  return (
    <div className='PersonalInfo_block'>
      {active &&  <CheckCustormer setActive={setActive}/>}
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Campaign')}</div>
        <div className='Item_right'>Campaign</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Cust Name')}</div>
        <div className='Item_right flex_between'>
          <div>{data?.fullname}</div>
          <div>
          <AiOutlineSearch  onClick={() => {
            setActive(true)
          }}/>
          </div>
        </div>
       
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Cust. ID No')}</div>
        <div className='Item_right'>{data?.idNo}</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Date of Birth')}</div>
        <div className='Item_right'>{data?.dateOfBirth}</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('tel (Mobile)')}</div>
        <div className='Item_right flex_between'>
          <div>{data?.mobile && phone(data?.mobile)}</div>
          <div>
            <BsTelephoneFill
              onClick={() => {
                handleCall(data?.mobile);
              }} 
              style={{color:statusCall.status === 'Dialing' && 'gray'}}
            />
          </div>
        </div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Tel (Phone)')}</div>
        
         <div className='Item_right flex_between'>
          <div>{phone(data?.phone)}</div>
          <div>
            <BsTelephoneFill
              onClick={() => {
                handleCall(data?.phone);
              }}
              style={{color:statusCall.status === 'Dialing' && 'gray'}}
            />
          </div>
        </div> </div>
     
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Tel (Company)')}</div>
     
        <div className='Item_right flex_between'>
          <div>{phone(data?.companyTel)}</div>
          <div>
            <BsTelephoneFill
              onClick={() => {
                handleCall(data?.phone);
              }}
              style={{color:statusCall.status === 'Dialing' && 'gray'}}
            />
          </div>
        </div>
           </div>
    
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Campaign')}</div>
        <div className='Item_right'>GROUP1_TEAM_E</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Campaign')}</div>
        <div className='Item_right'>GROUP1_TEAM_E</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Campaign')}</div>
        <div className='Item_right'>GROUP1_TEAM_E</div>
      </div>
      <div className='PersonalInfo_chid_item'>
        <div className='item_left'>{t('Campaign')}</div>
        <div className='Item_right'>GROUP1_TEAM_E</div>
      </div>
    </div>
  );
};

export default PersonalInfo;
