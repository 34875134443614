import React, { useEffect } from 'react';
import './infomation.css';
import { BsBookFill, BsBookmarkPlusFill, BsSearch, BsSendFill } from 'react-icons/bs';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from '../../api/dashboardApi';
import { useSelector } from 'react-redux';

const Infomation = () => {
  const refreshData = useSelector((state) => state.customer.refreshDataHeader);
  console.log('refreshData', refreshData);
  useEffect(() => {
    refetch();
  }, [refreshData]);
  const {
    data: dataReportHeader,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['reportHeader'],
    queryFn: () => DashboardApi.getAllDashboards(),
    keepPreviousData: false,
    staleTime: 60 * 1000,
  });

  // console.log('dashbroad', dataReportHeader);

  return (
    <div className='body_infomation'>
      <div>
        <BsBookFill />
      </div>
      <div className='infomation_chid_2'>
        <div>I/B</div>
        <div>
          Ans. Rate :{' '}
          {(dataReportHeader?.data?.rateAns && parseFloat(dataReportHeader?.data?.rateAns?.slice(0, -1)).toFixed(2)) ||
            0}
          %
        </div>
        <div>Ans. : {dataReportHeader?.data?.ans}</div>
        <div>Aban. : {dataReportHeader?.data?.aban}</div>
        <div>[O/B]</div>
        <div>
          Conn . Rate :{' '}
          {(dataReportHeader?.data?.rateFail &&
            parseFloat(dataReportHeader?.data?.rateFail?.slice(0, -1)).toFixed(2)) ||
            0}
          %
        </div>
        <div>Conn : {dataReportHeader?.data?.conn}</div>
        <div>Fail : {dataReportHeader?.data?.fail}</div>
      </div>
      <div className='infomation_chid_3'>
        <div className='Item_infomation'>
          <input type='text' className='ItemInput' placeholder='KeyWord' />
          <div className='itemChid_2'>
            <BsSearch style={{ fontSize: '13px', color: 'white' }} />
          </div>
        </div>
        <div className='Item_infomation' style={{ borderRight: '1px solid gray', height: '15px' }}>
          <BsSendFill style={{ transform: 'rotate(270deg)' }} />
          MSG
        </div>
        <div className='Item_infomation'>
          <BsBookmarkPlusFill /> MEMO
        </div>
      </div>
    </div>
  );
};

export default Infomation;
