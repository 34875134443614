import axiosClient from './axiosClient';

const DashboardApi = {
  getAllDashboards(params) {
    const url = `/dashboard/`;
    return axiosClient.get(url, { params });
  },
  getNotice(params) {
    const url = `/contact/notice/`;
    return axiosClient.get(url, { params });
  },
  getCalendar(params) {
    const url = `/contact/todo/`;
    return axiosClient.get(url, { params });
  },
  postCalendar(body) {
    const url = '/contact/todo/';
    return axiosClient.post(url, body);
  },
  postNotice(body) {
    const url = '/contact/notice/';
    return axiosClient.post(url, body);
  },
};
export default DashboardApi;
