import { useDispatch } from 'react-redux';
import { postKey } from '../features/org/orgSlice';

export const guideSteps = {
  addStaff: [
    {
      content: <h5>Thêm mới tài khoản nhân viên</h5>,
      locale: { skip: <strong>SKIP</strong> },
      placement: 'center',
      target: 'body',
      title: 'Bắt đầu',
    },
    {
      content: <h5>Đến Cấu hình</h5>,

      placement: 'bottom',
      target: '#configuration',
      title: 'Bước 1',
    },
    {
      content: <h5>Chọn cấu hình chung</h5>,

      placement: 'bottom',
      target: '#General_configuration',
      title: 'Bước 2',
    },
    {
      content: <h5>Chọn Nhân viên</h5>,

      placement: 'bottom',
      target: '#staff',
      title: 'Bước 3',
    },
    {
      content: <h5>Chọn Thêm nhân viên và điền thông tin</h5>,

      placement: 'bottom',
      target: '#addStaff',
      title: 'kết thúc',
    },
  ],
  addGroup: [
    {
      content: <h5>Thêm mới nhóm quyền</h5>,
      locale: { skip: <strong>SKIP</strong> },
      placement: 'center',
      target: 'body',
      title: 'Bắt đầu',
    },
    {
      content: <h5>Đến Cấu hình</h5>,

      placement: 'bottom',
      target: '#configuration',
      title: 'Bước 1',
    },
    {
      content: <h5>Chọn cấu hình chung</h5>,

      placement: 'bottom',
      target: '#General_configuration',
      title: 'Bước 2',
    },
    {
      content: <h5>Chọn nhóm quyền</h5>,

      placement: 'bottom',
      target: '#department',
      title: 'Bước 3',
    },
    {
      content: <h5>Chọn Thêm mới nhóm quyền : điền nội dung, bật tắt các quyền</h5>,

      placement: 'bottom',
      target: '#addGroup',
      title: 'kết thúc',
    },
  ],
  addExtension: [
    {
      content: <h5>Thêm mới nhóm quyền</h5>,
      locale: { skip: <strong>SKIP</strong> },
      placement: 'center',
      target: 'body',
      title: 'Bắt đầu',
    },
    {
      content: <h5>Đến Cấu hình</h5>,

      placement: 'bottom',
      target: '#configuration',
      title: 'Bước 1',
    },
    {
      content: <h5>Chọn cấu hình chung</h5>,

      placement: 'bottom',
      target: '#General_configuration',
      title: 'Bước 2',
    },
    {
      content: <h5>Chọn Máy lẻ</h5>,

      placement: 'bottom',
      target: '#extension',
      title: 'Bước 3',
    },
    {
      content: <h5>Chọn và gán máy lẻ tương ứng</h5>,

      placement: 'bottom',
      target: '#addExtension',
      title: 'kết thúc',
    },
  ],
};

export const dasboardSteps = [
  {
    content: <h5>Chào mừng bạn đến mới Mcall</h5>,
    locale: { skip: <strong>SKIP</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: <h5>Bật/ tắt kết nối với tổng đài</h5>,

    placement: 'bottom',
    target: '#SwitchBoard',
  },
  {
    content: <h5>Hiện màn hình quay số</h5>,

    placement: 'bottom',
    target: '#phonering-alo-phoneIcon',
  },
  {
    content: <h5>Chỉnh sửa thông tin cá nhân</h5>,

    placement: 'bottom',
    target: '#avatar',
  },
];

export const startSteps = [
  {
    content: <h5>Thêm mới nhóm cơ hội</h5>,
    locale: { skip: <strong>SKIP</strong> },
    // placement: 'bottom',
    disableBeacon: true,
    target: '#addGroup',
  },
  {
    content: <h5>Thêm mới khách hàng trong nhóm</h5>,

    placement: 'bottom',
    target: '#HiOutlinePlusCircle',
  },
];

export const customerSteps = [
  {
    content: <h5> Thêm mới khách hàng</h5>,
    locale: { skip: <strong>SKIP</strong> },
    // placement: 'bottom',
    disableBeacon: true,
    target: '#add-custorm',
  },
  {
    content: <h5>Xuất file excel dữ liệu toàn bộ khách hàng</h5>,

    placement: 'bottom',
    target: '#export-excel',
  },
  {
    content: <h5>Các hành động : xóa khách hàng, chuyển quyền quản lý, upload dữ liệu qua excel,....</h5>,

    placement: 'bottom',
    target: '#selectCustormer',
  },
  {
    content: <h5>sử dụng bộ lọc để tìm kiếm nhanh khách hàng</h5>,

    placement: 'bottom',
    target: '#filtercustomer',
  },
  {
    content: <h5>Tùy chỉnh hiển thị các mục</h5>,

    placement: 'bottom',
    target: '#settingCustormer',
  },
];

export const ordersSteps = [
  {
    content: <h5> Thêm mới khách hàng</h5>,
    locale: { skip: <strong>SKIP</strong> },
    // placement: 'bottom',
    disableBeacon: true,
    target: '#add-order',
  },
  {
    content: <h5>sử dụng bộ lọc để tìm kiếm nhanh khách hàng</h5>,

    placement: 'bottom',
    target: '#filterOrder',
  },
  {
    content: <h5>Tùy chỉnh hiển thị các mục</h5>,

    placement: 'bottom',
    target: '#settingOrder',
  },
];

export const invoiceSteps = [
  {
    content: <h5> Thêm mới khách hàng</h5>,
    locale: { skip: <strong>SKIP</strong> },
    // placement: 'bottom',
    disableBeacon: true,
    target: '#add-invoice',
  },
  {
    content: <h5>sử dụng bộ lọc để tìm kiếm nhanh khách hàng</h5>,

    placement: 'bottom',
    target: '#filterinvoice',
  },
  {
    content: <h5>Tùy chỉnh hiển thị các mục</h5>,

    placement: 'bottom',
    target: '#settinginvoice',
  },
];

export const productsteps = [
  {
    content: <h5> Thêm mới từng sản phẩm</h5>,
    locale: { skip: <strong>SKIP</strong> },
    // placement: 'bottom',
    disableBeacon: true,
    target: '#PSt1',
  },
  {
    content: <h5>Thêm cùng lúc nhiều sản phẩm bằng file excel</h5>,

    placement: 'bottom',
    target: '#PSt2',
  },
  {
    content: <h5> thêm mô tả, phân loại chi tiết cho sản phẩm</h5>,

    placement: 'bottom',
    target: '#PSt3',
  },
  {
    content: <h5>Tìm kiếm sản phẩm nhanh bằng bộ lọc</h5>,

    placement: 'bottom',
    target: '#PSt4',
  },
  {
    content: <h5>Tùy chỉnh hiển thị các mục</h5>,

    placement: 'bottom',
    target: '#PSt5',
  },
];

export const campaignSteps = {
  sms: [
    {
      content: <h5> Thêm danh sách khách hàng nhận SMS trong chiến dịch</h5>,
      locale: { skip: <strong>SKIP</strong> },
      // placement: 'bottom',
      disableBeacon: true,
      target: '#sms-Steps-1',
    },
    {
      content: <h5>Tạo mới và kích hoạt chiến dịch</h5>,

      placement: 'bottom',
      target: '#sms-Steps-2',
    },
    {
      content: <h5> Xem lại toàn bộ chiến dịch đã tạo</h5>,

      placement: 'bottom',
      target: '#sms-Steps-3',
    },
  ],
  email: [
    {
      content: <h5>Thêm danh sách khách hàng nhận email trong chiến dịch</h5>,
      locale: { skip: <strong>SKIP</strong> },
      // placement: 'bottom',
      disableBeacon: true,
      target: '#email-Steps-1',
    },
    {
      content: <h5>Tạo mới và kích hoạt chiến dịch</h5>,

      placement: 'bottom',
      target: '#email-Steps-2',
    },
  ],
  zalo: [
    {
      content: <h5> Chọn danh sách mẫu ZNS đã được khai báo và phê duyệt</h5>,
      locale: { skip: <strong>SKIP</strong> },
      // placement: 'bottom',
      disableBeacon: true,
      target: '#zalo-Steps-1',
    },
    {
      content: <h5>Thêm danh sách và mẫu Zalo ZNS gửi khách hàng</h5>,

      placement: 'bottom',
      target: '#zalo-Steps-2',
    },
    {
      content: <h5> Tạo và kích hoạt chiến dịch gửi Zalo ZNS</h5>,

      placement: 'bottom',
      target: '#zalo-Steps-3',
    },
  ],
};

export const guideFunction = (data, dispatch, navigator) => {
  if (data?.step?.target === '#configuration') {
    navigator('/admin/configuration');
  }
  if (data?.step?.target === '#General_configuration') {
    navigator('/admin/configuration/all');
  }
  if (data?.step?.target === '#staff') {
    dispatch(postKey({ key: 'staff' }));
  }
  if (data?.step?.target === '#department') {
    dispatch(postKey({ key: 'department' }));
  }

  if (data?.step?.target === '#extension') {
    dispatch(postKey({ key: 'extension' }));
  }

  if (data?.action === 'reset') {
   
  }
  if (data?.step?.title === 'kết thúc' && data.action === 'next' && data.lifecycle === 'init') {

  }
  //   if (data?.action === 'stop') {

  //     dispatch(endGuide());
  //   }
};
//&& data?.action === 'update'
