import React, { useEffect, useState, useRef } from 'react';
import { BiChevronDown, BiChevronUp, BiX } from 'react-icons/bi';
import { useOnOutsideClick } from '../../hooks/use-outside';
const CustomSelect = (props) => {

  const { keys, name, setSelectValue, setKeySearch } = props;
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [optionsData, setOptionsData] = useState([]);

  useEffect(() => {
    setSelectedOption(props.options?.find((v) => v.value === props?.defaultValue) || '');
    setOptionsData(props.options);
  }, [props.options]);
  const [selectedOption, setSelectedOption] = useState('');
  const [valueInput, setValueInput] = useState('All');

  const { innerBorderRef } = useOnOutsideClick(() => setIsOpen(false));

  const handleSelected = (value) => {
    setValueInput(value.label);
    setSelectedOption(value);
    setIsOpen(false);
    props.handleChange(props?.name, value?.value);
  };

  useEffect(() => {
    if (props.defaultValue && selectedOption) {
      props.handleChange(props?.name, selectedOption?.value);
    }
  }, [props.defaultValue]);
  const handleInputClick = (e) => {
    e.stopPropagation();
  };
  const handleSearch = (e) => {
    let valueSearch = e.target.value;
    setValueInput(valueSearch);
    if (inputRef.current) {
      clearTimeout(inputRef.current);
    }
    inputRef.current = setTimeout(() => {
      setKeySearch(valueSearch);
    }, 200);
  };
  useEffect(() => {
    if (props?.filter === 'object' && Object?.keys(props?.filter)?.length) {
      setSelectedOption(props?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.filter]);

  if (props?.isGroup === true) {
    return (
      <div className='form-input-group'>
        {props.label ? <p>{props?.label}</p> : null}
        <div
          className={`custom-select-drp ${props.className} ${isOpen ? 'open' : ''} `}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className='cus-icon-select'>{!isOpen ? <BiChevronDown /> : <BiChevronUp />}</span>
          <span className='select-box'>{selectedOption?.label || props.label}</span>

          <ul className='drp-list'>
            {props.options.map((option, index) => {
              return (
                <li className='active' key={index} onClick={() => handleSelected(option)}>
                  {option.label}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`custom-select-drp ${props.className} ${isOpen ? 'open' : ''} ${
          keys && selectedOption ? 'custom' : 'uncustom'
        } `}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption && <span className='cus-icon-select'>{!isOpen ? <BiChevronDown /> : <BiChevronUp />}</span>}
        {!selectedOption && <span className='cus-icon-select'>{!isOpen ? <BiChevronDown /> : <BiChevronUp />}</span>}
        {/* {selectedOption && (
          <span
            onClick={() => {
              setSelectedOption(null);
              setIsOpen(false);
              setSelectValue((prev) => ({ ...prev, [name]: '' }));
            }}
            className='cus-icon-select'
          >
            {<BiX />}
          </span>
        )} */}
        {!selectedOption && keys && (
          <span className='select-box select-box-2'>
            <input
              placeholder={selectedOption?.label || props.label}
              type='text'
              value={valueInput}
              onChange={handleSearch}
            />
          </span>
        )}
        {selectedOption && keys && (
          <span onChange={handleSearch} className='select-box select-box-2'>
            <input placeholder={keys} type='text' value={valueInput} onChange={handleSearch} />
          </span>
        )}
        {!selectedOption && !keys && <span className='select-box'>{selectedOption?.label || props.label}</span>}
        {selectedOption && !keys && (
          <span className='select-box-1'>
            <div>{selectedOption?.label || props.label}</div>
          </span>
        )}
        {/* <div>{isOpen && keys && <input type='text' placeholder='search' />}</div> */}

        <ul className='drp-list' ref={innerBorderRef}>
          {/* {keys && (
            <li onChange={handleSearch} onClick={handleInputClick}>
              <input type='text' placeholder='search' />
            </li>
          )} */}
          {optionsData?.map((option, index) => {
            return (
              <>
                <li className='active' key={index} onClick={() => handleSelected(option)}>
                  {option.label}
                </li>
              </>
            );
          })}
        </ul>
      </div>
    );
  }
};

export default CustomSelect;
