import React from 'react';
import './layout.css';
import { BsSendFill, BsTelephoneMinusFill, BsTelephoneXFill } from 'react-icons/bs';
import PhoneConnect from '../phone/phone-connect';
import { useDispatch } from 'react-redux';
import { EndCall, Hold, UpdateTime, selectStatusCall } from '../../features/call/callSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { el } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const LayoutHeader = () => {
  const { t } = useTranslation();
  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState(false);
  const statusCall = useSelector(selectStatusCall);
  const [time, setTime] = useState(0);
  const [minute, setMinute] = useState(0);
  useEffect(() => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // Truy cập microphone thành công
          setHasMicrophoneAccess(true);
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((error) => {
          // Lỗi khi truy cập microphone
          setHasMicrophoneAccess(false);
        });
    } else {
      // Trình duyệt không hỗ trợ MediaDevices API hoặc getUserMedia
      setHasMicrophoneAccess(false);
    }
  }, []);
  useEffect(() => {
    if (statusCall.time) {
      const interval = setInterval(() => {
        if (time === 60) {
          setMinute(minute + 1);
          setTime(0);
        } else {
          setTime(time + 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setTime(0);
      setMinute(0);
      if (statusCall.status === 'After Work' || statusCall.status === 'NotReady' || statusCall.status === 'Dialing') {
        dispatch(UpdateTime(true));
      }
    }
  }, [statusCall.time, time]);

  const dispatch = useDispatch();
  return (
    <div className='blockLayout'>
      <div className='blockStart'>
        <div className='blockChid'>
          <div className='statusCall text-[#333366]'>{t('Call Status')}</div>
          <div className='blockStatus'>000/00:00:00</div>
        </div>
        <div className='blockChid'>
          <div className='CttStatus text-[#333366]'>{t('CTT status')}</div>
          <div className='blockStt' style={{ paddingLeft: '5px', display: 'flex', alignItems: 'center' }}>
            {hasMicrophoneAccess ? (
              <div>
                {statusCall?.status} / {minute < 10 ? `0${minute}` : minute || '00'}:
                {time < 10 ? `0${time}` : time || '00'}
              </div>
            ) : (
              <div>{t('LOGOUT')}</div>
            )}
          </div>
        </div>
      </div>
      <div className='Title'>
        <span style={{ color: '#F4A460' }}>MAFC</span> <span style={{ color: '0099ff' }}>CENTER</span>
      </div>
      <div className='block_end'>
        <div className='block_end_chid'>
          <div className='icon border_rught'>
            <BsSendFill style={{ transform: 'rotate(270deg)' }} />
          </div>
          <div className='font'>{t('Break')}</div>
        </div>
        <div className='block_end_chid'>
          <div className='icon border_rught'>
            <BsTelephoneMinusFill
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(Hold());
              }}
            />
          </div>
          <div className='font'>{t('Hold')}</div>
        </div>
        <div className='block_end_chid'>
          <div className='icon'>
            <BsTelephoneXFill
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(EndCall());
              }}
            />
          </div>
          <div className='font'>{t('Release')}</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutHeader;
