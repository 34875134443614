import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../../api/axiosClient';

const initialState = {
  list: {},
  detail: null,
};

const ACTION = {
  GET_NOTIFY: 'notify/getNotify',

  GET_NOTIFY_ID: 'notify/getNotifyId',
  DEL_NOTIFY: 'notify/delNotify',

  UPDATE_NOTIFY: 'notify/updateNotify',
};

export const getNotify = createAsyncThunk(ACTION.GET_NOTIFY, async (body) => {
  return axiosClient.get('/notifycations/', {
    params: body,
  });
});

export const getNotifyId = createAsyncThunk(ACTION.GET_NOTIFY_ID, async (body) => {
  return axiosClient.get('/notifycations/', {
    params: body,
  });
});

export const updateNotify = createAsyncThunk(ACTION.UPDATE_NOTIFY, async (body) => {
  return axiosClient.put(`/notifycations/${body.id}/`, body);
});

export const delNotify = createAsyncThunk(ACTION.DEL_NOTIFY, async (body) => {
  return axiosClient.delete('/notifycations/', {
    params: body,
  });
});

const notifySlice = createSlice({
  name: 'notify',
  initialState: initialState,
  reducers: {
    getDetail: (state, action) => {
      state.detail = action.payload;
    },
    getMessage: (state, action) => {
      state.message = action.payload;
    },
    getMessageZalo: (state, action) => {
      state.messageZalo = action.payload;
    },
    getMessageStarLead: (state, action) => {
      state.messageStarLead = action.payload;
    },
    resetNotify: (state) => {
      state.detail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotify.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotify.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getNotify.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.isSuccess = false;
      })
      .addCase(getNotifyId.pending, (state) => {})
      .addCase(getNotifyId.rejected, (state) => {})
      .addCase(getNotifyId.fulfilled, (state, action) => {
        state.detail = action.payload.data;
      })
      .addCase(delNotify.pending, (state) => {})
      .addCase(delNotify.rejected, (state) => {
        state.isSuccess = false;
      })
      .addCase(delNotify.fulfilled, (state, action) => {
        state.isSuccess = true;
      })
      .addCase(updateNotify.pending, (state) => {})
      .addCase(updateNotify.rejected, (state) => {
        state.isSuccess = false;
      })
      .addCase(updateNotify.fulfilled, (state, action) => {
        state.isSuccess = true;
      });
  },
});

// Reducer
export const { getDetail, resetNotify, getMessage, getMessageZalo, getMessageStarLead } = notifySlice.actions;
const { reducer: notifyReducer } = notifySlice;
export default notifyReducer;
