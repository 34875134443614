import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

const ProtectedAuth = (props) => {
  const location = useLocation();

  const { Component } = props;
  const token = localStorage.getItem('access_token');

  return !token ? <Component /> : <Navigate to={'/'} replace state={{ from: location }} />;
};

export default ProtectedAuth;
