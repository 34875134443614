import { createContext, useState } from 'react';

const ThemContext = createContext();

function Themprovider({ children }) {
  const [loadding, setLoadding] = useState(false);
  const data = {
    loadding,
    setLoadding,
  };
  return <ThemContext.Provider value={data}>{children}</ThemContext.Provider>;
}

export { ThemContext, Themprovider };
