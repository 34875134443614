export const listNarbar = [
  {
    name: 'Dashboard',
    path: 'dashboard',
  },
  {
    name: 'Customer',
    path: 'customer',
  },
  {
    name: 'Consultation',
    path: 'consultation',
  },
  {
    name: 'Consultation Assist',
    path: 'consultationAssist',
  },
  {
    name: 'Reports',
    path: 'reports',
  },
  {
    name: 'Upload Files',
    path: 'uploadFile',
  },
];
