import axios from 'axios';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/`,
  timeout: 10000,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptorproduct_code

axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('access_token');
    if (
      config.url.includes('upload-xls-customer') ||
      config.url.includes('as-data') ||
      config.url.includes('leads/export-csv-customer')
    ) {
      config.timeout = 10000000;
    }
    if (config.url.includes('/products/') && config.method === 'post') {
      config.headers = { 'Content-Type': 'multipart/form-data' };
    }

    config.headers.Authorization = token ? 'JWT ' + localStorage.getItem('access_token') : '';
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// for multiple requests
let isRefreshing = false;
let subscribers = [];

function onRefreshed({ authorisationToken }) {
  subscribers.map((cb) => cb(authorisationToken));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

axiosClient.interceptors.response.use(
  (response) => {
    // response.dispatch(showMessageSuccess('Sửa đơn hàng thành công'));
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    // if (error.response.status === 401 && error.response.data.detail === 'Token is invalid or expired') {
    //   localStorage.clear();
    //   window.location.href = '/login';
    //   return Promise.reject(error);
    // }
    if (
      (error.response.status === 401 && ['Authentication credentials were not provided.', 'User is inactive', 'User not found'].includes(error.response.detail))
    ) {
      localStorage.clear();
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `JWT ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
      // exp date in token is expressed in seconds, while now() returns milliseconds:
      const now = Math.ceil(Date.now() / 1000);
      if (tokenParts.exp > now) {
        return new Promise(function (resolve, reject) {
          axiosClient
            .post('/account/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.access);
              localStorage.setItem('refresh_token', response.data.refresh);
              axiosClient.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

              onRefreshed(response.data.access);
              resolve(axiosClient(originalRequest));
            })
            .catch((err) => {
              subscribers = [];

              localStorage.clear();
              window.location.href = '/login';
              reject(err);
              //return Promise.reject(error);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      } else {
        localStorage.clear();
        window.location.href = '/login';
      }
    }

    // specific error handling done elsewherenp
    localStorage.setItem('loadding', false);
    return Promise.reject(error);
  }
);

export default axiosClient;
