import React, { useState } from 'react';
import { BsFillCaretLeftFill, BsFillCaretRightFill, BsFillFastForwardFill, BsFillRewindFill } from 'react-icons/bs';
import './style.scss';
const Pagnigation = ({ currentPage, totalPages, onPageChange, total }) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  return (
    <div className='flex items-center pagination-customer'>
      <div className='cursor-pointer' onClick={() => onPageChange(1)}>
        <BsFillRewindFill />
      </div>
      <div className='cursor-pointer' onClick={() => onPageChange(currentPage - 1)}>
        <BsFillCaretLeftFill />{' '}
      </div>
      {pages.map((page) => (
        <div
          key={page}
          className={`item cursor-pointer${currentPage === page ? ' active' : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </div>
      ))}
      <div className='cursor-pointer' onClick={() => onPageChange(currentPage + 1)}>
        <BsFillCaretRightFill />
      </div>
      <div className='cursor-pointer' onClick={() => onPageChange(totalPages)}>
        <BsFillFastForwardFill />
      </div>
      <div style={{ marginLeft: '5px' }}>{`( Total : ${total ? total : 0} )`}</div>
    </div>
  );
};

export default Pagnigation;
