import React, { useEffect, useState, useRef } from 'react';
import './FindDataCustomer.scss';
import { HiStar, HiSearch } from 'react-icons/hi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FcCalendar } from 'react-icons/fc';
import CustomSelect from '../../../components/common/CustomSelect';
import { HiChevronRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import customerApi from '../../../api/customer';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Pagnigation from './components/Pagnigation';
import CommonApi from '../consultation/component/common';
import ConsultationApi from '../../../api/Consultation';
import { useNavigate } from 'react-router-dom';
import { format, subDays, addDays, addHours } from 'date-fns';
import { GetSubCategory } from '../../../api/commonApi/commonApi';
import Select from 'react-select';
import Spinner from '../../../components/common/Spinner';
import CustomNumberFormat from '../../../components/common/CustomNumberFormat';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

const filterObject = (obj) => {
  const filteredData = {};
  for (const key in obj) {
    if (obj[key] !== '') {
      filteredData[key] = obj[key];
    }
  }
  return filteredData;
};
function FindDataCustomer(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const currentTime = new Date();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState({
    from: subDays(currentTime, 7),
    to: addDays(currentTime, 1),
    fromDateCall: '',
    toDateCall: '',
  });
  const [selectRadio, setSelectRadio] = useState('week');
  const [selectValue, setSelectValue] = useState({
    campaign: '',
    city: '',
    telesaleStatus: '',
    placeOfAppl: '',
    processOfType: '',
    call_result: '',
    main: '',
    sub: '',
    phoneNo: '',
  });
  const [inputValue, setInputValue] = useState({
    idNo: '',
    name: '',
    idAgree: '',
    phone: '',
  });
  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);
  const dateFromCallRef = useRef(null);
  const dateToCallRef = useRef(null);

  const configTimeRef = {
    from: dateFromRef,
    to: dateToRef,
    fromDateCall: dateFromCallRef,
    toDateCall: dateToCallRef,
  };
  const arrList = useRef();

  const [check, setCheck] = useState(false);
  const [activeTest, setActiveTest] = useState(false);
  const [activeCheck, setActiveCheck] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [resize, setResize] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [keySearch, setKeySearch] = useState('');

  const handleCol = (x, type) => {
    if (type) {
      return isMobile ? 12 : isTablet ? 12 : x;
    } else {
      return isMobile ? 12 : isTablet ? 6 : x;
    }
  };
  useEffect(() => {
    if (isMobile) {
      setResize(true);
    } else {
      setResize(false);
    }
  }, [isMobile]);
  useEffect(() => {
    refetch();
    function handleResize() {
      setIsMobile(window.innerWidth < 480);
      setIsTablet(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDateChange = (date, key) => {
    setSelectRadio(null);
    setSelectedDate({ ...selectedDate, [key]: date });
  };
  const handleChange = (key, value) => {
    setSelectValue({ ...selectValue, [key]: value });
  };

  const renderDate = () => {
    if (selectedDate.from && selectedDate.to) {
      return {
        from: format(new Date(selectedDate.from), 'yyyy-MM-dd'),
        to: format(new Date(selectedDate.to), 'yyyy-MM-dd'),
      };
    } else {
      return {};
    }
  };
  const itemsPerPage = 10;

  const {
    data: dataCustomer,
    isLoading: loading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['customer', currentPage],
    queryFn: () =>
      customerApi.getAll({
        ...filterObject(selectValue),
        ...filterObject(inputValue),
        ...renderDate(),
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
      }),
    keepPreviousData: false,
    staleTime: 60 * 1000,
  });
  const totalPages = dataCustomer ? Math.ceil(dataCustomer?.data?.count / 10) : 0;
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      // setCheck(false);
    }
  };
  useEffect(() => {
    arrList.current = [];
  }, []);
  const handleChangeCheckbox = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheck(true);
      const allItemIds = dataCustomer?.data?.results?.map((item) => item.id) || [];
      const arr = arrList.current;
      arr.push(...allItemIds);
      arrList.current = arr;
      setActiveTest(!activeTest);
    } else {
      const arr = arrList.current.filter((v) => !dataCustomer?.data?.results?.find((k) => k.id === v));
      setCheck(false);
      arrList.current = arr;
      setActiveTest(!activeTest);
    }
  };
  const handleRadioChange = (event) => {
    setSelectRadio(event.target.value);
    const currentDate = new Date();
    switch (event.target.value) {
      case 'yesterday':
        return setSelectedDate({
          ...selectedDate,
          from: subDays(currentDate, 1),
          to: currentDate,
        });
      case 'day':
        return setSelectedDate({
          ...selectedDate,
          from: currentDate,
          to: addDays(currentDate, 1),
        });
      case 'week':
        return setSelectedDate({
          ...selectedDate,
          from: subDays(currentDate, 7),
          to: addDays(currentDate, 1),
        });
      case 'month':
        return setSelectedDate({
          ...selectedDate,
          from: subDays(currentDate, 30),
          to: currentDate,
        });

      default:
        return null;
    }
  };
  const handleChangeCheckItem = (e, id) => {
    if (e.target.checked) {
      const arr = arrList.current;
      arr.push(id);
      arrList.current = arr;
      setActiveTest(!activeTest);
    } else {
      const arr = arrList.current;
      arr?.splice(arrList.current.indexOf(id), 1);
      arrList.current = arr;
      setCheck(false);
      setActiveTest(!activeTest);
    }
  };
  useEffect(() => {
    if (
      dataCustomer?.data?.results?.filter((v) => arrList.current?.includes(v?.id)).length >
      dataCustomer?.data?.results?.length - 1
    ) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [activeTest, currentPage]);
  // ============================
  const { data: dataCities, isLoading: loaddingDataCities } = useQuery({
    queryKey: ['cities', keySearch],
    queryFn: () => CommonApi.cities({ q: keySearch }),
    select: (data) => {
      const newData = data.data.map((item) => ({ value: item.id, label: item.name }));
      // newData.push({ value: '', label: 'All' });
      return newData ? [{ value: '', label: 'All' }, ...newData] : [];
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });
  const { data: dataSubCategory } = GetSubCategory();

  const { data: dataCallRst, isLoading: loaddingDataCallRst } = useQuery({
    queryKey: ['CallRst'],
    queryFn: () => CommonApi.callResult(),
    select: (data) => {
      const newData = data.data.results.map((item) => ({ value: item.id, label: item.name }));
      return newData ? [{ value: '', label: 'All' }, ...newData] : [];
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });
  const { data: dataCampaign, isLoading: loaddingDataCampaign } = useQuery({
    queryKey: ['Campaign'],
    queryFn: () => ConsultationApi.getDetailCustomer(),
    select: (data) => {
      const newData = data.data?.contacts.map((item, index) => {
        return {
          value: item?.id,
          label: item?.fullname,
        };
      });
      return newData ? [{ value: '', label: 'All' }, ...newData] : [];
    },
    keepPreviousData: false,
    staleTime: 60 * 1000,
  });
  const { data: dataMainCatg, isLoading: loaddingDataMainCatg } = useQuery({
    queryKey: ['MainCatg'],
    queryFn: () => CommonApi.mainCategory(),
    select: (data) => {
      const newData = data.data.results.map((item) => ({ value: item.id, label: item.name }));
      return newData ? [{ value: '', label: 'All' }, ...newData] : [];
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });
  const { data: dataOccupations, isLoading: loaddingOccupations } = useQuery({
    queryKey: ['occupations'],
    queryFn: () => CommonApi.Occupation(),
    select: (data) => {
      const newData = data.data.results.map((item) => ({ value: item.id, label: item.name }));
      return newData ? [...newData] : [];
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });

  const mutation = useMutation({
    mutationFn: (data) => {
      return customerApi.campaign(data);
    },
    onSuccess: (data, variables, context) => {
      // alert('Cập nhật thành công');
      navigate('/admin/consultation');
    },
    onError: (data, variables, context) => {
      alert('Cập nhật thất bại');
    },
  });

  const handleOption = (key) => {
    switch (key) {
      case 'city':
        return dataCities ? dataCities : [];
      case 'call_result':
        return dataCallRst ? dataCallRst : [];
      case 'main':
        return dataMainCatg ? dataMainCatg : [];
      case 'campaign':
        return dataCampaign ? dataCampaign : [];
      case 'processOfType':
        return [{ value: '', label: 'All' }];
      case 'placeOfAppl':
        return [{ value: '', label: 'All' }];
      case 'telesaleStatus':
        return [{ value: '', label: 'All' }];
      case 'sub':
        return selectValue.main ? (dataSubCategory ? dataSubCategory : []) : [{ value: '', label: 'All' }];

      default:
        return [];
    }
  };

  const datafilter = [
    {
      title: 'Date',
      list: [
        { title: 'from', type: 'date' },
        { title: 'to', type: 'date' },
      ],
      col: handleCol(4),
    },
    {
      title: '',
      list: [
        { title: 'Yesterday', type: 'radio', value: 'yesterday' },
        { title: 'Day', type: 'radio', value: 'day' },
        { title: 'Week', type: 'radio', value: 'week' },
        { title: 'Month', type: 'radio', value: 'month' },
      ],
      col: handleCol(4),
    },
    { title: 'Campaign', type: 'select', col: handleCol(4), key: 'campaign' },
    { title: 'Area', type: 'select', col: handleCol(3), key: 'city' },
    { title: 'TeleSale Status', type: 'select', col: handleCol(3), key: 'telesaleStatus' },
    { title: 'Place of appl', type: 'select', col: handleCol(3), key: 'placeOfAppl' },
    { title: 'Process of Type', type: 'select', col: handleCol(3), key: 'processOfType' },
    { title: 'Call Rslt', type: 'select', col: handleCol(3), key: 'call_result' },
    { title: 'Main Category', type: 'select', col: handleCol(3), key: 'main' },
    { title: 'Sub Category', type: 'select', col: handleCol(3), key: 'sub' },
    { title: 'Phone No', type: 'input', col: handleCol(3), key: 'phone' },
    { title: 'Cust. ID No', type: 'input', col: handleCol(3), key: 'idNo' },
    { title: 'Cust Name', type: 'input', col: handleCol(3), key: 'name' },
    {
      title: 'Last Call Time',
      list: [
        { title: 'fromDateCall', type: 'date' },
        { title: 'toDateCall', type: 'date' },
      ],
      col: handleCol(3),
    },
    { title: 'Agree ID', type: 'input', col: handleCol(3), key: 'idAgree' },
    {
      title: 'Organization',
      keys: 'organize',
      list: [
        { title: '1', type: 'select' },
        { title: '2', type: 'select' },
        { title: '3', type: 'select' },
        { title: '4', type: 'select' },
      ],
      col: handleCol(9, true),
    },
    { title: '', text: 'Inquiry', type: 'button', col: handleCol(3) },
  ];

  const DateTimerCustom = ({ keys, handleDateChange }) => {
    return (
      <div className='datepicker-wrapper '>
        <FcCalendar
          onClick={() => {
            configTimeRef[keys].current.setFocus();
          }}
          className='datepicker-icon'
        />
        <DatePicker
          placeholderText='Choose Date'
          selected={selectedDate[keys]}
          onChange={(e) => {
            handleDateChange(e, keys);
          }}
          ref={configTimeRef[keys]}
        />
      </div>
    );
  };
  const handleConsulting = () => {
    if (arrList.current?.length > 0) {
      mutation.mutate({ contacts: arrList.current });
    } else {
      alert('ban can chon khach hang');
      return;
    }
  };
  const handSearch = (second) => {
    // alert('hello');
    // filterObject(selectedDate);
    refetch();
  };
  let colCount = 0; // Biến đếm tổng giá trị col
  let rows = []; // Mảng chứa các phần tử row
  let currentRow = []; // Mảng chứa các phần tử col trong mỗi row
  datafilter.forEach((item, index) => {
    if (colCount + item.col <= 12) {
      // Nếu tổng giá trị col nhỏ hơn hoặc bằng 12, thêm vào currentRow
      currentRow.push(
        <div
          style={{ flexDirection: resize && item.keys === 'organize' ? 'column' : 'unset' }}
          className={`col col-${item.col} ${item.type === 'button' && 'search'}`}
          key={index}
        >
          <div style={{ fontSize: '12px' }} className='item1'>
            {t(item?.title)}
          </div>
          {item.type === 'input' && (
            <div className='item'>
              <input
                name={item.key}
                onChange={(e) => setInputValue({ ...inputValue, [e.target.name]: e.target.value })}
                value={inputValue[item.key]}
                className='style-input'
                type={item.type}
                placeholder={item?.title}
              />
            </div>
          )}
          {item.type === 'select' && (
            <div className='item'>
              <CustomSelect
                className='custom-select'
                value={selectValue[item.key]}
                handleChange={handleChange}
                label={item.title}
                name={item.key}
                options={handleOption(item.key)}
                setSelectValue={setSelectValue}
                keys={item.key === 'city' ? 'searchCity' : null}
                setKeySearch={setKeySearch}
                defaultValue={''}
              />
              {/* <Select
                className='custom-select'
                value={selectValue[item.key]}
                handleChange={handleChange}
                label={item.title}
                name={item.key}
                options={handleOption(item.key)}
              /> */}
            </div>
          )}
          {item.type === 'button' && (
            <div onClick={handSearch} className='flex items-center search-btn justify-end' style={{ gap: '4px' }}>
              <HiSearch />
              {item.text}
            </div>
          )}
          {item?.list
            ? item?.list.map((el) => {
                if (el.type === 'input') {
                  return <input className='item' type={el.type} placeholder={item?.title} />;
                } else if (el.type === 'date') {
                  return <DateTimerCustom keys={el.title} handleDateChange={handleDateChange} />;
                } else if (el.type === 'radio') {
                  return (
                    <>
                      <label style={{ fontSize: '12px' }} htmlFor='time'>
                        {t(el.title)}
                      </label>
                      <input
                        value={el.value}
                        checked={selectRadio === el.value}
                        onChange={handleRadioChange}
                        className='item'
                        name='time'
                        type={el.type}
                      />
                    </>
                  );
                } else {
                  return (
                    <div className='item'>
                      <CustomSelect
                        className='custom-select'
                        value={selectValue[item.key]}
                        handleChange={handleChange}
                        label={item.title}
                        name={item.key}
                        options={handleOption(item.key)}
                        defaultValue={''}
                      />
                    </div>
                  );
                }
              })
            : ''}
        </div>
      );
      colCount += item.col;
    } else {
      // Nếu tổng giá trị col vượt quá 12, thêm currentRow vào rows
      rows.push(
        <div className='row' key={rows.length}>
          {currentRow}
        </div>
      );

      // Tạo currentRow mới và thêm phần tử vào
      currentRow = [
        <div
          style={{ flexDirection: resize && item.keys === 'organize' ? 'column' : 'unset' }}
          className={`col col-${item.col}`}
          key={index}
        >
          <div style={{ fontSize: '12px' }} className='item1'>
            {' '}
            {t(item?.title)}
          </div>
          {item.type && (
            <div className='item'>
              {item.type === 'input' && (
                <input
                  name={item.key}
                  onChange={(e) => setInputValue({ ...inputValue, [e.target.name]: e.target.value })}
                  value={inputValue[item.key]}
                  className='style-input'
                  type={item.type}
                  placeholder={item?.title}
                />
              )}
              {item.type === 'select' && (
                <CustomSelect
                  className='custom-select'
                  value={selectValue[item.key]}
                  handleChange={handleChange}
                  label={item.title}
                  name={item.key}
                  options={handleOption(item.key)}
                  setSelectValue={setSelectValue}
                  keys={item.key === 'city' ? 'City' : null}
                  setKeySearch={setKeySearch}
                  defaultValue={''}
                />
              )}
            </div>
          )}
          {item?.list
            ? item?.list.map((el) => {
                if (el.type === 'input') {
                  return <input className='item' type={el.type} />;
                } else if (el.type === 'date') {
                  return <DateTimerCustom keys={el.title} handleDateChange={handleDateChange} />;
                } else if (el.type === 'radio') {
                  return (
                    <>
                      <label htmlFor='time'>{t(el.title)}</label>
                      <input
                        value={el.value}
                        checked={selectRadio === el.value}
                        onChange={handleRadioChange}
                        className='item'
                        name='time'
                        type={el.type}
                      />
                    </>
                  );
                } else {
                  return (
                    <div className='item'>
                      <CustomSelect
                        className='custom-select'
                        value={selectValue[item.key]}
                        handleChange={handleChange}
                        label={item.title}
                        name={item.key}
                        options={handleOption(item.key)}
                        defaultValue={''}
                      />
                    </div>
                  );
                }
              })
            : ''}
        </div>,
      ];
      colCount = item.col;
    }
  });

  // Thêm currentRow cuối cùng vào rows
  rows.push(
    <div className='row' key={rows.length}>
      {currentRow}
    </div>
  );
  // console.log('mutation.isLoading',mutation.isLoading);
  // console.log('loading', loading);
  // console.log('isFetching', isFetching);
  console.log('dataCustomer?.data?.results', dataCustomer?.data?.results);
  return (
    <div className='find-data-customer-container'>
      {mutation.isLoading && <Spinner />}
      {isFetching && <Spinner />}
      <div className='find-data-customer-title'>
        <div className='icon'>
          <HiStar />
        </div>
        <p>{`[CCLT3010] ${t('Asigned Customer - TS')}`}</p>
      </div>
      <div className='find-data-customer-filter'>{rows}</div>
      <div className='find-data-customer-table'>
        <table>
          <thead>
            <tr>
              <th className='except'>No</th>
              <th className='except'>
                <input checked={check} onChange={handleChangeCheckbox} type='checkbox' />
              </th>
              <th>{t('Last Call Time')}</th>
              <th>{t('Call Result')}</th>
              <th>{t('Cust Name')}</th>
              <th>{t('Salutation')}</th>
              <th>{t('Date of Birth')}</th>
              <th>{t('Cust Id No')}</th>
              <th>{t('Id Issue Date')}</th>
              <th>{t('Id Issue Place')}</th>
              <th>{t('Tel (Mobile)')}</th>
              <th>{t('Tel (Phone)')}</th>
              <th>{t('Email')}</th>
              <th>{t('Permanent Addr.')}</th>
              <th>{t('Remark')}</th>
              <th>{t('Occupation')}</th>
              <th>{t('Company Name')}</th>
              <th>{t('Income')}</th>
              <th>{t('Area')}</th>
              <th>{t('Campaign Name')}</th>
              <th>{t('Input Date')}</th>
              <th>{t('Input Time')}</th>
              <th>{t('Agent ID')}</th>
              <th>{t('Agent Name')}</th>
              <th>{t('TS Code')}</th>
              <th>{t('Assigned Date')}</th>
              <th>{t('Processing Status')}</th>
              <th>{t('TS Stat')}</th>
              <th>{t('Appoint Place')}</th>
              <th>{t('Product')}</th>
              <th>{t('Loan Amt')}</th>
              <th>{t('Tenure')}</th>
              <th>{t('Return Date')}</th>
              <th>{t('Process type')}</th>
              <th>{t('IR')}</th>
              <th>{t('Agree ID')}</th>
              <th>{t('First Call Date')}</th>
              <th>{t('First Call Time')}</th>
              <th>{t('Main Categry')}</th>
              <th>{t('Sub Category')}</th>
              <th>{t('Call count')}</th>
              <th>{t('Register')}</th>
              <th>{t('Postal ID')}</th>
              <th>{t('Risk Score')}</th>
              <th>{t('Checking S37')}</th>
              <th>{t('Channel')}</th>
              <th>{t('Mediasource')}</th>
              <th>{t('Campaign')}</th>
              <th>{t('Adset')}</th>
            </tr>
          </thead>
          <tbody>
            {dataCustomer?.data?.results?.map((item, index) => (
              <tr
                key={item.id}
                className={`${arrList?.current?.includes(item.id) ? 'active-row' : ''} ${
                  item?.histories[0]?.subCategory?.id === 6 && item?.histories[0]?.subCategory?.parent?.id === 2
                    ? 'active-red'
                    : ''
                } ${item?.histories[0]?.subCategory?.parent?.id === 4 ? 'active-brown' : ''}`}
              >
                <td className='except'>{index + 1}</td>
                <td className='except'>
                  <input
                    checked={arrList?.current?.includes(item?.id)}
                    onChange={(e) => handleChangeCheckItem(e, item.id)}
                    type='checkbox'
                  />
                </td>
                <td class='cell-with-border'>
                  <div>{fomatTime(item?.histories[0]?.createdOn)}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{CallReslt(item?.callResult)}</div>
                </td>
                <td class='cell-with-border'>{item?.fullname || ''}</td>
                <td class='cell-with-border'>{item?.salutation || ''}</td>
                <td class='cell-with-border'>{item?.dateOfBirth || ''}</td>
                <td class='cell-with-border'>{item?.idNo || ''}</td>
                <td class='cell-with-border'>{item?.issueDate || ''}</td>
                <td class='cell-with-border'>{item?.issuePlace || ''}</td>
                <td class='cell-with-border'>{hidePhoneNumber(item?.mobile) || ''}</td>
                <td class='cell-with-border'>{hidePhoneNumber(item?.phone) || ''}</td>
                <td class='cell-with-border'>
                  <div>{item?.email || ''}</div>{' '}
                </td>
                <td class='cell-with-border'>
                  <div>{item?.permanentAddres || ''}</div>
                </td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'>
                  {item?.occupation ? dataOccupations?.filter((el) => el.value === item?.occupation)[0]?.label : ''}
                </td>
                <td class='cell-with-border'>
                  <div>{item?.companyName || ''}</div>
                </td>
                <td class='cell-with-border'>
                  {item?.income && <CustomNumberFormat displayType={'text'} value={item?.income} />}
                </td>
                <td class='cell-with-border'>
                  {dataCities ? dataCities?.filter((el) => el.value === item?.city)[0]?.label : 'NA'}
                </td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'>
                  <div>{fomatDate(item?.createdOn) || ''}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{fomatHours(item?.createdOn) || ''}</div>
                </td>
                <td class='cell-with-border'>{item?.id}</td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'>{fomatAsignTime(item?.assignedDate)}</td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'>
                  <div>{item?.payment?.product?.name || ''}</div>
                </td>

                <td class='cell-with-border'>
                  <div>{item?.payment?.amount || ''}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{item?.payment?.tenure || ''}</div>
                </td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'>
                  <div>{fomatDate(item?.histories[0]?.createdOn)}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{fomatHours(item?.histories[0]?.createdOn)}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{item?.histories[0]?.subCategory?.parent?.name || ''}</div>
                </td>
                <td class='cell-with-border'>
                  <div>{item?.histories[0]?.subCategory?.name || ''}</div>
                </td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
                <td class='cell-with-border'></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='find-data-customer-footer'>
        <div className='pagnigation'>
          <Pagnigation
            total={dataCustomer?.data?.count}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        {!isMobile && (
          <div
            onClick={handleConsulting}
            className='btn-conslt flex items-center'
            style={{ gap: '4px', cursor: 'pointer  ' }}
          >
            Consulting
            <div className='icon-conslt'>
              <HiChevronRight />
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div
          onClick={handleConsulting}
          className='btn-conslt flex items-center'
          style={{ gap: '4px', cursor: 'pointer  ' }}
        >
          Consulting
          <div className='icon-conslt'>
            <HiChevronRight />
          </div>
        </div>
      )}
      <div style={{ height: '40px' }}></div>
    </div>
  );
}
function hidePhoneNumber(phoneNumber) {
  const visibleDigits = 3;
  const hiddenDigits = phoneNumber.length - visibleDigits;
  return phoneNumber.length > 7 ? '*'.repeat(hiddenDigits) + phoneNumber.slice(-visibleDigits) : 0;
}
function addColonNumber(number) {
  number = Number(number);
  if (isNaN(number) || Math.abs(number) < 1000) {
    return number.toString();
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
const CallReslt = (value) => {
  if (value === '1') {
    return 'Success';
  } else if (value === '0') {
    return 'No answer';
  } else if (!value) {
    return '';
  }
};
const fomatTime = (time) => {
  if (!time) {
    return '';
  }
  return format(addHours(new Date(time), 7), 'yyyy-MM-dd HH:mm:ss');
};
const fomatAsignTime = (time) => {
  if (!time) {
    return '';
  }
  return format(addHours(new Date(time), 7), 'yyyy-MM-dd');
};
const fomatDate = (time) => {
  if (!time) {
    return '';
  }
  return format(addHours(new Date(time), 7), 'yyyy-MM-dd');
};
const fomatHours = (time) => {
  if (!time) {
    return '';
  }
  return format(addHours(new Date(time), 7), 'HH:mm:ss');
};

export default FindDataCustomer;
