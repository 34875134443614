import React from 'react';
import { useTranslation } from 'react-i18next';

const BoxDashboard4 = ({ dataCall }) => {
  const { t } = useTranslation();

  const totalConnect = dataCall?.data?.connect?.map((v) => ({
    createdOn_Date: v?.createdOn_Date,
    connect: v?.total,
    totalBillsec: v?.totalBillsec,
  }));

  const totalNotConnect = dataCall?.data?.notConnect?.map((v) => ({
    createdOn_Date: v?.createdOn_Date,
    notConnect: v?.total,
  }));

  const mergedArray = totalConnect?.reduce((acc, item1) => {
    const { createdOn_Date, connect, totalBillsec } = item1;
    const item2 = totalNotConnect.find((item) => item.createdOn_Date === createdOn_Date);
    if (item2) {
      const { notConnect } = item2;
      acc.push({ createdOn_Date, connect, notConnect, totalBillsec });
    } else {
      acc.push({ createdOn_Date, connect, totalBillsec });
    }
    return acc;
  }, []);


  return (
    <div className='dasboard-table dashboard-table__second ' style={{ height: '100%' }}>
      <table className='table mb-0' style={{ backgroundColor: '#FFFFFF', borderRadius: '0px', width: '100%' }}>
        {/* <thead>
          <tr>
            <th rowspan='2'>{t('Date')}</th>
            <th rowspan='2'>{t('Total')}</th>
            <th colSpan='2'>{t('Total Call')}</th>
            <th rowspan='2'>{t('Talktime Total')}</th>
            <th rowspan='2'>{t('Average duration')}</th>
          </tr>
          <tr style={{ backgroundColor: '#EDEDED' }}>
            <th>{t('fresh Data (not call ever)')}</th>
            <th>{t('Wip Data (called connect before)')}</th>
          </tr>
        </thead> */}
        <thead>
          <tr className='bg-[#EDEDED]'>
            <th>{t('Date')}</th>
            <th>{t('Total Call')}</th>
            <th>{t('Talktime Total')}</th>
            <th>{t('Average duration')}</th>
          </tr>
        </thead>
        <tbody>
          {mergedArray?.map((v, i) => (
            <tr key={i}>
              <td>{v?.createdOn_Date}</td>
              <td>{v?.connect + v?.notConnect}</td>
              <td>{v?.totalBillsec}</td>
              <td>{v?.totalBillsec / v?.connect ? parseFloat(v?.totalBillsec / v?.connect).toFixed(2) : 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BoxDashboard4;
