import React, { useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { nanoid } from 'nanoid';
import events from './events';
import moment from 'moment';
import ButtonRefreshPlus from './ButtonRefreshPlus';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from '../../../../api/dashboardApi';
import Modal, { ModalContent } from '../../../../components/common/Modal';
import NewTodo from './NewTodo';

let todayStr = new Date().toISOString().replace(/T.*$/, '');

const BoxDashbroad1 = () => {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef = useRef(null);
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [modalTodo, setModalTodo] = useState(false);

  const { data: dataDashboardTodo, isLoading: loading } = useQuery({
    queryKey: ['dashBoardCalendar', modalTodo],
    queryFn: () => DashboardApi.getCalendar(),
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });

  console.log('dataDashboardTodo', dataDashboardTodo);

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };

  const handleDateClick = (arg) => {};
  const handleDateSelect = (selectInfo) => {
    if (selectInfo.view.type === 'timeGridWeek' || selectInfo.view.type === 'timeGridDay') {
      selectInfo.view.calendar.unselect();
      setState({ selectInfo, state: 'create' });

      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  };
  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <i
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {eventInfo.event.title}
        </i>
      </div>
    );
  };
  const handleEventClick = (clickInfo) => {
    setState({ clickInfo, state: 'update' });
    setTitle(clickInfo.event.title);
    setStart(clickInfo.event.start);
    setEnd(clickInfo.event.end);

    setModal(true);
  };
  const handleEvents = (events) => {
    setCurrentEvents(events);
  };
  const handleEventDrop = (checkInfo) => {
    setState({ checkInfo, state: 'drop' });
    setConfirmModal(true);
  };
  const handleEventResize = (checkInfo) => {
    setState({ checkInfo, state: 'resize' });
    setConfirmModal(true);
  };
  const handleClose = () => {
    setTitle('');
    setStart(new Date());
    setEnd(new Date());
    setState({});
    setModal(false);
  };
  const [state, setState] = useState({});

  const onFilter = (element) => {
    // console.log(element.value);
  };

  return (
    <>
      <Modal active={modalTodo}>
        <ModalContent
          className={'pu-face'}
          heading={'New calendar'}
          onClose={() => {
            setModalTodo(false);
          }}
        >
          <NewTodo setModalTodo={() => setModalTodo()} />
        </ModalContent>
      </Modal>
      <div className='box-content__dasboard'>
        <div className='dashboard-calendar'>
          <ButtonRefreshPlus color={'#0A425C'} handle={() => setModalTodo(true)} />
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev',
              center: 'title',
              right: 'next',
            }}
            buttonText={{
              today: 'current',
              month: 'month',
              week: 'week',
              day: 'day',
              list: 'list',
            }}
            initialView='timeGridWeek'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            events={dataDashboardTodo?.data?.todo?.map((v) => ({
              id: v?.id,
              start: moment(v?.duedate, 'YYYY-MM-DD HH:MM:SS')?.format('YYYY-MM-DD'),
              title: v?.description,
            }))}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={() => handleEvents(events)}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            //
            dateClick={handleDateClick}
            eventAdd={(e) => {
              // console.log('eventAdd', e);
            }}
            eventChange={(e) => {
              // console.log('eventChange', e);
            }}
            eventRemove={(e) => {
              // console.log('eventRemove', e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BoxDashbroad1;
