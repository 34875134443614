import React from 'react';
import { MdRefresh } from 'react-icons/md';
import { BiPlus } from 'react-icons/bi';

const ButtonRefreshPlus = ({ color, handle }) => {
  return (
    <div className='flex groups-refesh__plus'>
      <span className='btn-groups__dashboard' onClick={handle} style={{ backgroundColor: color }}>
        <BiPlus />
      </span>
      <span className='btn-groups__dashboard' style={{ backgroundColor: color }}>
        <MdRefresh />
      </span>
    </div>
  );
};

export default ButtonRefreshPlus;
