import { createSlice } from '@reduxjs/toolkit';
import { getAllDashboardApi } from './dashboardThunk';

const initialState = {
  loading: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDashboardApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDashboardApi.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getAllDashboardApi.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

// Actions
export const dashboardActions = dashboardSlice.actions;

// Selector
export const selectAccessToken = (state) => state.dashboard.accessToken;
export const selectDashboardloading = (state) => state.dashboard.loading;

// Reducer
const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
