import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registered: 'unregistered',
  numberPhone: null,
  listPhone: [],
  statusCall: false,
  callEnd: null,
  holdCall: null,
  status: 'logIn',
  time: false,
  clear: null,
  makeCallID:''
};

const callSlice = createSlice({
  name: 'call',
  initialState: initialState,
  reducers: {
    updateRegister: (state) => {
      state.registered = false;
    },
    updateListPhone: (state, action) => {
      state.listPhone = action.payload;
    },
    updateRegisterCall: (state) => {
      state.registered = 'registered';
    },
    updateUnregisterCall: (state) => {
      state.registered = 'unregistered';
    },
    updateRegisteringCall: (state) => {
      state.registered = 'registering';
    },
    startCall: (state, action) => {
      state.numberPhone = action.payload;
    },
    endCallPhone: (state) => {
      state.numberPhone = null;
    },
    clearCallEnd: (state) => {
      state.callEnd = null;
    },
    EndCall: (state) => {
      state.callEnd = true;
      state.time = false;
      state.status = 'After Work';
      // state.time = true;s
    },
    NotReady: (state) => {
      state.time = false;
      state.status = 'NotReady';
    },
    clearHold: (state) => {
      state.callEnd = null;
    },
    Hold: (state) => {
      state.holdCall = state.holdCall + 1;
    },

    Dialing: (state) => {
      state.time = false;
      state.status = 'Dialing';
    },
    updateStatus: (state) => {
      state.status = 'Dialing';
    },
    UpdateTime: (state, action) => {
      state.time = action.payload;
    },
    FncMakeCallId : (state,action) => {
      state.makeCallID = action.payload
    }
  },
});

export const {
  updateRegister,
  updateRegisterCall,
  updateUnregisterCall,
  updateRegisteringCall,
  unregisterCall,
  registerCall,
  startCall,
  endCallPhone,
  updateListPhone,
  clearCallEnd,
  EndCall,
  clearHold,
  Hold,
  UpdateTime,
  NotReady,
  updateStatus,
  Dialing,
  FncMakeCallId
} = callSlice.actions;

// Selector
export const selectStatusCall = (state) => state.call;
export const selectListPhone = (state) => state.call.listPhone;
export const selectCallEnd = (state) => state.call.callEnd;
export const selectHold = (state) => state.call.holdCall;
export const selectMakeCallID = (state) => state.call.makeCallID

const { reducer: callReducer } = callSlice;
export default callReducer;
