import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from '../../api/axiosClient';

//////initial
const initialState = {
  orgList: [],
  keyAll: 'all',
  successEdit: null,
  loading: false,
};
// Actions
const ACTION = {
  GET_ORG: 'org/getOrg',
};

export const getOrg = createAsyncThunk(ACTION.GET_ORG, async (body) => {
  return axiosClient.get('/org/', { params: body });
});

// Reducer
const orgSlice = createSlice({
  name: 'org',
  initialState: initialState,
  reducers: {
    postKey: (state, action) => {
      state.keyAll = action.payload.key;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrg.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.orgList = action.payload;
      });
  },
});

export const { postKey } = orgSlice.actions;

export const selectOrg = (state) => state.org.orgList;
export const selectKey = (state) => state.org.keyAll;

const { reducer: orgReducer } = orgSlice;
export default orgReducer;
