import React from 'react';
import BoxDashbroad1 from './component/BoxDashbroad1';
import BoxDashbroad2 from './component/BoxDashbroad2';
import BoxDashbroad3 from './component/BoxDashbroad3';
import BoxDashboard4 from './component/BoxDashboard4';
import { IoMdClose } from 'react-icons/io';
import { GrStar } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from '../../../api/dashboardApi';
import moment from 'moment';

const Dashboard = () => {
  const completeData = new Date();
  const { t } = useTranslation();

  const { data: dataDashboard, isLoading: loading } = useQuery({
    queryKey: ['reportHeader'],
    queryFn: () => DashboardApi.getAllDashboards(),
    keepPreviousData: true,
    staleTime: 60 * 1000,
  });

  return (
    <>
      <div className='content-dashboard bg-[#FFFFFF] mt-[15px] w-[98%] mx-[auto] mb-[45px]'>
        <div className='top-dashbroad__content'>
          <p className='flex items-center mb-[0]'>
            <GrStar className='text-[#FFFFFF]' /> [COMM1030] {t('DASHBOARD')}
          </p>
          <div className='right-top__dashboard flex items-center text-[#7D848A] font-bold'>
            <p className='mr-[10px] mb-[0px]'>{t('HELP')}</p>
            <span>
              <IoMdClose />
            </span>
          </div>
        </div>
        <div className='body-dashbroad__content'>
          <div className='flex flex-wrap gap-0 mx-[-10px]' style={{ gap: 0 }}>
            <div className='xl:w-[50%] w-[100%] px-[10px] mb-[10px]'>
              <BoxDashbroad3 dataChart={dataDashboard} />
            </div>
            <div className='xl:w-[50%] w-[100%] px-[10px] mb-[10px]'>
              <BoxDashboard4 dataCall={dataDashboard} />
            </div>
            <div className='w-[100%] px-[10px] mb-[10px]'>
              <BoxDashbroad2 />
            </div>
            <div className='w-[100%] px-[10px] mb-[10px]'>
              <BoxDashbroad1 />
            </div>
          </div>
        </div>
        <div className='bottom-dashbroad__content '>
          <p className='mb-[0px]'> {moment(completeData).format('YYYY-MM-DD HH:mm:ss')} Query Completed. </p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
