import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../../api/axiosClient';

const initialState = {
  customerList: {},
  loading: false,
  statusList: {},
  refreshDataHeader: 1,
};

const ACTION = {
  GET_CUSTOMERS: 'customer/getCustomer',
  CREATE_CUSTOMER: 'customer/createCustomer',
  UPDATE_CUSTOMER: 'customer/updateCustomer',

  UPDATE_CUSTOMER2: 'customer/updateCustomer2',

  IMPORT_DATA_CUSTOMER: 'customer/importData',

  GET_STATUS: 'customer/getStatus',
  EDIT_STATUS: 'customer/editStatus',
  CREATE_STATUS: 'customer/createStatus',

  GET_SOURCE: 'customer/getSource',
  EDIT_SOURCE: 'customer/editSource',
  CREATE_SOURCE: 'customer/createSource',
};

export const getCustomer = createAsyncThunk(ACTION.GET_CUSTOMERS, async (body) => {
  return axiosClient.get('/leads/customer/', { params: body });
});

// exportExcel
export const ExportExcel = createAsyncThunk('export', async (body) => {
  return axiosClient.get('/leads/export-csv-customer/', {
    params: body,
    responseType: 'blob',

    headers: {
      'Content-Type': 'application/json',
    },
  });
});

// create customer

export const createCustomer = createAsyncThunk(ACTION.CREATE_CUSTOMER, async (body) => {
  return axiosClient.post('/leads/customer/', body);
});

// update customer

export const updateCustomer = createAsyncThunk(ACTION.UPDATE_CUSTOMER, async (body) => {
  return axiosClient.put(`/leads/customer/`, body);
});

export const updateCustomer2 = createAsyncThunk(ACTION.UPDATE_CUSTOMER2, async (body) => {
  return axiosClient.put(`/leads/customer/?unique_id=${body.unique_id}&tags=${body.tags}`);
});

// import data customer

export const importData = createAsyncThunk(ACTION.IMPORT_DATA_CUSTOMER, async (body) => {
  return axiosClient.post(`/leads/customer-to-package/`, body);
});

// status

export const getStatus = createAsyncThunk(ACTION.GET_STATUS, async (body) => {
  return axiosClient.get('/leads/call-status/', { params: body });
});

export const editStatus = createAsyncThunk(ACTION.EDIT_STATUS, async (body) => {
  return axiosClient.put(`/leads/call-status/${body.id}/`, body);
});

export const createStatus = createAsyncThunk(ACTION.CREATE_STATUS, async (body) => {
  return axiosClient.post(`/leads/call-status/`, body);
});

// source

export const getSource = createAsyncThunk(ACTION.GET_SOURCE, async (body) => {
  return axiosClient.get('/leads/sources/', { params: body });
});

export const editSource = createAsyncThunk(ACTION.EDIT_SOURCE, async (body) => {
  return axiosClient.put(`/leads/sources/${body.id}/`, body);
});

export const createSource = createAsyncThunk(ACTION.CREATE_SOURCE, async (body) => {
  return axiosClient.post(`/leads/sources/`, body);
});

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    saveCurrentPage: (state, action) => {
      state.page = action.payload;
    },
    TrueLoadding: (state) => {
      state.loading = true;
    },
    FalseLoadding: (state) => {
      state.loading = false;
    },
    refreshFnc: (state, action) => {
      state.refreshDataHeader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.success = false;
        state.customerList = action.payload;
      })
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCustomer2.pending, (state) => {})
      .addCase(updateCustomer2.rejected, (state) => {
        state.success = false;
      })
      .addCase(updateCustomer2.fulfilled, (state, action) => {
        state.success = true;
      })

      .addCase(getStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.success = false;
        state.statusList = action.payload.data;
      })

      .addCase(editStatus.pending, (state) => {})
      .addCase(editStatus.rejected, (state) => {
        state.success = false;
      })
      .addCase(editStatus.fulfilled, (state) => {
        state.success = true;
      })
      .addCase(createStatus.pending, (state) => {})
      .addCase(createStatus.rejected, (state) => {
        state.success = false;
      })
      .addCase(createStatus.fulfilled, (state) => {
        state.success = true;
      })

      .addCase(getSource.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSource.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSource.fulfilled, (state, action) => {
        state.loading = false;
        state.success = false;
        state.sourceList = action.payload.data;
      })

      .addCase(editSource.pending, (state) => {})
      .addCase(editSource.rejected, (state) => {
        state.success = false;
      })
      .addCase(editSource.fulfilled, (state) => {
        state.success = true;
      })
      .addCase(createSource.pending, (state) => {})
      .addCase(createSource.rejected, (state) => {
        state.success = false;
      })
      .addCase(createSource.fulfilled, (state) => {
        state.success = true;
      });
  },
});

const { reducer: customertReducer } = customerSlice;
//actions
export const { saveCurrentPage, TrueLoadding, FalseLoadding, refreshFnc } = customerSlice.actions;
export const selectLoaddingCus = (state) => state.customer?.loading;
export default customertReducer;
