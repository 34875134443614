import { createSlice } from '@reduxjs/toolkit';
import { LoginApi } from './authThunk';

const initialState = {
  loading: false,
  accessToken: null,
  refreshToken: null,
  active: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, payload) => {
      state.active = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(LoginApi.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

// Actions
export const { login } = authSlice.actions;

// Selector
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const loaddingLogin = (state) => state.auth.loading;
export const selectAuthActive = (state) => state.auth.active;
// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
