import axiosClient from './axiosClient';

const authApi = {
  login(data) {
    const url = `/account/token/`;
    return axiosClient.post(url, data);
  },
  get(data) {
    const url = `/account/users/`;
    return axiosClient.get(url, data);
  },
  profile(data) {
    const url = `/account/profile/`;
    return axiosClient.get(url, data);
  },
  getListUser(data) {
    const url = `/account/users/`;
    return axiosClient.get(url, data);
  },
};

export default authApi;
