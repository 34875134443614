import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoutes = (props) => {
  const location = useLocation();
  const { Component } = props;
  const token = localStorage.getItem('access_token');

  return token ? <Component /> : <Navigate to={'/login'} replace state={{ from: location }} />;
};

export default ProtectedRoutes;
