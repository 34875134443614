import React, { useState, useEffect } from 'react';
import '../../../../styles/components/Consultation/layoutAsgList.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ConsultationApi from '../../../../api/Consultation';
import { useDispatch } from 'react-redux';
import { updateDataDetai } from '../../../../features/consultation/ConsultationSlice';
import { useTranslation } from 'react-i18next';

const AsgList = ({ setKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['Campaign'],
    queryFn: () => ConsultationApi.getDetailCustomer(),
    keepPreviousData: false,
    // staleTime: 60 * 1000,
  });
  // useEffect(() => {
  //   refetch();
  // }, []);

  const CallReslt = (value) => {
    if (value === '1') {
      return t('success');
    } else if (value === '0') {
      return t('nocall');
    } else if (!value) {
      return '';
    }
  };
  return (
    <div className='ags__main'>
      <div className='ags__title'>
        {dataTitle.map((item) => (
          <div key={item.id} className='item'>
            {t(item.title)}
          </div>
        ))}
      </div>
      <div className='ags__content'>
        {data?.data?.contacts?.map((item, i) => (
          <div
            className={`content__item ${
              item?.histories[0]?.subCategory?.id === 6 && item?.histories[0]?.subCategory?.parent?.id === 2
                ? 'active-red'
                : ''
            } ${item?.histories[0]?.subCategory?.parent?.id === 4 ? 'active-brown' : ''} `}
            key={item?.id}
          >
            <div className='item'>{i + 1}</div>
            <div
              className='item'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(updateDataDetai(item));
                setKey('Info');
              }}
            >
              <p> {item?.fullname}</p>
            </div>
            <div className='item'>{item?.idNo}</div>
            <div className='item'>
              <p>{CallReslt(item?.histories[0]?.status)}</p>
            </div>
            <div className='item'>
              <p>{item?.contact?.companyName}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const dataTitle = [
  { id: '1', title: 'No' },
  { id: '2', title: 'Cust Name' },
  { id: '3', title: 'Cust. ID No' },
  { id: '4', title: 'Last call result' },
  { id: '5', title: 'Campaign' },
];
// === '1' ? t('success') : t('nocall')

export default AsgList;
