import React, { useEffect, createRef, useState, useRef } from 'react';
import { WebSocketInterface } from 'jssip';
import _ from 'lodash';
import CallsFlowControl from './CallsFlowControl';
import {
  MdBackspace,
  MdClose,
  MdMicOff,
  MdMic,
  MdVolumeUp,
  MdPhone,
  MdPlayCircleOutline,
  MdPauseCircleOutline,
  MdCallEnd,
} from 'react-icons/md';

import useLongPress from '../../util/useLongPress';
import IconImg from '../../assets/image/img_.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStatusCall,
  endCallPhone,
  updateUnregisterCall,
  updateRegisterCall,
  selectCallEnd,
  clearCallEnd,
  selectHold,
  clearHold,
  FncMakeCallId,
  EndCall,
} from '../../features/call/callSlice';
import Button from '../../components/common/Button';
import FormInput from '../../components/common/FormInput';
import Select from 'react-select';
import ConfirmDialog from './ConfirmDialog/confirm-dialog';
import { selectListUser, selectProfile } from '../../features/user/userSlice';
import { BsFillTelephoneForwardFill } from 'react-icons/bs';

import moment from 'moment';

import { SelectpostCall, phoneupdateStatus } from '../../features/phone/phoneThunk';

import icon from '../../assets/image/favicon.png';
import { selectConsultation } from '../../features/consultation/ConsultationSlice';
import { getPhoneCheck } from '../../features/phone/phoneSlice';

export function DurationTime(props) {
  const generator = (seconds) => {
    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }
    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`;
    }
    return `${min}:${sec}`;
  };

  return (
    <>
      {props.calling && props.answered === false ? (
        <div style={{ marginTop: 10, color: '#807F7F' }}>Đang gọi...</div>
      ) : props.answered ? (
        <div style={{ marginTop: 10, color: '#807F7F' }}>Thời lượng: {generator(props.duration)}</div>
      ) : props.justEnded ? (
        <div style={{ marginTop: 10, color: '#ff0000' }}>Cuộc gọi đã kết thúc</div>
      ) : (
        ''
      )}
    </>
  );
}

const flowRoute = new CallsFlowControl();
const player = createRef();
const endCall = createRef();
const ringCall = createRef();

export default function TestCall({
  setStatusSip,
  setShowPhone,
  setShowDialogCall,
  showDialogCall,
  showPhone,
  phoneNumber,
  callVolume,
  ringVolume,
  setConnectOnStartToLocalStorage,
  setNotifications,
  setCallVolume,
  setRingVolume,
  notifications = true,
  connectOnStart = true,
  timelocale = 'UTC',
  asteriskAccounts = [],
}) {
  const profile = useSelector(selectProfile);
  const [config, setConfig] = useState({});
  const [dialState] = useState('');
  const [activeChannel] = useState(0);

  useEffect(() => {
    if (profile?.length === 0) {
      return;
    }
    if (Object.keys(profile).length !== 0 && profile?.agent) {
      // setConfig({
      //   domain: 'pbx01.siptrunk.vn', // sip-server@your-domain.io
      //   uri: `sip:ext201411001@pbx01.siptrunk.vn;transport=wss`, // sip:sip-user@your-domain.io
      //   password: 'pY2NWZhmZ2VXMsmE', //  PASSWORD ,
      //   ws_servers: 'wss://pbx01.siptrunk.vn:4443', //ws server
      //   sockets: [new WebSocketInterface('wss://pbx01.siptrunk.vn:4443')] || '',
      //   display_name: '***', //jssip Display Name
      //   debug: true, // Turn debug messages on
      //   extraHeaders: [`Contact: <sip:username@mydomain.com>`],
      //   // Turn debug messages on
      // });
      setConfig({
        domain: profile?.agent?.webrtcDomain, // sip-server@your-domain.io
        uri: `${profile?.agent?.webrtcUri};transport=wss`, // sip:sip-user@your-domain.io
        password: profile?.agent?.webrtcPassword, //  PASSWORD ,
        ws_servers: profile?.agent?.webrtcWebsocket
, //ws server
        sockets: [new WebSocketInterface(profile?.agent?.webrtcWebsocket
)] || '',
        display_name: '***', //jssip Display Name
        debug: true, // Turn debug messages on
        extraHeaders: [`Contact: <sip:username@mydomain.com>`],
        // Turn debug messages on
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // const config = {
  //   domain: profile?.agent?.webrtc_domain  , // sip-server@your-domain.io
  //   uri: profile?.agent?.webrtc_uri, // sip:sip-user@your-domain.io
  //   password: profile?.agent?.webrtc_password, //  PASSWORD ,
  //   ws_servers: profile?.agent?.webrtc_websocket, //ws server
  //   sockets: [new WebSocketInterface(profile?.agent?.webrtc_websocket)],
  //   display_name: '***', //jssip Display Name
  //   debug: true, // Turn debug messages on
  //   pcConfig: {
  //     rtcpMuxPolicy: 'negotiate',
  //     iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
  //   },
  // };

  const defaultSoftPhoneState = {
    displayCalls: [
      {
        id: 0,
        info: 'Ch 1',
        hold: false,
        muted: 0,
        autoMute: 0,
        inCall: false,
        inAnswer: false,
        inTransfer: false,
        callInfo: 'Ready',
        inAnswerTransfer: false,
        allowTransfer: true,
        transferControl: false,
        allowAttendedTransfer: true,
        transferNumber: '',
        attendedTransferOnline: '',
        inConference: false,
        callNumber: '',
        duration: 0,
        side: '',
        sessionId: '',
      },
    ],
    phoneConnectOnStart: connectOnStart,
    notifications,
    phoneCalls: [],
    connectedPhone: false,
    connectingPhone: false,
    activeCalls: [],
    callVolume,
    ringVolume,
  };
  
  const [localStatePhone, setLocalStatePhone] = useState(defaultSoftPhoneState);
  const phone = createRef();
  const [value, setValue] = React.useState(100);
  const [muted, setMuted] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [hold, setHold] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callJustEnded, setCallJustEnded] = useState(false);
  const [duration, setDuration] = React.useState(0);
  const durationInterval = useRef(null);
  const timer = useRef(null);
  const [sipStatus, setSipStatus] = useState('connecting');
  const [dialNumber, setDialNumber] = useState('');
  const [calls, setCalls] = React.useState([]);
  const [activeCreate, setActiveCreate] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(false);
  const [activeinfomation, setActiveInfomation] = useState(false);
  const [hostLine, setHostLine] = useState();
  const [active, setActive] = useState(false);
  const dialogRefCall = useRef(null);
  const [payloadhot, setpayLoadhot] = useState();
  const dispatch = useDispatch();
  const state = useSelector(selectStatusCall);
  const phoneEnter = useRef;
  const day = new Date();

  flowRoute.activeChanel = localStatePhone.displayCalls[0];
  flowRoute.connectedPhone = sipStatus === 'connected';
  flowRoute.engineEvent = (event, payload) => {
    // Listen Here for Engine "UA jssip" events
    switch (event) {
      case 'connecting':
        setSipStatus('connecting');
        break;
      case 'connected':
        setSipStatus('connected');
        break;
      case 'registered':
        break;
      case 'disconnected':
        setSipStatus('disconnected');
        break;
      case 'registrationFailed':
        break;

      default:
        break;
    }
  };

  const handleChange = (e) => {
    player.current.volume = parseInt(e.target.value, 10) / 100;
    setValue(e.target.value);
  };

  function selectedEffect(e) {
    const val = parseInt(e.target.value);
    e.target.style.background =
      'linear-gradient(to right, rgb(154, 165, 234) 0%, rgb(154, 165, 234) ' + val + '%, #fff ' + val + '%, #fff 100%';
  }

  // const continuosIncerment = () => {
  //   setDialNumber(dialNumber.slice(0, -1));
  //   timer.current = setTimeout(continuosIncerment, 200);
  // };

  // function timeoutClear() {
  //   clearTimeout(timer.current);
  // }
  useEffect(() => {
    setStatusSip(sipStatus);
    if (Object.keys(config).length > 0 && state.registered === 'registering') {
      flowRoute.config = config;
      flowRoute.init();
      flowRoute.start();
      try {
        player.current.defaultMuted = false;
        player.current.autoplay = true;
        player.current.volume = 1;

        ringCall.current.defaultMuted = false;
        ringCall.current.autoplay = false;
        ringCall.current.volume = 1;
        //player.volume = this.outputVolume;
        flowRoute.player = player;
        flowRoute.ringer = ringCall;
        dispatch(updateRegisterCall());
      } catch (e) {
        dispatch(updateUnregisterCall());
      }
    }
    if (state.registered === 'unregistered' && Object.keys(flowRoute.config).length > 0) {
      flowRoute.stop();
      dispatch(updateUnregisterCall());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sipStatus, state.registered, config, localStatePhone.callVolume, localStatePhone.ringVolume]);

  useEffect(() => {
    if (!('Notification' in window)) {
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
        }
      });
    }
  }, []);

  const handleDuration = () => {
    var seconds = 0;
    durationInterval.current = setInterval(() => {
      seconds++;
      setDuration(seconds);
    }, 1000);
  };

  const [, setCheckMic] = useState(false);
  const assign_id = useSelector(selectConsultation);
  const checkMic = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        if (stream.getAudioTracks().length > 0) {
          //code for when none of the devices are available
          setCheckMic(true);
        } else {
          // code for when both devices are available
          setCheckMic(false);
        }
      })
      .catch(function (error) {
        // code for when there is an error
        setCheckMic(false);
      });
  };

  var orgCode = profile?.agent?.extension;
  flowRoute.onCallActionConnection = async (type, payload, data) => {
    switch (type) {
      case 'created':
        break;
      case 'sending':
      
        dispatch(
          SelectpostCall({
            // call_id: payload?.call_id,
            make_id: payload.make_id,
            assign_id: assign_id?.id,
          })
        );
        dispatch(FncMakeCallId(payload.make_id))
        break;
      case 'reinvite':
        // looks like its Attended Transfer
        // Success transfer

        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: _.map(localStatePhone.displayCalls, (a) =>
            a.sessionId === payload
              ? {
                  ...a,
                  allowAttendedTransfer: true,
                  allowTransfer: true,
                  inAnswerTransfer: true,
                  inTransfer: true,
                }
              : a
          ),
        }));

        break;
      case 'incomingCall':
        const number = payload._remote_identity._uri._user;
        setpayLoadhot(payload);
        // if (!active) return;
        if (payload._direction === 'incoming') {
          setDialNumber(number);
        }
        // if (active) {
        //   setActive(false);
        // }
        var options = {
          body: 'Notification Body',
          icon: icon,
          dir: 'ltr',
        };
        new Notification('đang có cuộc gọi tới', options);
        ringCall.current?.play();

        const result = await ConfirmDialog(
          {
            title: 'Có cuộc gọi đến số máy của bạn',
            message: `Cuộc gọi từ số ${number}`,
            confirmText: 'Đồng ý',
            confirmClass: 'button-red',
            cancelText: 'Từ chối',
            cancelClass: 'button-cancel',
            phoneNumber: number,
            orgCode: orgCode,
            webSocket: webSocket,
          },
          dialogRefCall
        );

        if (result && checkMic()) {
          ringCall.current?.pause();
          flowRoute.answer(payload._id);
          setShowPhone(true);

          const newProgressLocalStatePhone = _.cloneDeep(localStatePhone);
          newProgressLocalStatePhone.displayCalls[0] = {
            ...localStatePhone.displayCalls[0],
            sessionId: payload._id,
          };
          // Save new object into the array with display calls

          setLocalStatePhone((prevState) => ({
            ...prevState,
            displayCalls: newProgressLocalStatePhone.displayCalls,
            phoneCalls: [
              ...prevState.phoneCalls,
              { check: 1 },
              {
                callNumber:
                  payload.remote_identity.display_name !== ''
                    ? `${payload.remote_identity.display_name || ''}`
                    : payload.remote_identity.uri.user,
                sessionId: payload.id,
                ring: false,
                duration: 0,
                direction: payload.direction,
              },
            ],
          }));

          //setDialNumber(number);
          setInCall(true);
        } else {
          flowRoute.hungup();
          ringCall.current.pause();
        }
        break;
      case 'outgoingCall':
        // looks like new call its outgoing call
        // Create object with the Display data of new outgoing call
        setActiveCustomer(true);
        const newProgressLocalStatePhone = _.cloneDeep(localStatePhone);
        newProgressLocalStatePhone.displayCalls[0] = {
          ...localStatePhone.displayCalls[0],
          sessionId: payload.id,
        };

        // Save new object into the array with display calls

        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: newProgressLocalStatePhone.displayCalls,
          phoneCalls: [
            ...prevState.phoneCalls,
            { check: 1 },
            {
              callNumber:
                payload.remote_identity.display_name !== ''
                  ? `${payload.remote_identity.display_name || ''}`
                  : payload.remote_identity.uri.user,
              sessionId: payload.id,
              ring: false,
              duration: 0,
              direction: payload.direction,
            },
          ],
        }));

        setInCall(true);

        break;

      case 'callAccepted':
        setCallAccepted(true);
        handleDuration();
        setActiveCreate(true);
        setActiveCustomer(true);
        setActiveInfomation(true);
        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: [...prevState.displayCalls],
        }));

        break;

      case 'callEnded':
        dispatch(EndCall());
        setActiveInfomation(false);
        // setActiveCustomer(false);
        dialogRefCall?.current?.closeDialogCall();
        phone.current.disabled = false;
        setInCall(false);
        setCallAccepted(false);
        setCallJustEnded(true);
        setTimeout(() => setCallJustEnded(false), 1500);
        setDuration(0);
        clearInterval(durationInterval.current);
        endCall.current.play();
        Socket(JSON.stringify('disconented'));
        dispatch(endCallPhone());
        const firstCheck = localStatePhone.phoneCalls.filter(
          (item) => item.sessionId === payload && item.direction === 'incoming'
        );

        const secondCheck = localStatePhone.displayCalls.filter((item) => item.sessionId === payload);

        dispatch(
          getPhoneCheck({
            status: callAccepted ? 'Đã nghe máy' : 'Không nghe máy',
            event: firstCheck[0]?.direction ? 'Gọi vào' : 'Gọi ra',
            phone: phone.current?.value,
            content: firstCheck[0]?.direction ? 'Cuộc gọi đến' : 'Cuộc gọi đi',
          })
        );
        if (firstCheck.length === 1) {
          setCalls((call) => [
            {
              status: callAccepted ? 'Đã nghe máy' : 'Không nghe máy',
              event: firstCheck[0]?.direction ? 'Gọi vào' : 'Gọi ra',
              phone: phone.current?.value,
              content: firstCheck[0]?.direction ? 'Cuộc gọi đến' : 'Cuộc gọi đi',
            },
           
          ]);
        } else if (secondCheck.length === 1) {
          setCalls((call) => [
            {
              status: callAccepted ? 'Đã nghe máy' : 'Không nghe máy',
            event: firstCheck[0]?.direction ? 'Gọi vào' : 'Gọi ra',
            phone: phone.current?.value,
            content: firstCheck[0]?.direction ? 'Cuộc gọi đến' : 'Cuộc gọi đi',
            },
            
          ]);
        }

        // if (firstCheck.length === 1) {
        //   dispatch(
        //     CallHistory({
        //       status: callAccepted ? 'answered' : 'missed',
        //       sessionId: firstCheck[0].sessionId,
        //       direction: firstCheck[0].direction,
        //       number: phone.current?.value,
        //       time: new Date(),
        //     })
        //   );
        // } else if (secondCheck.length === 1) {
        //   dispatch(
        //     CallHistory({
        //       status: callAccepted ? 'answered' : 'missed',
        //       sessionId: secondCheck[0].sessionId,
        //       direction: secondCheck[0].direction,
        //       number: phone.current?.value,
        //       time: new Date(),
        //     })
        //   );
        // }

        // setCallAccepted(false);

        break;
      case 'hold':
        setHold(true);
        break;
      case 'unhold':
        setHold(false);
        break;
      default:
        break;
    }
  };

  const handleMicMute = () => {
    flowRoute.setMicMuted();
    setMuted(!muted);
  };

  const handleEndCall = (event) => {
    dispatch(endCallPhone());
    event?.persist();
    phone.current.disabled = false;
    flowRoute.hungup(localStatePhone.displayCalls[0].sessionId);
    setInCall(false);
    clearInterval(durationInterval.current);
    setDuration(0);
    endCall.current.play();
    dispatch(clearCallEnd());
    dispatch(clearHold());
  };
  const callEnd = useSelector(selectCallEnd);
  useEffect(() => {
    callEnd && handleEndCall();
  }, [callEnd]);

  const handleHold = () => {
    if (hold === false) {
      setHold(true);
      flowRoute.hold(localStatePhone.displayCalls[0].sessionId);
    } else if (hold === true) {
      setHold(false);
      flowRoute.unhold(localStatePhone.displayCalls[0].sessionId);
    }
  };
  const holdCall = useSelector(selectHold);
  useEffect(() => {
    if (holdCall) {
      if (hold === false) {
        setHold(true);
        flowRoute.hold(localStatePhone.displayCalls[0].sessionId);
      } else if (hold === true) {
        setHold(false);
        flowRoute.unhold(localStatePhone.displayCalls[0].sessionId);
      }
    }
  }, [holdCall]);

  const handleDialNumberChange = (event) => {
    event.persist();
    setDialNumber(event.target.value);
  };

  const onLongPressDelete = () => {
    !inCall && setDialNumber((dialNumber) => dialNumber.slice(0, -1));
    timer.current = setTimeout(onLongPressDelete, 100);
  };

  const onClickDelete = () => {
    !inCall && setDialNumber(dialNumber.slice(0, -1));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPressDelete, onClickDelete, defaultOptions);

  const followCursor = () => {
    const end = phone.current?.value.length;
    phone.current.setSelectionRange(end, end);
    // phone.current.focus();
  };

  const handlelistStatus = (value) => {
    setValuephone(Number(value.label?.slice(-4)));
  };

  const handleCallAttendedTransfer = (event, number) => {
    switch (event) {
      case 'transfer':
        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: _.map(localStatePhone.displayCalls, (a) =>
            a.id === activeChannel
              ? {
                  ...a,
                  transferNumber: dialState || number,
                  allowAttendedTransfer: false,
                  allowTransfer: false,
                  transferControl: true,
                  allowFinishTransfer: false,
                  callInfo: 'Attended Transferring...',
                  inTransfer: true,
                }
              : a
          ),
        }));

        flowRoute.activeCall.sendDTMF(`*2${dialState || number}`);
        break;
      case 'merge':
        const newCallMergeAttendedTransferDisplayCalls = _.map(localStatePhone.displayCalls, (a) =>
          a.id === activeChannel
            ? {
                ...a,
                callInfo: 'Conference',
                inConference: true,
              }
            : a
        );
        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: newCallMergeAttendedTransferDisplayCalls,
        }));

        flowRoute.activeCall.sendDTMF('*5');
        break;
      case 'swap':
        flowRoute.activeCall.sendDTMF('*6');
        break;
      case 'finish':
        flowRoute.activeCall.sendDTMF('*4');
        break;
      case 'cancel':
        const newCallCancelAttendedTransferDisplayCalls = _.map(localStatePhone.displayCalls, (a) =>
          a.id === activeChannel
            ? {
                ...a,
                transferNumber: dialState,
                allowAttendedTransfer: true,
                allowTransfer: true,
                allowFinishTransfer: false,
                transferControl: false,
                inAnswerTransfer: false,
                callInfo: 'In Call',
                inTransfer: false,
              }
            : a
        );
        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: newCallCancelAttendedTransferDisplayCalls,
        }));
        flowRoute.activeCall.sendDTMF('*3');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.numberPhone) return;
    // flowRoute.call(Number(covertPhone(state.numberPhone)));

    flowRoute.call(state.numberPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.numberPhone]);

  const callPhone = async () => {
    await setDialNumber(state?.numberPhone);
    await flowRoute.call(state.numberPhone);
    await setInCall(true);

    phone.current.disabled = true;
  };
  useEffect(() => {
    if (phone.current) {
      // phone.current.focus();
    }
  }, [phone]);

  // const status

  const statusText = (status) => {
    switch (status) {
      case 'connecting':
        return 'connecting';
      case 'connected':
        return 'connected';

      case 'disconnected':
        return 'disconnected';
      default:
        break;
    }
  };
  useEffect(() => {}, [sipStatus]);

  const phoneValue = useSelector(selectListUser);

  const [valuephone, setValuephone] = useState();

  const webSocket = useRef(null);
  var url = `wss://api.siptrunk.vn/socket-server/${orgCode}`;

  useEffect(() => {
    webSocket.current = new WebSocket(url);
    webSocket.current.onmessage = (message) => {
      const mes = JSON.parse(message.data);

      setActive(true);

      setHostLine(mes?.message?.hotline);
      // dispatch(getHotLine(mes?.message?.hostLine));
    };
  }, [url]);

  const Socket = (value) => {
    if (orgCode !== undefined) {
      webSocket.current = new WebSocket(url);

      webSocket.current.onopen = () => {
        webSocket.current.send(value);
      };

      webSocket.current.onclose = function (evt) {};
    }
  };

  const myFunction = () => {
    // your logic here

    // if (phone.current?.value) {
    flowRoute.call(phoneEnter.current);
    Socket(JSON.stringify('conented'));
    setInCall(true);

    phone.current.disabled = true;
    // }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
  console.log('callssdasdasdas',calls);
  useEffect(() => {
    phoneEnter.current = dialNumber || state.numberPhone;
  }, [dialNumber, state.numberPhone]);
  return (
    <div style={{display:'none'}}>

      <div
        className='softphone-dialog'
        style={
          inCall
            ? {
                background: 'linear-gradient(180deg,rgb(154, 165, 234) 0%,rgba(255, 255, 255, 1) 50%)',
                zIndex: '3060',
              }
            : { display: showPhone ? 'block' : 'none', zIndex: '3060' }
        }
      >
        {!inCall && (
          <div
            className='close-phone'
            onClick={() => {
              setShowDialogCall(false);
              setShowPhone(false);
              setActiveCustomer(false);
            }}
          >
            <MdClose />
          </div>
        )}
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            {inCall && (
              <div className='photo'>
                <img src={IconImg} alt='avatar' />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <input
              type={'text'}
              // value={state.numberPhone ? state.numberPhone : dialNumber}
              value={dialNumber}
              onChange={handleDialNumberChange}
              ref={phone}
              id='phoneNumberInput'
              autoFocus
              placeholder={!state.numberPhone ? 'Nhập số điện thoại ...' : state.numberPhone}
              className='form-control-phone'
              autoComplete='off'
            />
            <MdBackspace
              {...longPressEvent}
              style={
                !inCall
                  ? {
                      color: 'gray',
                      cursor: 'pointer',
                    }
                  : { display: 'none' }
              }
              id='deleteBtn'
              // onMouseDown={continuosIncerment}
              // onMouseUp={timeoutClear}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <DurationTime calling={inCall} answered={callAccepted} duration={duration} justEnded={callJustEnded} />
          </div>

          {/* audio */}
          <div hidden>
            <audio preload='auto' ref={player} />
          </div>
          <div hidden>
            <audio
              preload='auto'
              src={require('../../assets/sounds/end_call.mp3')}
              autoPlay={false}
              defaultmuted='false'
              volume='1'
              ref={endCall}
            />
          </div>
          <div hidden>
            <audio
              preload='auto'
              src={require('../../assets/sounds/ring_call.wav')}
              autoPlay={false}
              defaultmuted='false'
              volume='1'
              ref={ringCall}
            />
          </div>

          <div className='dialpad-wrap' style={!inCall ? {} : { display: 'none' }}>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('1'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>1</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('2'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>2</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('3'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>3</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('4'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>4</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('5'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>5</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('6'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>6</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('7'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>7</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('8'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>8</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('9'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>9</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('*'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>*</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('0'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>0</div>
            </div>
            <div
              className='dialpad-number no-select'
              onClick={() => {
                setDialNumber(dialNumber.concat('#'));
                followCursor();
              }}
            >
              <div className='dialpad-number-circle'>#</div>
            </div>
          </div>
          <div className='dial-buttons'>
            {/* <div
              onClick={() => {
                // flowRoute.transfer();
                handleCallAttendedTransfer('transfer', 1002);
              }}
            >
              Chuyen
            </div> */}
            {inCall ? (
              <Button style={{ backgroundColor: '#f4f6f8' }} onClick={handleMicMute}>
                {muted ? <MdMicOff /> : <MdMic />}
              </Button>
            ) : (
              <Button disabled>
                <MdMic />
              </Button>
            )}
            {inCall === false ? (
              <Button
                className={'callButton'}
                onClick={() => {
                  flowRoute.call(phone.current?.value);
                  Socket(JSON.stringify('conented'));
                  setInCall(true);

                  phone.current.disabled = true;
                }}
              >
                <MdPhone style={{ color: 'white' }} />
              </Button>
            ) : (
              <Button className={'endCallButton'} onClick={handleEndCall}>
                <MdCallEnd style={{ color: 'yellow' }} />
              </Button>
            )}
            {callAccepted ? (
              <div style={{ display: 'flex', width: 38, justifyContent: 'space-around', marginLeft: '-20px' }}>
                <Button size='small' style={{ backgroundColor: '#f4f6f8' }} onClick={handleHold}>
                  {hold ? <MdPlayCircleOutline /> : <MdPauseCircleOutline />}
                </Button>

                <Button
                  onClick={() => {
                    handleCallAttendedTransfer('transfer', valuephone);
                  }}
                >
                  <BsFillTelephoneForwardFill />
                </Button>
              </div>
            ) : (
              <Button disabled>
                <MdPauseCircleOutline />
              </Button>
            )}
          </div>

          {callAccepted && (
            <div>
              <Select
                onChange={handlelistStatus}
                name='status'
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: '0 !important',
                    borderBottom: '1px dotted #CCCCCC !important',
                    color: 'gray !important',
                    fontWeight: '600 !important',
                    borderRadius: '0 !important',
                    width: '100% !important',
                  }),
                }}
                options={phoneValue}
                className='basic-multi-select'
                classNamePrefix='Tài khoản'
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',

              marginBottom: 15,
            }}
          >
            <MdVolumeUp style={{ fontSize: 22, marginRight: 10 }} />
            <FormInput
              id='rangePhone'
              type='range'
              value={value}
              onInput={selectedEffect}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className='dialpad-status'>
          <div>Trạng thái :</div>
          <span className={`sipStatusPlaceholder ${statusText(sipStatus)}`} style={{ float: 'right' }}>
            {sipStatus === 'connecting'
              ? 'ĐANG KẾT NỐI...'
              : sipStatus === 'connected'
              ? 'ĐÃ KẾT NỐI'
              : sipStatus === 'disconnected'
              ? 'CHƯA KẾT NỐI'
              : 'CHƯA XÁC ĐỊNH'}
          </span>
        </div>
        {calls.length > 0 && (
          <div style={{ marginTop: '5px', marginLeft: '10px' }}>
            {' '}
            <b>Lịch sử cuộc gọi</b>
          </div>
        )}
        <div
          style={{
            background: '#fff',
            fontSize: '14px',
            fontWeight: '500',
            borderRadius: '0.5rem',
            padding: '8px',
            alignItems: 'center',
            maxHeight: '90px',
            overflow: 'auto',
          }}
        >
          {calls.map((v) => (
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                color: 'red',
                fontSize: '10px',
              }}
            >
              <div>{v?.number}</div>
              <div>{moment(v?.time).format('YYYY-MM-DD h:mm:ss')}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
