import { createSlice } from '@reduxjs/toolkit';

const initialState = {
datadetail:{}
};

const ConsultationSlice = createSlice({
  name: 'consultation',
  initialState: initialState,
  reducers: {
      updateDataDetai: (state, action) => {
          state.datadetail = action.payload
    }
  },
});

export const {
updateDataDetai
} = ConsultationSlice.actions;

// Selector
export const selectConsultation= (state) => state.consultation.datadetail;


const { reducer: consultationReducer } = ConsultationSlice;
export default consultationReducer;
