import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillRecordFill } from 'react-icons/bs';
import ConsultationApi from '../../../../api/Consultation';
import { selectConsultation } from '../../../../features/consultation/ConsultationSlice';
import { useSelector } from 'react-redux';
import Select, { components, ValueContainerProps } from 'react-select';
import { styles } from '../../../../scss/cssCustoms';
import CommonApi from './common';
import CustomNumberFormat from '../../../../components/common/CustomNumberFormat';
const ValueContainer = ({
  children,
  ...props
}) => {
  const document = useQuery({
    queryKey: ['document'],
    queryFn: () => ConsultationApi.getdocument(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  let [values, input] = children;
  const total = document?.data?.data?.doc?.length
  if (Array.isArray(values)) {
    values = `${values.length} Selected / ${total} All`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};
const Tenure = [
  {label:3,value:3}, {label:6,value:6}, {label:9,value:9}, {label:12,value:12}, {label:15,value:15},
]
const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            style={{ width: 'inherit', marginRight: 5 }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};
const PopupInfomation = ({ setStatusInfomation }) => {
  const [data, setData] = useState({});
  const value = useSelector(selectConsultation);
  const [documentValue, setDocumentValue] = useState();
  const [documentValueName, setDocumentValueName] = useState();
  const mutation = useMutation({
    mutationFn: (dataInfomation) => {
      return ConsultationApi.saveInfomation(dataInfomation);
    },
    onSuccess: (data, variables, context) => {
      alert('Nộp hồ sơ thành công');
      setStatusInfomation(false);
    },
    onError: (data, variables, context) => {
    
    },
  });
  const saveInfomation = () => {
    mutation.mutate({ ...data, id: `${value?.id}`, name: value?.fullname, doccument: documentValue });
  };
  const document = useQuery({
    queryKey: ['document'],
    queryFn: () => ConsultationApi.getdocument(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const product = useQuery({
    queryKey: ['product'],
    queryFn: () => ConsultationApi.getproduct(),
  });
  const citys = useQuery({
    queryKey: ['citys'],
    queryFn: () => CommonApi.cities(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSelectRcpt = (value) => {
    setDocumentValue(value.map((v) => v?.value));
    setDocumentValueName(value.map((v) => v?.label))
  };
  return (
    <div>
      {' '}
      <Draggable defaultPosition={{ x: 600, y: 0 }}>
        <div className='boxInfomation' style={{ zIndex: '10000' }}>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFC125',
              justifyContent: 'space-between',
              paddingRight: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <div style={{ marginLeft: '10px', color: 'white' }}>DC infomation</div>
            <AiOutlineClose
              style={{ fontWeight: '600',cursor:'pointer' }}
              onClick={() => {
                setStatusInfomation(false);
              }}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <div className='Item_title_list' style={{ display: 'flex', border: 'none', alignItems: 'center' }}>
              <BsFillRecordFill style={{ fontSize: '12px' }} /> Personal Infomation
            </div>
            <table style={{ width: '100%', padding: '10px' }}>
              <tr>
                <td>Product</td>
                <td colSpan={3}>
                  <select
                    onChange={(e) => {
                      setData({ ...data, ['product']: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  >
                    {product?.data?.data?.products?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Loan amount</td>
                <td>
                  <CustomNumberFormat
                    isGroup={false}
                    onValueChange={(value) => {
                      setData({ ...data, amount: value.floatValue });
                    }}
                  />
                </td>
                <td>Loan Tenure</td>

                <td>
                  {' '}
                  <Select
                      options={Tenure}
                      
                     
                      onChange={(value) => {
                        setData({ ...data, ['tenure']: value?.value });
                      }}
                      styles={styles}
                    />
                </td>
              </tr>
              <tr>
                <td>Appt. Schedule</td>
                <td>
                  <input type='date' name='schedule' onChange={handleChange} />
                </td>
                <td>Place of Appl</td>
                <td>
                  <select
                    onChange={(e) => {
                      setData({ ...data, ['placeappl']: e.target.value });
                    }}
                    style={{ width: '100%' }}
                  >
                    {citys?.data?.data?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>Document</td>
                <td colSpan={3}>
                  <Select
                    options={document?.data?.data?.doc?.map((v) => ({
                      label: v?.name,
                      value: v?.id,
                    }))}

                    isMulti
                    onChange={handleSelectRcpt}
                    styles={styles}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    components={{
                      Option,
                      ValueContainer

                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3}>   {documentValueName?.map((v) =>
                  <div>{v}</div>
                )}</td>
              </tr>
              <tr>
                <td colSpan={2}>1</td>

                <td>   <div
                  className='block_one_2'
                  style={{ marginTop: '5px', marginRight: '5px', display: 'flex', justifyContent: 'flex-end' }}
                >
                  <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
                    <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
                    <span>add</span>
                  </div>
                  <div className='item_block_one_2' onClick={saveInfomation} style={{ cursor: 'pointer' }}>
                    clear
                  </div>
                </div></td>
              </tr>
            </table>

            <div
              className='block_one_2'
              style={{ marginTop: '5px', marginRight: '5px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
                <span>Initialize</span>
              </div>
              <div className='item_block_one_2' onClick={saveInfomation} style={{ cursor: 'pointer' }}>
                Save
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default PopupInfomation;
