import React, { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillRecordFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectConsultation } from '../../../../features/consultation/ConsultationSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import ConsultationApi from '../../../../api/Consultation';
import { styles } from '../../../../scss/cssCustoms';
import Select, { components } from 'react-select';
import CommonApi from './common';
import { useTranslation } from 'react-i18next';
import CustomNumberFormat from '../../../../components/common/CustomNumberFormat';
import customerApi from '../../../../api/customer';
const ValueContainer = ({ children, ...props }) => {
  const document = useQuery({
    queryKey: ['document'],
    queryFn: () => ConsultationApi.getdocument(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });
  let [values, input] = children;
  const total = document?.data?.data?.doc?.length;
  if (Array.isArray(values)) {
    values = `${values.length} Selected / ${total} All`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            style={{ width: 'inherit', marginRight: 5 }}
            type='checkbox'
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};
const DCInfo = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const value = useSelector(selectConsultation);
  const [documentValue, setDocumentValue] = useState();
  const [documentValueName, setDocumentValueName] = useState();
  const {
    data: dataCustomer,
    isLoading: loading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['customer'],
    queryFn: () => customerApi.getAll(),
    keepPreviousData: false,
    staleTime: 60 * 1000,
  });
  const mutation = useMutation({
    mutationFn: (dataInfomation) => {
      return ConsultationApi.saveInfomation(dataInfomation);
    },
    onSuccess: (data, variables, context) => {
      alert('Nộp hồ sơ thành công');
      refetch();
    },
    onError: (data, variables, context) => {
      // setStatusInfomation(false);
    },
  });

  const saveInfomation = () => {
    mutation.mutate({ ...data, id: `${value?.id}`, name: value?.fullname, doccument: documentValue });
  };
  const document = useQuery({
    queryKey: ['document'],
    queryFn: () => ConsultationApi.getdocument(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const product = useQuery({
    queryKey: ['product'],
    queryFn: () => ConsultationApi.getproduct(),
  });
  const citys = useQuery({
    queryKey: ['citys'],
    queryFn: () => CommonApi.cities(),
    staleTime: 60 * 1000,
    keepPreviousData: true,
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSelectRcpt = (value) => {
    setDocumentValue(value.map((v) => v?.value));
    setDocumentValueName(value.map((v) => v?.label));
  };
  console.log('value?.id', value?.id);
  const { data: getpayment, refetch: refetchgetpayment } = useQuery({
    queryKey: ['getpayment', value?.id],
    queryFn: () => ConsultationApi.getInfomation(value?.id, { limit: 100, offset: 0 }),
  });
  const dataDetail = getpayment?.data?.data[getpayment?.data?.data.length - 1];
  useEffect(() => {
    setData({
      product: dataDetail?.product?.id,
      amount: dataDetail?.amount,
      tenure: dataDetail?.tenure,
      schedule: dataDetail?.schedule,
      placeappl: dataDetail?.placeappl,
    });
    setDocumentValueName(dataDetail?.doccument?.map((v) => v?.name));
  }, [getpayment?.data?.data]);

  const Tenure = [
    { label: 3, value: 3 },
    { label: 6, value: 6 },
    { label: 9, value: 9 },
    { label: 12, value: 12 },
    { label: 15, value: 15 },
  ];
  return (
    <div>
      {value && Object.keys(value).length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFC125',
              justifyContent: 'space-between',
              paddingRight: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <div style={{ marginLeft: '10px', color: 'white' }}>{t('DC infomation')}</div>
            <AiOutlineClose
              style={{ fontWeight: '600' }}
              onClick={() => {
                // setStatusInfomation(false);
              }}
            />
          </div>
          <div style={{ padding: '10px' }}>
            <div className='Item_title_list' style={{ display: 'flex', border: 'none', alignItems: 'center' }}>
              <BsFillRecordFill style={{ fontSize: '12px' }} /> {t('DC infomation')}
            </div>
            <table style={{ width: '100%', padding: '10px' }}>
              <tr>
                <td>{t('Product')}</td>
                <td colSpan={3}>
                  {/* <select
                      onChange={(e) => {
                        setData({ ...data, ['product']: e.target.value });
                      }}
                      style={{ width: '100%' }}
                    >
                      {product?.data?.data?.products?.map((v, i) => (
                        <option key={i} value={v?.id}>
                          {v?.name}
                        </option>
                      ))}
                    </select> */}
                  <Select
                    options={product?.data?.data?.products?.map((v) => ({
                      label: v?.name,
                      value: v?.id,
                    }))}
                    value={{ label: product?.data?.data?.products?.find((v) => v?.id === data?.product)?.name }}
                    onChange={(value) => {
                      setData({ ...data, ['product']: value?.value });
                    }}
                    styles={styles}
                  />
                </td>
              </tr>
              <tr>
                <td>{t('Loan amount')}</td>
                <td>
                  <CustomNumberFormat
                    isGroup={false}
                    value={data?.amount}
                    onValueChange={(value) => {
                      setData({ ...data, amount: value.floatValue });
                    }}
                  />
                </td>
                <td>{t('Loan Tenure')}</td>
                <td>
                  {' '}
                  {/* <input type='text'  name='tenure' value={data?.tenure} onChange={handleChange} /> */}
                  <Select
                    options={Tenure}
                    value={{ label: data?.tenure }}
                    onChange={(value) => {
                      setData({ ...data, ['tenure']: value?.value });
                    }}
                    styles={styles}
                  />
                </td>
              </tr>
              <tr>
                <td>{t('Appt. Schedule')}</td>
                <td>
                  <input type='date' value={data?.schedule} name='schedule' onChange={handleChange} />
                </td>
                <td>{t('Place of appl')}</td>
                <td>
                  <Select
                    options={citys?.data?.data?.map((v) => ({
                      label: v?.name,
                      value: v?.id,
                    }))}
                    value={{ label: citys?.data?.data?.find((v) => v?.id === data?.placeappl)?.name }}
                    onChange={(value) => {
                      setData({ ...data, ['placeappl']: value?.value });
                    }}
                    styles={styles}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>{t('Document')}</td>
                <td colSpan={3}>
                  <Select
                    options={document?.data?.data?.doc?.map((v) => ({
                      label: v?.name,
                      value: v?.id,
                    }))}
                    isMulti
                    onChange={handleSelectRcpt}
                    styles={styles}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    components={{
                      Option,
                      ValueContainer,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ minHeight: '20px' }}>
                  {documentValueName?.map((v) => (
                    <div>{v}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {' '}
                  <input type='text' />
                </td>
                <td>
                  {' '}
                  <div
                    className='block_one_2'
                    style={{ marginTop: '5px', marginRight: '5px', display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
                      <span>add</span>
                    </div>
                    <div className='item_block_one_2' style={{ cursor: 'pointer' }}>
                      clear
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <div
              className='block_one_2'
              style={{ marginTop: '5px', marginRight: '5px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <div className='item_block_one_2' style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineCheck style={{ color: 'green', fontWeight: '500' }} />
                <span>{t('Initialize')}</span>
              </div>
              <div className='item_block_one_2' onClick={saveInfomation} style={{ cursor: 'pointer' }}>
                {t('Save')}
              </div>
            </div>
          </div>
        </>
      ) : (
        'Chưa chọn khách hàng'
      )}
    </div>
  );
};

export default DCInfo;
