import axiosClient from './axiosClient';

const apiPhone = {
  updateStatusCall(body) {
    const url = '/leads/timeline/';
    return axiosClient.post(url, body);
  },
  hostline() {
    const url = '';
    return axiosClient.get(url);
  },
  postCall(body) {
    const url = '/contact/cdr/';
    return axiosClient.post(url, body);
  },
};
export default apiPhone;
