import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from '../../api/axiosClient';

const initialState = {
  loading: false,
  profile: {},
  userList: {},
  listUser: [],
  last_login: 'true',
  header: '',
  email: '',
  sms: '',
  zns: '',
  dataList: '',
  product: '',
  receipt: '',
  report: '',
  start: '',
};

const ACTION = {
  GET_PROFILE: 'user/getProfile',
  EDIT_PASSWORD: 'user/editPassword',

  EDIT_USER: 'user/editUser',

  GET_USER: 'user/getUser',
};

export const getProfile = createAsyncThunk(ACTION.GET_PROFILE, async (body) => {
  return axiosClient.get('/account/profile/', {
    params: body,
  });
});

export const editPassword = createAsyncThunk(ACTION.EDIT_PASSWORD, async (body, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post('/users/change-password/?', body);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const getUser = createAsyncThunk(ACTION.GET_USER, async (body) => {
  return axiosClient.get('/auth/users/', {
    params: body,
  });
});

export const editUser = createAsyncThunk(ACTION.EDIT_USER, async ({ id, body }) => {
  return axiosClient.put(`/users/${id}/`, body);
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUserList: (state, action) => {
      state.listUser = action.payload.map((e) => ({
        idPhone: e?.id,

        label: e?.extension,
      }));
    },
    resetProfile: (state) => {
      state.profile = null;
    },
    getlast_login: (state, action) => {
      state.last_login = action.payload.last_login;
    },
    getHeder: (state, action) => {
      state.header = 'header';
    },
    getemail: (state, action) => {
      state.email = 'email';
    },
    getsms: (state, action) => {
      state.sms = 'sms';
    },
    getzns: (state, action) => {
      state.zns = 'zns';
    },
    getdataList: (state, action) => {
      state.dataList = 'dataList';
    },
    getproduct: (state, action) => {
      state.product = 'product';
    },
    getreceipt: (state, action) => {
      state.receipt = 'receipt';
    },
    getreport: (state, action) => {
      state.report = 'report';
    },
    getstart: (state, action) => {
      state.start = 'start';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = false;
        state.profile = action.payload?.data?.userObj;
      })
      .addCase(editUser.pending, (state) => {})
      .addCase(editUser.rejected, (state) => {
        state.success = false;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.success = true;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userList = action.payload.data;
        state.loading = false;
        state.success = false;
      });
  },
});

// Selector
export const {
  getUserList,
  resetProfile,
  getlast_login,
  getHeder,
  getemail,
  getsms,
  getzns,
  getdataList,
  getproduct,
  getreceipt,
  getreport,
  getstart,
} = userSlice.actions;

export const selectProfile = (state) => state.user?.profile;
export const selectUser = (state) => state.user.userList;
export const selectMessage = (state) => state.user.success;
export const profileLoadding = (state) => state.user.loading;
export const selectListUser = (state) => state.user?.listUser;
export const selectlast_login = (state) => state.user.last_login;

export const selectHeder = (state) => state.user.header;
export const selectEmail = (state) => state.user.email;
export const selectsms = (state) => state.user.sms;
export const selectzns = (state) => state.user.zns;
export const selectdataList = (state) => state.user.dataList;
export const selectProductguide = (state) => state.user.product;
export const selectreceit = (state) => state.user.receipt;
export const selectreport = (state) => state.user.report;
export const selectstate = (state) => state.user.start;
// Reducer
const { reducer: userReducer } = userSlice;
export default userReducer;
