import React from 'react';
import NumberFormat from 'react-number-format';

const CustomNumberFormat = ({ ...props }) => {
  return (
    <>
      {!props.isGroup ? (
        <NumberFormat className={props.classNameInput} thousandSeparator={true} {...props} />
      ) : (
        <div className={`form-input-group ${props.classNameGroup}`} style={{ position: 'relative', ...props.style }}>
          {props.label ? (
            <p>
              {props.label}
              <span className='text-red-400 font-bold text-lg'>{props.required && ' *'}</span>
            </p>
          ) : null}
          <NumberFormat thousandSeparator={true} className={props.classNameInput} {...props} />
        </div>
      )}
    </>
  );
};

export default CustomNumberFormat;
