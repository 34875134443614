//react hook
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Toast from '../../components/common/Toast';

import { useDispatch } from 'react-redux';
import { LoginApi } from '../../features/login/authThunk';

import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/common/FormInput';
import './auth.css';

import authApi from '../../../src/api/auth';

import { useMutation } from '@tanstack/react-query';

import Select from 'react-select';

import logoLogin from '../../assets/image/logo-mains.png';
import { useTranslation } from 'react-i18next';

const Auth = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [langue, setLangue] = useState({
    value: 'en',
    label: 'English',
  });

  const loginAuth = useMutation({
    mutationFn: (data) => authApi.login(data),
    onSuccess: (data) => {
    
      localStorage.setItem('access_token', data?.data?.access);
      localStorage.setItem('refresh_token', data?.data?.refresh);
      navigate('/admin/dashboard');
    },
    onError: (data) => {
      alert('Đăng nhập thất bại');
    },
  });

  const onSubmit = (data) => {
    loginAuth.mutate({ username: data?.email.trim(), password: data?.password.trim() });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const language = [
    { label: 'English', value: 'en' },
    { label: 'Vietnamese', value: 'vn' },
  ];
  const handleChangeLangue = (value) => {
   
    i18n.changeLanguage(value.value);
    setLangue(value);
  };
  return (
    <div className='bodyLogin'>
      <div className='blockLogin md:grid'>
        <div className='formLogin mx-auto'>
          <div className>
            <div className=' mb-[100px] w-[100%]'>
              <h2>
                <img src={logoLogin} alt='' className='imgIconLogo' />
              </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginRight: '100px', maxWidth: '100%', width: '330px' }}>
              <FormInput
                name='email'
                type='text'
                bottom='20px'
                styleInput={{
                  borderRadius: '0px',
                  backgroundColor: 'white',
                  marginBottom: 5,
                  height: 40,
                  borderColor: '#222',
                }}
                {...register('email', {
                  // required: true,
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: 'invalid email address',
                  // },
                })}
                placeholder='ACCOUNT'
                required
              />

              {errors?.email && <p style={{ color: 'red', fontSize: '12px' }}>Không thể để trống tài khoản</p>}

              <FormInput
                name='password'
                type='password'
                bottom='30px'
                styleInput={{
                  borderRadius: '0px',
                  marginBottom: 5,
                  backgroundColor: 'white',
                  height: 40,
                  borderColor: '#222',
                }}
                {...register('password', { required: true })}
                active={active}
                setActive={setActive}
                required
                placeholder='PASSWORD'
              />
              {errors?.password && <p style={{ color: 'red', fontSize: '12px' }}>Không thể để trống mật khẩu</p>}
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_API_CAPTCHA}
                ref={captchaRef}
                onChange={useCallback(() => setDisableSubmit(false))}
              /> */}
              {/* <div className='TextCheck'>
                <input type='checkbox' style={{ borderRadius: '3px' }} />
                <div>Duy Trì Đăng Nhập</div>
              </div> */}
              <div className='flex items-center justify-end flex-wrap select-language'>
                <p className='mr-[15px] text-[14px] mb-[0px] text-[#FFFFFF]'>{t('Language Select')}</p>
                <Select onChange={handleChangeLangue} options={language} value={langue} />
              </div>
              <div className='flex items-center justify-center md:w-[100%] w-[auto]'>
                <button
                  className='buttonLogin '
                  style={{ backgroundColor: '#EF8927' }}
                  // disabled={disableSubmit}
                  type='submit'
                >
                  {t('LOGIN')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
