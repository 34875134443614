import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile, selectProfile } from '../../../../features/user/userSlice';
import ButtonRefreshPlus from './ButtonRefreshPlus';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BoxDashbroad3 = ({ dataChart }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const [profileUser, setProfileUser] = useState();

  const data = {
    labels: dataChart?.data?.connect?.map((v) => v?.createdOn_Date),
    datasets: [
      // {
      //   label: 'Total call',
      //   data: listDaily?.map((v) => v?.totalCall),
      //   // fill: true,
      //   backgroundColor: 'rgba(255,255,255,0)',
      //   borderColor: '#EAAE49',
      //   borderWidth: '3',
      //   // tension: 0.25,
      //   pointBorderWidth: '2',
      // },

      {
        label: t('Connect'),
        data: dataChart?.data?.connect?.map((v) => (v?.total / 100) * 100),
        fill: true,
        backgroundColor: 'rgba(255,255,255,0)',
        borderColor: '#7FB1CD',
        borderWidth: '3',
        tension: 0.3,
        pointBorderWidth: '2',
      },
      {
        label: t('No Connect'),
        data: dataChart?.data?.notConnect?.map((v) => (v?.total / 100) * 100),
        fill: true,
        backgroundColor: 'rgba(255,255,255,0)',
        borderColor: '#4E8F5A',
        borderWidth: '3',
        tension: 0.3,
        pointBorderWidth: '2',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };

  const position = [
    { value: '1', label: 'Telesales', key: 'position' },
    { value: '2', label: 'Telesales', key: 'position' },
    { value: '3', label: 'Telesales', key: 'position' },
    { value: '4', label: 'Telesales', key: 'position' },
  ];

  const groups = [
    { value: '1', label: 'TS 2 groups', key: 'groups' },
    { value: '2', label: 'TS 2 groups', key: 'groups' },
    { value: '3', label: 'TS 2 groups', key: 'groups' },
    { value: '4', label: 'TS 2 groups', key: 'groups' },
  ];

  const packages = [
    { value: '1', label: 'FAMILY', key: 'package' },
    { value: '2', label: 'FAMILY', key: 'package' },
    { value: '3', label: 'FAMILY', key: 'package' },
    { value: '4', label: 'FAMILY', key: 'package' },
  ];

  useEffect(() => {
    if (profile) {
      setProfileUser(profile);
    }
  }, [profile]);

  const profileDashboard = { role: ['dep'] };

  return (
    <div className='border-[#4F6896] box-content__dasboard '>
      <div className='p-[5px] flex justify-between items-center mb-[15px] bg-[#D47625]'>
        <p className='mb-0 text-[#FFFFFF] font-bold'>{t('Dailyperfomance')}</p>
        <div className='flex items-center'>
          {/* <div className='groups-select__dashboard'>
            {profileDashboard?.role?.includes('channel', 'admin ') && (
              <select>
                {position?.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.label}
                  </option>
                ))}
              </select>
            )}

            {profileDashboard?.role?.includes('dep', 'channel', 'admin') && (
              <select>
                {groups?.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.label}
                  </option>
                ))}
              </select>
            )}

            {profileDashboard?.role?.includes('dep', 'channel', 'admin', 'super') && (
              <select>
                {packages?.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.label}
                  </option>
                ))}
              </select>
            )}
          </div> */}
          {/* <ButtonRefreshPlus color={'#8B4B13'} /> */}
        </div>
      </div>
      {/* <Bar options={options} data={data} /> */}
      <Line data={data} options={options} />
    </div>
  );
};

export default BoxDashbroad3;
