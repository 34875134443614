import React, { useState, useEffect } from 'react';
import DashboardApi from '../../../../api/dashboardApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import authApi from '../../../../api/auth';
import Select from 'react-select';

import { showMessageError, showMessageSuccess } from '../../../../features/alert/alert-message';

const NewNotice = ({ setModalNotice }) => {
  const dispatch = useDispatch();
  const [dataNotice, setDataNotice] = useState({});
  const postTodo = useMutation({
    mutationFn: (data) => DashboardApi.postNotice(data),
    onSuccess: (data) => {
      dispatch(showMessageSuccess('Tạo thông báo thành công '));
      setModalNotice(false);
    },
    onError: (data) => {
      dispatch(showMessageError('Tạo thông báo không thành công'));
      setModalNotice(false);
    },
  });

  const {
    data: dataCustomer,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['customer'],
    queryFn: () => authApi.getListUser({}),
    keepPreviousData: false,
    staleTime: 60 * 1000,
  });



  const listDivision = dataCustomer?.data?.map((v) => ({
    value: v?.id,
    label: v?.username,
    key: 'division',
  }));

  // const listPublisher = dataCustomer?.data?.map((v) => ({
  //   value: v?.id,
  //   label: v?.fullname || v?.companyName,
  //   key: 'publisher',
  // }));

  const onSubmit = () => {
    postTodo.mutate(dataNotice);
  };

  const handleChange = (e) => {
    setDataNotice({ ...dataNotice, [e.target.name]: e.target.value });
  };

  const handleSelect = (value) => {
    setDataNotice({ ...dataNotice, [value.key]: parseInt(value.value) });
  };



  return (
    <>
      <div className='form-post__notice p-[20px]'>
        <form>
          <input
            type='text'
            name='title'
            placeholder='title'
            onChange={(e) => handleChange(e)}
            className='control-alls'
          ></input>
          <input
            type='text'
            name='description'
            placeholder='description'
            onChange={(e) => handleChange(e)}
            className='control-alls'
          ></input>
          <Select
            name='division'
            onChange={(value) => handleSelect(value)}
            // value={customersData?.find((v) => v?.value === data?.customer)}
            options={listDivision}
            placeholder='Chọn người phân công'
            className='select-alls__control'
            classNamePrefix={'select-control'}
          />
          {/* <Select
            name='publisher'
            onChange={(value) => handleSelect(value)}
            // value={customersData?.find((v) => v?.value === data?.customer)}
            options={listPublisher}
            placeholder='Chọn người phụ trách'
            className='select-alls__control'
            classNamePrefix={'select-control'}
          /> */}
          <button
            className='btn-blues'
            type='submit'
            onClick={(event) => {
              event.preventDefault();
              onSubmit();
            }}
          >
            Tạo ghi nhớ
          </button>
        </form>
      </div>
    </>
  );
};

export default NewNotice;
