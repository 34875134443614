import Draggable from 'react-draggable';
import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import customerApi from '../../../../api/customer';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../../../features/user/userSlice';
import { updateDataDetai } from '../../../../features/consultation/ConsultationSlice';
import { useDispatch } from 'react-redux';
import ConsultationApi from '../../../../api/Consultation';
import Spinner from '../../../../components/common/Spinner';

const CheckCustormer = ({ setActive }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [checkApi, setCheckApi] = useState(false);
  const [payment, setPayment] = useState(false);

  const [data, setData] = useState();
  const key = ['Agree ID', 'Full Name', 'Company', 'Product', 'Loan Amount'];
  const value = [];
  const profile = useSelector(selectProfile);

  const {
    data: dataCustomer,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['checkID'],
    queryFn: () => customerApi.checkCustomer(data),
    enabled: checkApi,
    staleTime: 60 * 1000,
  });

  const handleChange = (e) => {
    setData({ ...data, [e?.target?.name]: e?.target?.value });
  };

  const Inquiry = () => {
    setCheckApi(true);
    refetch();
    setPayment(true);
    queryClient.invalidateQueries({ queryKey: ['getpayment', dataCustomer?.data?.results[0].id] });
  };

  const checkAssigned = (data) => {
    if (profile?.username === data?.assigned?.username) {
      dispatch(updateDataDetai({ ...data, assigned: '1' }));
    } else {
      alert(`Not a assigned customer . Consulting is not available \n Assigned agent : ${data?.assigned?.username}`);
    }

    setActive(false);
  };

  const { data: getpayment, refetch: refetchgetpayment } = useQuery({
    queryKey: ['getpayment', dataCustomer?.data?.results[0].id],
    queryFn: () => ConsultationApi.getInfomation(dataCustomer?.data?.results[0].id),
    enabled: payment,
  });

  // console.log('isLoading',isLoading);
  // console.log('isFetching',isFetching);
  return (
    <div>
      {' '}
      {isFetching && <Spinner />}{' '}
      <Draggable defaultPosition={{ x: 600, y: 0 }}>
        <div className='blockCheckCustomer' style={{ zIndex: '10000' }}>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFC125',
              justifyContent: 'space-between',
              paddingRight: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <div style={{ marginLeft: '10px', color: 'white' }}>[CCOM3020] Search Customer</div>
            <AiOutlineClose
              style={{ fontWeight: '600', cursor: 'pointer' }}
              onClick={() => {
                setActive(false);
              }}
            />
          </div>
          <div style={{ padding: '10px' }} className='CheckCustomer'>
            <div className='checkCustomerItem'>
              <div>Customer No</div>
              <div>
                <input type='text' className='inputCheckcustomer' name='name' onChange={handleChange} />
              </div>
            </div>
            <div className='checkCustomerItem'>
              <div>Tel (Mobile)</div>
              <div>
                <input type='text' className='inputCheckcustomer' name='phone' onChange={handleChange} />
              </div>
            </div>
            <div className='checkCustomerItem'>
              <div>Cust. ID No.</div>
              <div>
                <input type='text' className='inputCheckcustomer' name='idNo' onChange={handleChange} />
              </div>
            </div>
            <div className='checkCustomerItem'>
              <div>Agree ID</div>
              <div>
                <input type='text' className='inputCheckcustomer' name='name' onChange={handleChange} />
              </div>
            </div>
            <div className='checkCustomerItem'>
              <div>Cust. Name</div>
              <div>
                <input type='text' className='inputCheckcustomer' name='name' onChange={handleChange} />
              </div>
            </div>
            <div className='checkCustomerItem'>
              <div></div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  style={{
                    border: '1px solid grey',
                    display: 'flex',
                    alignItems: 'center',
                    height: '20px',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                  }}
                  onClick={Inquiry}
                >
                  <AiOutlineSearch /> Inquiry
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: '10px', border: '1px solid gray' }}>
            <div className='checkTable'>
              {key?.map((v, i) => (
                <div key={i}>{v}</div>
              ))}
            </div>

            {getpayment?.data?.data?.length > 0
              ? getpayment?.data?.data?.map((v, i) => (
                  <div
                    key={i}
                    className='valueCheckTable'
                    onClick={() => checkAssigned(dataCustomer?.data?.results[0])}
                  >
                    <div>{v?.id}</div>
                    <div>{dataCustomer?.data?.results[0]?.fullname}</div>
                    <div>{dataCustomer?.data?.results[0]?.companyName || 'N/A'}</div>
                    <div style={{ marginRight: '5px' }}>{v?.product?.name}</div>
                    <div>{v?.amount}</div>
                  </div>
                ))
              : dataCustomer?.data?.results?.map((v, index) => (
                  <div
                    key={index}
                    className='valueCheckTable'
                    onClick={() => checkAssigned(dataCustomer?.data?.results[0])}
                  >
                    <div>{v?.id}</div>
                    <div>{v?.fullname}</div>
                    <div>{v?.companyName || 'N/A'}</div>
                    <div style={{ marginRight: '5px' }}></div>
                    <div></div>
                  </div>
                ))}
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default CheckCustormer;
