import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../features/dashboard/dashboardSlice';

import orgReducer from '../features/org/orgSlice';
// import userReducer from '../api/authThunk';

import customertReducer from '../features/customer/customerSlice';

import PhoneSliceReduce from '../features/phone/phoneSlice';
import sidebarReducer from '../features/sidebar/sidebarSlice';
import callReducer from '../features/call/callSlice';
import userReducer from '../features/user/userSlice';
import consultationReducer from '../features/consultation/ConsultationSlice';
const rootReducer = combineReducers({
  dashboard: dashboardReducer,

  org: orgReducer,

  // user: userReducer,
  user: userReducer,
  phone: PhoneSliceReduce,
  call: callReducer,
  customer: customertReducer,
  sidebar: sidebarReducer,
  consultation:consultationReducer
});

const configStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  return store;
};

const store = configStore();
export default store;
