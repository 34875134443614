import React, { useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Outlet, useNavigate } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';

import { useDispatch } from 'react-redux';
import { actionKey, postKey } from '../../features/org/orgSlice';
import { guideFunction, guideSteps } from '../../util/guide';
import { useSelector } from 'react-redux';

import { useEffect } from 'react';
import { getProfile, getlast_login, selectProfile } from '../../features/user/userSlice';
import { login } from '../../features/login/authSlice';

const Layout = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [run, setRun] = useState(false);
  const [nameSteps, setNameSteps] = useState();
  const token = localStorage.getItem('access_token');
  const profile = useSelector(selectProfile)
  useEffect(() => {
    if(token){
    if (!profile) {
      dispatch(getProfile()).unwrap().then((res)=>{
        dispatch(getlast_login({ last_login: res?.last_login }));
        dispatch(login());
      
          
      })
    }
    if (profile && Object.keys(profile).length === 0) {
      dispatch(getProfile())
    }
  }
  }, [token]);
  // const run = true;

  return (
    <>
      <Joyride
        continuous
        callback={(data) => guideFunction(data, dispatch, navigator)}
        run={run}
        steps={guideSteps[nameSteps]}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
        styles={{
          options: {
            arrowColor: 'white',
            backgroundColor: 'white',
            overlayColor: '#444444',
            primaryColor: '#5a8dee',
            textColor: '#808080',
            width: 300,
            zIndex: 1000,
          },
        }}
      />
      <Outlet />

     
    </>
  );
};

export default Layout;
