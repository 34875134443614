import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  showStatus: false,
  type: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    showMessageSuccess: (state, action) => {
      state.message = action.payload;
      state.showStatus = true;
      state.type = 'success';
    },
    showMessageError: (state, action) => {
      state.message = action.payload;
      state.showStatus = true;
      state.type = 'error';
    },
    resetState: (state) => {
      state.message = '';
      state.type = '';
      state.showStatus = false;
    },
  },
});

export const { showMessageSuccess, showMessageError, resetState } = alertSlice.actions;
const { reducer: alertReducer } = alertSlice;
export default alertReducer;
