import React from 'react';
import '../../styles/LoaddingCoaster.scss';
import { AiOutlineArrowUp } from 'react-icons/ai';
const LoaddingCoaster = () => {
  return (
    <div className='body'>
      {/* <span className='spapLoadding'>
        <BsArrowBarUp />
      </span> */}
      <span className='spapLoadding' style={{ delay: '0.1s', fontSize: '20px' }}>
        <AiOutlineArrowUp />
      </span>

      {/* <span className='spapLoadding' style={{ delay: '0.3s' }}>
        <BsArrowUp />
      </span>
      <span className='spapLoadding' style={{ delay: '0.4s' }}>
        <BsArrowUp />
      </span>
      <span className='spapLoadding' style={{ delay: '0.5s' }}>
        <BsArrowUp />
      </span> */}
    </div>
  );
};

export default LoaddingCoaster;
