

export const CssButton = {
  backgroundColor: '#00CC33',
  borderRadius: '2px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '4px',
  paddingBottom: '4px',
  marginLeft: '10px',
  color: 'white',
  fontWeight: '600',
};
export const cssInput = {
  height: '20px',
  color: 'gray',
  fontWeight: '600',
  width: '100%',
  backgroundColor: '#f2f3f9',
  border: 'none',
  borderBottom: '1px dotted  #CCCCCC',
};
export const styles = {
  control: (base, state) => ({
    ...base,
    border: '0 !important',
    // color: 'gray !important',
    // fontWeight: '600 !important',
    borderRadius: '0 !important',
    backgroundColor: 'transparent !important',
    width: '100% !important',
    zIndex:'0 !important',
 
  }),
  singleValue: (base, state) => ({
    ...base,
    // color: '#6b7280',
  }),
  container: (base, state) => ({
    ...base,
    padding: '0 !important',
  }),
};
